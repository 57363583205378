import React from "react"
import { useSearchParams, useNavigate } from "react-router-dom"

import { Input, InputContainer, ToolTipImageContainer, ToolTip, Wrapper } from './index.styled'
import ToolTipLogo from "../../../../../../../../media/Dashboard/tooltip.svg"

import SearchIcon from '../../../../../../../../media/Dashboard/searchIcon.svg'
import { Tooltip } from 'react-tooltip'

import { urlPatternValidation } from "../../../../../../../../utils/helper"
import { useAuth } from "../../../../../../../../context/auth";
import { useGetUserInfo } from "../../../../../../../hooks/useGetUserInfo"

const UrlInput = ({setCreditsExhaustedPopupActive}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { authState, authDispatch } = useAuth();
  const { canAccessFeature } = useGetUserInfo()

  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      if(!canAccessFeature({type: "adspy"})){
        setCreditsExhaustedPopupActive(true)
        return
      }
      const { searchMode } = authState

      if (!authState.searchPlatform) {
        return authDispatch({ type: 'SET_SEARCH_PLATFORM_ERROR', value: true })
      }

      if (searchMode === "url") {
        const { urlSearchType } = authState
        authDispatch({ type: 'UPDATE_FILTER_STATUS', value: 'loading' })

        if (urlSearchType === "exact") {
          const { domainUrlInput } = authState

          if (domainUrlInput.valid) {
            authDispatch({ type: "SET_HIGHLIGHT_WORDS", value: [] })
            authDispatch({ type: 'SET_URL_INPUT_ERROR', value: false })

            searchParams.set('searchMode', "url")
            searchParams.set('urlSearchType', "exact")
            searchParams.set('domainUrlInput', domainUrlInput.value);
            searchParams.set('page', 1);
            searchParams.set('sortType', 'relevant');
            searchParams.delete('selectedCountries');
            searchParams.delete('startDate');
            searchParams.delete('endDate');
            // setSearchParams(searchParams)
            navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

          } else {
            authDispatch({ type: 'SET_URL_INPUT_ERROR', value: true })
          }
        } else if (urlSearchType === "domain") {
          const { domainUrlInput } = authState

          if (domainUrlInput.valid) {
            authDispatch({ type: "SET_HIGHLIGHT_WORDS", value: [] })
            authDispatch({ type: 'SET_URL_INPUT_ERROR', value: false })

            searchParams.set('searchMode', "url")
            searchParams.set('urlSearchType', "domain")
            searchParams.set('domainUrlInput', domainUrlInput.value);
            searchParams.set('page', 1);
            searchParams.set('sortType', 'relevant');
            searchParams.delete('selectedCountries');
            searchParams.delete('startDate');
            searchParams.delete('endDate');
            // setSearchParams(searchParams)
            navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

          } else {
            authDispatch({ type: 'SET_URL_INPUT_ERROR', value: true })
          }
        } else if (urlSearchType === "text") {
          const { domainUrlInput } = authState

          authDispatch({ type: "SET_HIGHLIGHT_WORDS", value: [] })

          searchParams.set('searchMode', "url")
          searchParams.set('urlSearchType', "text")
          searchParams.set('domainUrlInput', domainUrlInput.value);
          searchParams.set('page', 1);
          searchParams.set('sortType', 'relevant');
          searchParams.delete('selectedCountries');
          searchParams.delete('startDate');
          searchParams.delete('endDate');
          // setSearchParams(searchParams)
          navigate(`/adspy/${authState.searchPlatform}?${searchParams.toString()}`)

        }

      }
    }
  }

console.log(authState.urlInputError,'authState.urlInputError')
  return <Wrapper
    data-tooltip-id="disabled-tooltip"
    data-tooltip-content="Please select a platform first"
  >
    {!authState.searchPlatform && <Tooltip id="disabled-tooltip" />}
    <InputContainer
      isDisabled={!authState.searchPlatform}
    >
      <img src={SearchIcon} />
      <Input
        isDisabled={!authState.searchPlatform}
        onKeyDown={handleSearch}
        error={authState.urlInputError}
        name="domain-url-input"
        type="text"
        placeholder={authState.searchPlatform ? "Enter an URL" : "Select a Platform first"}
        autoComplete="off"
        value={authState.domainUrlInput.value}
        onChange={(e) => {
          const isTrueVal = urlPatternValidation(e.target.value)
          console.log(isTrueVal)
          authDispatch({ type: "SET_DOMAIN_URL_INPUT", payload: { value: e.target.value, valid: isTrueVal } })
          authDispatch({ type: 'SET_URL_INPUT_ERROR', value: false })
        }}
      />
      <ToolTipImageContainer
        error={authState.urlInputError} className="tooltip-container"
      >
        <img src={ToolTipLogo} />
        <ToolTip>
          {authState.urlSearchType == "text" ? "Please enter a single word" : "Please enter a url"}
        </ToolTip>
      </ToolTipImageContainer>
    </InputContainer>

    {/* </ExampleWrap> */}

  </Wrapper>
}

export default UrlInput