import React, { useEffect, useState, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import { Helmet } from "react-helmet";
import { BreadCrumb } from "../../index.styled";

import { useNavigate } from "react-router-dom";

import {
  Container,
  Header,
  SearchContainer,
  Input,
  SearchIconContainer,
  HeaderLeft,
  HeaderRight,
  ProjectsContainer,
  ProjectList,
  Project,
  ProjectFooter,
  Date,
  Options,
  DropDown,
  AnalyseButton,
  RemoveButton,
  SaveNameBtn,
  NoResult,
  ProjectCard,
  LogoContainer,
  Details,
  PrimaryDetails,
  SecondaryDetails,
  PrimaryText,
  SecondaryText,
  Row,
  Image,
  Logo,
  SavedAdsWrapper,
} from "./index.styled";
import SearchIcon from "../../../../../media/Dashboard/searchIcon.svg";
import TreeDots from "../../../../../media/Dashboard/threedots-horizontal.svg";
import task from "../../assets/task.svg"
import calender from "../../assets/calender.svg"


import { appClient } from "../../../../../utils/axios-utils";
import { useAuth } from "../../../../../context/auth";
// import { useGetAccount } from "../../../../hooks/useGetAccount";

import { useSavedAds } from "../../context/savedads";
import { getStaticLogo } from "../../../Overview/utils";
import { formatDate } from "../../../../../utils/dateFormat";
const projectNames = [
  "Couponswala",
  "Flonnect",
  "Ad-collector",
  "Startup-Talky",
  "ChatGPT Writer",
];

const getAllProjects = async (data) => {
  const config = {
    url: `/dashboard-overview/client-profile/get-projects`,
    method: "GET",
  };

  return appClient(config);
};
const renameProject = async (data) => {
  const config = {
    url: `/adgeneration/project/rename`,
    method: "POST",
    data,
  };

  return appClient(config);
};
const deleteProject = async (data) => {
  const config = {
    url: `/adgeneration/project/remove`,
    method: "POST",
    data,
  };

  return appClient(config);
};

function useOutsideAlerter(ref, setOpenedDropdown, setEnableRenameFor) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (
          !event.target?.className?.includes("save-rename") &&
          !event.target?.className?.includes("rename-input") &&
          !event.target?.className?.includes("rename-btn") &&
          !event.target?.className?.includes("delete-btn")
        ) {
          setOpenedDropdown("");
          setEnableRenameFor(false);
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

// const getAllProjects = async (data) => {
//     const config = {
//         url: `/adgeneration/project/all`,
//         method: "GET"
//     }

//     return appClient(config)
// }

// const renameProject = async (data) => {
//     const config = {
//         url: `/adgeneration/project/rename`,
//         method: "POST",
//         data
//     }

//     return appClient(config)
// }

// const deleteProject = async (data) => {
//     const config = {
//         url: `/adgeneration/project/remove`,
//         method: "POST",
//         data
//     }

//     return appClient(config)
// }



const SavedAdsHome = () => {
  const { authState, authDispatch } = useAuth();

  const { savedAdsState, savedAdsDispatch } = useSavedAds();
  const [openedDropdown, setOpenedDropdown] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [enableRenameFor, setEnableRenameFor] = useState("");
  const [renameInput, setRenameInput] = useState("");
  // const accountInfoHook = useGetAccount();

  const navigate = useNavigate();

  let wrapperRef = useRef(null);

  // const {mutate: getProjectsMutate, isLoading: isProjectsLoading} = useMutation(getAllProjects, {
  //     onSuccess: (data) => {
  //         authDispatch({type: 'UPDATE_PROJECT_COLLECTIONS', value: data})
  //     },
  //     onError:  ((e)=>{

  //     })
  // })

  // const {mutate: renameProjectMutate, isLoading: isRenameProjectLoading} = useMutation(renameProject, {
  //     onSuccess: (data) => {
  //         getProjectsMutate()
  //     },
  //     onError:  ((e)=>{

  //     })
  // })

  // const {mutate: deleteProjectMutate, isLoading: isDeleteProjectLoading} = useMutation(deleteProject, {
  //     onSuccess: (data) => {
  //         getProjectsMutate()
  //     },
  //     onError:  ((e)=>{

  //     })
  // })

//   function formatDate(dateString) {
//     const date = new Date(dateString);
//     const options = { day: '2-digit', month: 'short', year: 'numeric' };
//     const formattedDate = date.toLocaleDateString('en-US', options);
//     const parts = formattedDate.split(' ');
//     const day = parts[1].startsWith('0') ? parts[1].slice(1) : parts[1];
//     return `${day} ${parts[0]} ${parts[2]}`;
// }

  const onSearchFilter = (value) => {
    // console.log('onfilter', value)
    let finalData = authState.projects.filter((project) => {
      // console.log(project.projectName)
      if (project.projectName.toLocaleLowerCase().includes(value)) {
        return project;
      }
    });

    setFilteredData(finalData);
  };

  const openDropDown = (data) => {
    if (data == openedDropdown) {
      setOpenedDropdown("");
    } else {
      setOpenedDropdown(data);
    }
  };

  const convertDate = (input) => {
    // console.log(input);
    // Input date string
    const inputDate = input;

    // Define arrays for day and month names
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Split the input date string into parts
    const dateParts = inputDate.split(" ");

    // Extract relevant date components
    const dayOfWeek = daysOfWeek[new Date(inputDate).getDay()];
    const month =
      months.indexOf(dateParts[1]) !== -1 ? dateParts[1] : dateParts[2];
    const day = dateParts[2];
    const time = dateParts[4];
    const ampm = dateParts[5];

    // Format the date
    const formattedDate = `${day} ${month} | ${time} ${ampm}`;

    return formattedDate;
  };

  useOutsideAlerter(wrapperRef, setOpenedDropdown, setEnableRenameFor);

  const handleProjectCardClick = (projectData) => {
    savedAdsDispatch({
      type: "SET_SELECTED_PROJECT",
      value: projectData,
    });
    navigate(
      `/saved-ads/details?projectid=${projectData.id}&projectname=${projectData.projectName}`
    );
  }

  const renderProjectCard = (project) => {
    return(
      <ProjectCard 
        key={project.id} 
        onClick={() => handleProjectCardClick(project)}
      >
          <LogoContainer>
              <Logo src={project.logo ?? getStaticLogo(project.website)} alt="add" />
          </LogoContainer>
          <Details>
            <PrimaryDetails>
                <PrimaryText>{project.projectName}</PrimaryText>
                <SecondaryText style={{ color: "#7BAE86" }} >{project.website}</SecondaryText>
            </PrimaryDetails>
            <SecondaryDetails>
                <Row>
                    <Image src={task} alt="task" />
                    <SecondaryText>{`${project.savedAdsCount ?? 10} saved ads`}</SecondaryText>
                </Row>
                <Row>
                    <Image src={calender} alt="calender" />
                    <SecondaryText>{`Created on : ${formatDate(project.createdAt)}`}</SecondaryText>
                </Row>
            </SecondaryDetails>
          </Details>
      </ProjectCard>
    )
  }
  const { mutate: getProjectsMutate, isLoading: isProjectsLoading } =
    useMutation(getAllProjects, {
      onSuccess: (data) => {
        authDispatch({ type: "UPDATE_PROJECT_COLLECTIONS", value: data.projects });
      },
      onError: (e) => {},
    });
    const { mutate: renameProjectMutate, isLoading: isRenameProjectLoading } =
    useMutation(renameProject, {
      onSuccess: (data) => {
        getProjectsMutate();
      },
      onError: (e) => {},
    });
    const { mutate: deleteProjectMutate, isLoading: isDeleteProjectLoading } =
    useMutation(deleteProject, {
      onSuccess: (data) => {
        getProjectsMutate();
      },
      onError: (e) => {},
    });


  useEffect(() => {
    getProjectsMutate();
  }, []);

  useEffect(() => {
    // console.log('projets', authState.projects)
  }, [authState.projects])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Profile | Saved Ads</title>
        <meta name="title" content="Profile | Saved Ads" />
        <meta
          name="description"
          content="Revolutionize your ad campaigns with AdSpyder. Gain strategic competitor insights, harness performance analytics, and supercharge ad optimization. "
        />
      </Helmet>
      <SavedAdsWrapper>
        <BreadCrumb>
          {/* <span>Account & Activity</span>
          {">"} */}
          <span>Saved Items</span>
        </BreadCrumb>
        <Container>
          <Header>
            <HeaderLeft>
              <h1>Saved Items</h1>
              <SearchContainer>
                <Input
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    onSearchFilter(e.target.value);
                  }}
                />
                <SearchIconContainer>
                  <img src={SearchIcon} />
                </SearchIconContainer>
              </SearchContainer>
            </HeaderLeft>
            <HeaderRight>
              {/* <NewProjectBtn>
                          + New Project
                      </NewProjectBtn> */}
            </HeaderRight>
          </Header>
          <ProjectsContainer>
            <h2>Projects</h2>
            {isProjectsLoading ? (
              <span>Loading...</span>
            ) : (
              <ProjectList>
                {searchText.length == 0 &&
                  (authState.projects?.length > 0 ? (
                    authState.projects.map((projectData, idx) => {
                      {return renderProjectCard(projectData)}
                    })
                  ) : (
                    <NoResult>No projects found</NoResult>
                  ))}
                {searchText.length > 0 &&
                  (filteredData.length > 0 ? (
                    filteredData.map((projectData, idx) => {
                      {return renderProjectCard(projectData)}
                      return (
                        <Project>
                          {projectData.projectName == enableRenameFor ? (
                            <input
                              className="rename-input"
                              defaultValue={projectData.projectName}
                              onChange={(e) => setRenameInput(e.target.value)}
                            />
                          ) : (
                            <h3
                              onClick={() => {
                                savedAdsDispatch({
                                  type: "SET_SELECTED_COLLECTION",
                                  value: projectData,
                                });
                                navigate(
                                  `/account/saved-ads/details?projectid=${projectData.id}&projectname=${projectData.projectName}&tab=adcopies`
                                );
                              }}
                            >
                              {projectData.projectName}
                            </h3>
                          )}

                          <SaveNameBtn
                            isEnabled={projectData.projectName != enableRenameFor}
                            className="save-rename"
                            onClick={(e) => {
                              e.stopPropagation();
                              renameProjectMutate({
                                projectId: projectData.id,
                                project: renameInput,
                              });
                            }}
                          >
                            Save name
                          </SaveNameBtn>

                          <ProjectFooter>
                            <Date>{projectData.updatedAt}</Date>
                            <Options ref={wrapperRef}>
                              <img
                                src={TreeDots}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openDropDown(projectData.id);
                                }}
                              />
                              <DropDown open={openedDropdown == projectData.id}>
                                <AnalyseButton
                                  className="rename-btn"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setEnableRenameFor(projectData.projectName);
                                    setOpenedDropdown("");
                                  }}
                                >
                                  Rename Project
                                </AnalyseButton>
                                <RemoveButton
                                  className="delete-btn"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deleteProjectMutate({
                                      projectId: projectData.id,
                                    });
                                  }}
                                >
                                  Delete
                                </RemoveButton>
                              </DropDown>
                            </Options>
                          </ProjectFooter>
                        </Project>
                      );
                    })
                  ) : (
                    <NoResult>No projects found</NoResult>
                  ))}
              </ProjectList>
            )}
          </ProjectsContainer>
        </Container>
      </SavedAdsWrapper>
    </>
  );
};

export default SavedAdsHome;
