import React,{useState,useEffect} from 'react'
import { useLocation } from 'react-router-dom'


/* STYLES */
import { Container, Content, Wrapper, AppWrapper } from './index.styled'

/* COMPONENTS */
import Header from './components/Header'
import SideNav from './components/SideNav'
import { useAuth } from '../../context/auth'
import CreditExpiredModal from '../components/creditExpiredModal'
import SaleBarContainer from './components/SaleBarContainer'
import LimitExceededPopup from '../components/limitExceededPopup'

const DashboardLayout = ({children}) => {
    // console.log("layout render")
    const location = useLocation()

    const routesWithoutSideNav = ["home"]

    const [noSideNav,setNoSideNav] = useState(false)
    const {authState, authDispatch} = useAuth()
    
    useEffect(() => {
        routesWithoutSideNav.map((r)=>{
            if(location.pathname.includes(r)){
                setNoSideNav(true)
            }
        })
    }, [])
    
    // const onClose = () => {
    //     authDispatch({type: 'SET_SHOW_CREDIT_EXPIRED_MODAL', value: false})
    // }

    if(location.pathname === "/browser-compatiblity") {
        return <Container>
                {children}
            </Container>
    } else {
        return <AppWrapper>
                {/* {authState.isSaleBar && (authState.plan.planName == "free") && <SaleBarContainer/>} */}
                <Container 
                    // announcementBar={authState.isSaleBar && (authState.plan.planName == "free")}
                >
                    {!noSideNav && <SideNav/>}
                    <Wrapper isNavCollapsed={authState.isNavCollapsed} noSideNav={noSideNav}>
                        <Header noSideNav={noSideNav}/>
                        <Content 
                            // announcement={authState.isSaleBar && (authState.plan.planName == "free")}
                        noSideNav={noSideNav}>
                            {/* {authState.showCreditExpiredModal && <CreditExpiredModal/>} */}
                            {children}
                        </Content>
                    </Wrapper>  
                </Container>
            </AppWrapper>
    }
}

export default DashboardLayout