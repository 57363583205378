import React, { useEffect, useState } from "react";
import {
  ActiveCampaign,
  AdsyderDiv,
  CampaignOuterWrap,
  CampaignTableWrap,
  ImpressionDiv,
  LeftArrow,
  NameDiv,
  NoDataDiv,
  PaginationWrapper,
  PerformanceDiv,
  PlatformDiv,
  RightArrow,
  Row,
  RowsList,
  ScrollWraper,
  TableBody,
  TableHeader,
  TableWrap,
} from "./index.styled";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  ColumnDef,
  getSortedRowModel,
} from "@tanstack/react-table";
import downArrow from "../../../../../../../../media/Dashboard/arrow-down.svg";
import GoogleLogo from "../../../../../../../../media/Dashboard/Google16.svg";
import BingLogo from "../../../../../../../../media/Dashboard/Bing16.svg";
import YoutubeLogo from "../../../../../../../../media/Dashboard/YouTube16.svg";
import MetaLogo from "../../../../../../../../media/Dashboard/meta16.png";
import LinkedinLogo from "../../../../../../../../media/Dashboard/LinkedIn16.svg";
import RedditLogo from "../../../../../../../../media/Dashboard/Reddit16.svg";
import GooglePlaLogo from "../../../../../../../../media/Dashboard/Googlepla16.svg";
import BingPlaLogo from "../../../../../../../../media/Dashboard/Bingpla16.svg";
import AmazonLogo from "../../../../../../../../media/Dashboard/amazon16.svg";
import DisplayLogo from "../../../../../../../../media/Dashboard/Display16.svg";
import GaugeComponent from "react-gauge-component";
import arrowOrange from "../../../../../../../../media/Dashboard/arrow-right-orange.svg";
import paginateArrow from "../../../../../../../../media/Dashboard/arrow-right.svg";
import greenLiveImg from "../../../../../../../../media/Dashboard/greenLive.svg";
import adResultData from "../../../../static";
import { useAdIntegrationInfo } from "../../../../hooks/useAdManagementInfo";
import { returnConnectedAccount } from "../../../../helperFunction";
import { useAdIntegration } from "../../../../context/AdManagementContext";
import pauseButton from "../../../../../../../../media/Dashboard/round-pause.svg";
import removeImg from "../../../../../../../../media/Dashboard/removeImg.svg";
import { useAuth } from "../../../../../../../../context/auth";
import Skeleton from "react-loading-skeleton";

const CampaignComponent = () => {
  const { setCampaignSummary, isCampaignSummaryLoading } =
    useAdIntegrationInfo();
  const {
    newAdManagementState: {
      selectedId,
      dateStartEnd,
      isCampaignSummary,
      isStatusDetail,
      isUpdateApiToggle,
    },
    newAdManagementDispatch,
  } = useAdIntegration();
  const { authState, authDispatch } = useAuth();

  const columnHelper = createColumnHelper();
  // const { dummyData } = adResultData;
  const PlatformList = {
    Google: GoogleLogo,
    Bing: BingLogo,
    Youtube: YoutubeLogo,
    Facebook: MetaLogo,
    Linkedin: LinkedinLogo,
    Reddit: RedditLogo,
    GoogleEcom: GooglePlaLogo,
    BingEcom: BingPlaLogo,
    Amazon: AmazonLogo,
    Display: DisplayLogo,
  };

  const columns = [
    columnHelper.accessor("no", {
      id: "no",
      header: () => "No",
      cell: (info) => info.getValue(),
    }),
  ];
  const table = useReactTable({
    data: "accou",
    columns,

    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const [currentPage, setCurrentPage] = useState(1);

  let rowsPerPage = 10;
  const totalPages = Math.ceil(isCampaignSummary.length / rowsPerPage);
  const currentData = isCampaignSummary.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  const startRow = (currentPage - 1) * rowsPerPage + 1;
  const endRow = Math.min(currentPage * rowsPerPage, isCampaignSummary.length);
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };
  useEffect(() => {
    setCampaignSummary({
      projectId: authState.defaultProjectInfo.projectId,
      customerId: selectedId?.customerId,
      startDate: dateStartEnd.start.format("YYYY-MM-D"),
      endDate: dateStartEnd.end.format("YYYY-MM-D"),
      status: isStatusDetail.toUpperCase(),
    });
  }, [isStatusDetail, isUpdateApiToggle,selectedId]);

  return (
    <CampaignOuterWrap>
      <h3>Campaigns</h3>
      <CampaignTableWrap>
        <ActiveCampaign>Active campaigns</ActiveCampaign>
        {!isCampaignSummaryLoading ? (
          isCampaignSummary.length > 0 ? (
            <>
              <ScrollWraper>
                <TableWrap>
                  {table.getHeaderGroups().map((headerGroup) => {
                    // console.log(headerGroup)
                    return (
                      <TableHeader>
                        <div id="campaignHead">Campaign</div>
                        <div id="plaformHead">Platforms</div>
                        <div id="impressionHead">Impression</div>
                        <div id="impressionHead">Click</div>
                        <div id="impressionHead">CT. Rate</div>
                        <div id="impressionHead">Total Spent</div>
                        <div id="performanceHead">
                          Status
                          {/* <img src={downArrow} /> */}
                        </div>
                        {/* <div id="performanceHead">
                    Performance Score <img src={downArrow} />
                  </div>
                  <div id="adspyderHead">Adspyder</div> */}
                      </TableHeader>
                    );
                  })}
                  {/* {!isCampaignSummaryLoading ? ( */}
                  <TableBody>
                    {currentData.map((row, index) => {
                      return (
                        <Row>
                          <NameDiv>{row.campaignName}</NameDiv>
                          <PlatformDiv>
                            <img src={PlatformList[row.platform]} />
                          </PlatformDiv>
                          <ImpressionDiv>{row.impressions}</ImpressionDiv>
                          <ImpressionDiv>{row.clicks}</ImpressionDiv>
                          <ImpressionDiv>{row.ctr}</ImpressionDiv>
                          <ImpressionDiv>{row.totalSpent}</ImpressionDiv>
                          <PerformanceDiv isActive={row.status}>
                            <p>
                              {row.status == "PAUSED" ? (
                                <img src={pauseButton} />
                              ) : row.status == "ENABLED" ? (
                                <img src={greenLiveImg} />
                              ) : (
                                <img src={removeImg} />
                              )}
                              {row.status}
                            </p>
                          </PerformanceDiv>
                          {/* <AdsyderDiv>
                      <GaugeComponent
                        value={50}
                        type="radial"
                        labels={{
                          tickLabels: {
                            hideMinMax: true,
                          },
                          valueLabel: {
                            hide: true,
                          },
                        }}
                        hideText={false}
                        arc={{
                          colorArray: [
                            "#9BFF00",
                            "#00D200",
                            "#FFE600",
                            "#FFAA32",
                            "#FF1E5A",
                            "#C40050",
                          ],
                          cornerRadius: 0,
                          subArcs: [
                            { showTick: false },
                            { showTick: false },
                            { showTick: false },
                            { showTick: false },
                            { showTick: false },
                            { showTick: false },
                          ],
                          padding: 0,
                          width: 0.45,
                        }}
                        pointer={{
                          elastic: false,
                          animationDelay: 100,
                          color: "#001428",
                          width: 37,
                        }}
                      />
                      <img src={arrowOrange} height={20} />
                    </AdsyderDiv> */}
                        </Row>
                      );
                    })}
                  </TableBody>
                </TableWrap>
              </ScrollWraper>

              <PaginationWrapper>
                <RowsList>
                  <span>
                    Rows {startRow}-{endRow}
                  </span>{" "}
                  of {isCampaignSummary.length} campaigns
                </RowsList>
                <LeftArrow
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  <img src={paginateArrow} />
                </LeftArrow>
                <RightArrow
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <img src={paginateArrow} />
                </RightArrow>
              </PaginationWrapper>
            </>
          ) : (
            <NoDataDiv>
              <p>No data found</p>
            </NoDataDiv>
          )
        ) : (
          <NoDataDiv isPadding={true}>
            <Skeleton height={400} width={"100%"} />
          </NoDataDiv>
        )}
      </CampaignTableWrap>
    </CampaignOuterWrap>
  );
};

export default CampaignComponent;
