import React, { useEffect, useState } from "react";
import {
  InfoText,
  LeftArrow,
  NoData,
  PaginationWrapper,
  PopupContainer,
  PopupContent,
  PopupDescrip,
  PopupHeader,
  PopupHeaderWrap,
  PopupTableHead,
  PopupTableRow,
  RightArrow,
  RowsList,
  SelectOverlay,
  SelectPopup,
  TableWrap,
} from "./index.styled";
import closeImg from "../../../../../../../../media/Dashboard/closeIcon.svg";
import greenLiveImg from "../../../../../../../../media/Dashboard/greenLive.svg";
import paginateArrow from "../../../../../../../../media/Dashboard/arrow-right.svg";
import { useAdIntegrationInfo } from "../../../../hooks/useAdManagementInfo";
import { useAdIntegration } from "../../../../context/AdManagementContext";
import Skeleton from "react-loading-skeleton";
import { useAuth } from "../../../../../../../../context/auth";

const SelectAccount = () => {
  const {
    newAdManagementState: { listAllAdAccounts, isIntegrationId },
    newAdManagementDispatch,
  } = useAdIntegration();
  const [currentPage, setCurrentPage] = useState(1);
  const adManagementHook = useAdIntegrationInfo();
  const { authState, authDispatch } = useAuth();

  let rowsPerPage = 5;
  const totalPages = Math.ceil(listAllAdAccounts.length / rowsPerPage);
  const presentData = listAllAdAccounts.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  const startRow = (currentPage - 1) * rowsPerPage + 1;
  const endRow = Math.min(currentPage * rowsPerPage, listAllAdAccounts.length);
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handleSelectAccount = (item) => {
    newAdManagementDispatch({
      type: "SET_CUSTOMER_ID",
      value: item.id,
    });

    adManagementHook.setAccountConnected({
      integrationId: isIntegrationId,
      managerId: item.manager,
      customerId: item.id,
      currency: item.currency,
      name: item.name,
      projectId: authState.defaultProjectInfo.projectId
    });
  };

  useEffect(() => {
    adManagementHook.setRecentConnection();
  }, []);
  return (
    <SelectOverlay>
      <SelectPopup>
        <PopupHeaderWrap>
          <PopupHeader>Select Google ad account</PopupHeader>
          <img
            src={closeImg}
            onClick={() => {
              newAdManagementDispatch({
                type: "SET_SELECT_AD_ACCOUNT",
                value: false,
              });
              newAdManagementDispatch({
                type: "SET_LIST_AD_ACCOUNTS",
                value: [],
              });
            }}
          />
        </PopupHeaderWrap>
        <PopupContainer>
          <PopupDescrip>
            Your selected Google Ad account(s) will be added to Adspyder's your
            account. You will receive an email from Google confirming this. By
            connecting your Google Ads account to Adspyder you agree that Google
            may share information about your use of Google Ads with Adspyder
            including the amount you spend on Google Ads. Google may use this
            information to further its business relationship with Adspyder
            including to calculate or pay commissions or bonuses owed to
            Adspyder. Learn more
          </PopupDescrip>
          <InfoText>
            Please connect the ad account related to the domain you've entered
            for the best results in your Flonnect project.{" "}
          </InfoText>
          <PopupDescrip>You can select only 1 account at a time.</PopupDescrip>
          <PopupContent>
            <PopupTableHead>
              <p>Ad account</p>
              <p>Ad account ID</p>
              <p>Connection Status</p>
            </PopupTableHead>
            <TableWrap>
              {!adManagementHook.isListAdAccountsLoading ? (
                presentData.map((item) => (
                  <PopupTableRow isConnected={item.connected}>
                    {listAllAdAccounts.length > 0 ? (
                      <>
                        <p>{item.name}</p>
                        <p>{item.id}</p>
                        {item.connected ? (
                          <p id="connectedStyle">
                            <img src={greenLiveImg} />
                            Connected
                          </p>
                        ) : (
                          <p
                            id="connectedStyle"
                            onClick={() => handleSelectAccount(item)}
                          >
                            Connect now
                          </p>
                        )}
                      </>
                    ) : (
                      <NoData>No Ad account found</NoData>
                    )}
                  </PopupTableRow>
                ))
              ) : (
                <PopupTableRow>
                  <p>
                    <Skeleton
                      style={{
                        lineHeight: "20px",
                        width: "80%",
                      }}
                    />
                  </p>
                  <p>
                    <Skeleton
                      style={{
                        lineHeight: "20px",
                        width: "80%",
                      }}
                    />
                  </p>
                  <p>
                    <Skeleton
                      style={{
                        lineHeight: "20px",
                        width: "80%",
                      }}
                    />
                  </p>
                </PopupTableRow>
              )}
            </TableWrap>
            <PaginationWrapper>
              <RowsList>
                <span>
                  Rows {startRow}-{endRow}
                </span>{" "}
                of {listAllAdAccounts.length} ad accounts
              </RowsList>
              <LeftArrow onClick={handlePrevPage} disabled={currentPage === 1}>
                <img src={paginateArrow} />
              </LeftArrow>
              <RightArrow
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <img src={paginateArrow} />
              </RightArrow>
            </PaginationWrapper>
          </PopupContent>
        </PopupContainer>
      </SelectPopup>
    </SelectOverlay>
  );
};

export default SelectAccount;
