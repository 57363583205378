import styled from "styled-components";

export const ContentHeading = styled.div`
    border-bottom: 1px solid #F2F2F2;
    color: #2E2E2E;
    font-size: 16px;
    font-weight: 500;
    padding: 16px 20px;
    width: 600px;
`;

export const ContentBody = styled.div`
    border-radius: 16px;
    background: #FFF;
    width: 80%;
    max-width: 648px;
    height: 80%;
    max-height: 408px;
    padding: 40px;
`;

export const DetailsContainer = styled.div`
    display: flex;
    margin-bottom: 16px;
    label{
        width: 158px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        color: rgba(28, 28, 28, 0.80);
        font-weight: 500;
        line-height: 26px;
        margin-right: 24px;
    }
    p{
        font-size: 16px;
        color: rgba(28, 28, 28, 0.50);
        font-weight: 500;
        line-height: 26px;
    }
`;

export const PlanDetailsContainer = styled.div`
    display: flex;
    margin-bottom: 15px;
    label{
        width: 158px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        color: rgba(28, 28, 28, 0.80);
        font-weight: 500;
        line-height: 26px;
        margin-right: 24px;
    }
    p{
        font-size: 16px;
        color: rgba(28, 28, 28, 0.50);
        font-weight: 500;
        line-height: 26px;
        display: flex;
        align-items: center;
    }
`;

export const UpgradeButton = styled.div`
    border-radius: 8px;
    background-color: #FF711E;
    color: white;
    font-size: 14px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    margin-left: 24px;
    :hover {
        filter: brightness(90%);
    }
`;