import styled from "styled-components";

export const NavItem = styled.div`
    border-radius: 8px;
    padding: 6px 8px;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
    line-height: 16px;
    margin-top: 4px;
    color: ${props => props.isActive ? "#12558D": "#555555"};
    background: ${props => props.isActive ? "#EEF7FF" : "none"};

`;

export const RightContent = styled.div`
    width: ${props => props.isSideNavDisabled ? "100%" : "calc(100% - 277px)"};
    padding-top: 10px;
    /* margin-left: ${props => props.isSideNavDisabled ? "0px" : "270px"}; */
`;

export const NavBar = styled.div`
    width: 244px;
    padding: 12px;
    border: 1px solid #F2F2F2;
    border-radius: 12px;
    background: #FFFFFF;
    margin-right: 16px;
    
    h2{
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 500;
        padding: 6px 8px;
    }
`;

export const Container = styled.div`
    display: flex;
    padding: 24px 82px;
    /* justify-content: center; */
    /* margin-top: 54px; */
    width: 100%;
    height: calc(100vh - 54px);
`

export const NameEmail = styled.div`

    font-size: 14px;
    font-weight: 300;
    color: #707070;
    padding: 6px 20px;
    h2{
        font-size: 18px;
        font-weight: 600;
        color: #2E2E2E;
        margin-bottom: 8px;
    }
`;

export const RightNavItem = styled.div`
    font-size: 14px;
    color: #2E2E2E;
    font-weight: 500;
    cursor: pointer;
    border-bottom: ${props => props.isActive ? "solid 3px #FF711E" : "none"};
    padding-bottom: 8px;
`;

export const RightNavBar = styled.div`
    border-bottom: 1px solid #EFEFEF;
    display: flex;
    padding: 16px 20px;
    gap: 24px;
    padding-bottom: 8px;
    margin-bottom: 12px;
`;

export const RightBody = styled.div`
    border: 1px solid #F2F2F2;
    border-radius: 12px;
    background: #FFFFFF;
`;





export const PasswordInputContainer = styled.div`
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    label{
        font-size: 14px;
        font-weight: 300;
        color: #2E2E2E;
    }
`;


export const PasswordContainer = styled.div`
    position: relative;
    #eye{
        position: absolute;
        right: 11px;
        top: 8px;
        cursor: pointer;
        color: #a9a9a9;
    }
`;

export const PasswordInput = styled.input`
    background: #FFFFFF;
    border: ${props => props.error ? "1px solid #FF5180" : "1px solid #ECECEC"};
    outline: ${props => props.error ? "2px solid #FFB7CB" : "none"};
    border-radius: 8px;
    width: 100%;
    height: 40px;
    /* margin-top: 8px; */
    padding: 9px 12px;
    font-weight: 300;
    color: ${props => props.theme.colors.lightBlack};
    &:focus{
        border: ${props => props.error ? "1px solid #FF5180" : "1px solid #5AB3FF"};
        outline: ${props => props.error ? "2px solid #FFB7CB" : "2px solid #B9DDFF"};
    }


`;

export const Error = styled.p`
    color: #FF0045;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 300;
`;

export const SubmitContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const CancelButton = styled.button`
    border-radius: 8px;
    font-size: 14px;
    font-weight: 300;
    border: none;
    outline: none;
    padding: 6px 12px;
    margin-right: 12px;
    cursor: pointer;
`;

export const ChangePasswordBtn = styled.button`
    background: ${props => props.disabled ? "#D4D4D4" : "#006ECC"};
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 300;
    border: none;
    outline: none;
    padding: 6px 12px;
    cursor: pointer;
    &:hover{
        background: #0059A5;
    }
`;

export const DetailsContainer = styled.div`
    margin-bottom: 12px;
    display: flex;
    label{
        color: #2E2E2E;
        font-size: 16px;
        font-weight: 300;
        width: 130px;
    }
    p{
        font-weight: 300;
        color: #2E2E2E;
    }
    span{
        font-weight: 500;
    }
`;

export const PlanDetailsContainer = styled.div`
    margin-bottom: 29px;
    display: flex;
    align-items: center;
    label{
        color: #2E2E2E;
        font-size: 16px;
        font-weight: 300;
        margin-right: 8px;
        
    }
    p{
        font-weight: 300;
        color: #2E2E2E;
        display: flex;
        align-items: center;
    }
    span{
        font-weight: 500;
    }
`;

export const UpgradeButton = styled.div`
    border-radius: 8px;
    background-color: #FF711E;
    color: white;
    font-size: 14px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    margin-left: 12px;
    :hover {
        filter: brightness(90%);
    }
`;
