import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { SearchProvider, useSearch } from "./context/search";

// Layout
import Layout from "../../../layout";

// Pages
import Google from "./Google";
import Bing from "./Bing";
import GoogleEcom from "./GoogleEcom";
import BingEcom from "./BingEcom";
import Display from "./Display";
import Amazon from "./Amazon";
import Youtube from "./Youtube";
import Linkedin from "./Linkedin";
import Facebook from "./Facebook";
import Reddit from "./Reddit";
import Twitter from "./Twitter";
import TikTok from "./TikTok";

import { getSearchErrCode } from "../../../helper/getSearchErrCode";

// Not found page
import NotFound from "../../NotFoundPage";

import { appClient } from "../../../../utils/axios-utils";
import { useAuth } from "../../../../context/auth";
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";
import AdspyHome from "./AdspyHome/components";
import LimitExceededPopup from "../../../components/limitExceededPopup";
import { useDashboard } from "../../../../context/dashboard";
import getCountryByDialingCode from "../../Dashboard/utils/getCountryByDialingCode";

const userFav = () => {
  const config = {
    url: "/adgeneration/saved-ads",
    method: "GET",
  };

  return appClient(config);
};

const AdSpyComponent = () => {
  const { searchState, searchDispatch } = useSearch();
  const {
    authState: { userLocation, defaultProjectInfo },
  } = useAuth();
  const useGetUserInfoHook = useGetUserInfo();
  const {
    dashboardState: { selectedCountry, selectedProject },
    dashboardDispatch,
  } = useDashboard();
  // let userFavQuery = useQuery(["user-fav"], userFav, {
  //     retry: false,
  //     // refetchInterval: 20000,
  //     refetchOnWindowFocus: false,
  //     refetchOnMount: false,
  //     enabled: false,
  //     onSuccess: (data)=>{
  //       authDispatch({type:'SET_FAV_ADS_LIST', value: data})
  //     },
  //     onError: (err) => {

  //     },
  // });
  useEffect(() => {
    // userFavQuery.refetch();
    useGetUserInfoHook.getUserFavourite();
    // Create the interceptor
    const interceptor = appClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        let possibleErrCodes = [
          "adviews-exhausted",
          "searches-exhausted",
          "access-time-expired",
          "plan-inactive",
        ];
        if (
          error?.config?.url?.includes("/search") &&
          possibleErrCodes.includes(error?.response?.data?.errors[0]?.errCode)
        ) {
          searchDispatch({ type: "UPDATE_LIMIT_EXCEEDED", value: true });
          searchDispatch({
            type: "UPDATE_SEARCH_ERROR",
            value: getSearchErrCode(error),
          });
        }
        console.log(error);
        return Promise.reject(error);
      }
    );

    // Clean up the interceptor when the component unmounts
    return () => {
      appClient.interceptors.response.eject(interceptor);
    };
  }, []);

  useEffect(() => {
    if (!selectedCountry) {
      dashboardDispatch({
        type: "SET_SELECTED_COUNTRY",
        value: getCountryByDialingCode(userLocation.countryCode).name,
      });
    }
    if (!selectedProject) {
      dashboardDispatch({
        type: "SET_SELECTED_PROJECT",
        value: { ...defaultProjectInfo, id: defaultProjectInfo.projectId },
      });
    }
  }, []);

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route
        path="/"
        element={
          <Layout>
            <AdspyHome />
          </Layout>
        }
      />
      <Route
        path="google"
        element={
          <Layout>
            <Google />
          </Layout>
        }
      />
      <Route
        path="bing"
        element={
          <Layout>
            <Bing />
          </Layout>
        }
      />
      <Route
        path="youtube"
        element={
          <Layout>
            <Youtube />
          </Layout>
        }
      />
      <Route
        path="googlepla"
        element={
          <Layout>
            <GoogleEcom />
          </Layout>
        }
      />
      <Route
        path="bingpla"
        element={
          <Layout>
            <BingEcom />
          </Layout>
        }
      />
      <Route
        path="display"
        element={
          <Layout>
            <Display />
          </Layout>
        }
      />
      <Route
        path="amazon"
        element={
          <Layout>
            <Amazon />
          </Layout>
        }
      />
      {/* <Route path="flipkart" element={
                <Layout>
                    <Flipkart />
                </Layout>
            } /> */}
      <Route
        path="linkedin"
        element={
          <Layout>
            <Linkedin />
          </Layout>
        }
      />
      <Route
        path="meta"
        element={
          <Layout>
            <Facebook />
          </Layout>
        }
      />
      <Route
        path="reddit"
        element={
          <Layout>
            <Reddit />
          </Layout>
        }
      />
      <Route
        path="twitter"
        element={
          <Layout>
            <Twitter />
          </Layout>
        }
      />
      <Route
        path="tiktok"
        element={
          <Layout>
            <TikTok />
          </Layout>
        }
      />
    </Routes>
  );
};

const SearchProviderWrapper = () => {
  return (
    <SearchProvider>
      <AdSpyComponent />
    </SearchProvider>
  );
};

export default SearchProviderWrapper;
