import { useEffect } from "react";
// import { useDashboard } from "../context/dashboard";
import { Container, Wrapper } from "./index.styled";
import ProjectList from "./ProjectList";
import ProjectOverview from "./ProjectOverview";
import AddNewProject from "./AddProject";
import { useDashboardInfo } from "../hooks/useDashboardInfo";
import { useAuth } from "../../../../context/auth";
import LimitExceededPopup from "../../../components/limitExceededPopup";
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";
import { useDashboard } from "../../../../context/dashboard";
function Root() {
  const {
    dashboardState: { selectedProject, popupActive, projectList, keywordLimitExceeded, competitorLimitExceeded, projectLimitExceeded},
    dashboardDispatch
  } = useDashboard();
  const useDashboardInfoHook = useDashboardInfo();
  const { getRemainingCreditsMutate } = useGetUserInfo()
  const { authState, authDispatch } = useAuth()

  useEffect(() => {
    console.log("authState.selectedProject",authState.selectedProject)
    if(authState.selectedProject){
      console.log()
      useDashboardInfoHook.getIndividualProject(authState.selectedProject.id);
    }
  }, [authState.selectedProject])

  const closePopup = () => {
    dashboardDispatch({
      type: "SET_POPUP_ACTIVE",
      value: false
    })
  }

  useEffect(() => {
    getRemainingCreditsMutate()
  }, [])

  console.log(selectedProject)

  return (
    <Wrapper>
      <Container paddingProps={authState.selectedProject && projectList?.length != 1 ? true : false}>
        {authState.selectedProject ? <ProjectOverview /> : <ProjectList />}
      </Container>
      {popupActive && <AddNewProject /> /*  (projectCountStillCanAdd > 0 ?  <AddNewProject /> : <LimitExceededPopup closeEnabled onClose={closePopup} err="add-project-quota-expired" /> ) */ }
    </Wrapper>
  );
}

export default Root;
