import React, { useState, useEffect } from "react";
import {
  DetailContainer,
  LevelOneDetailContainer,
  AdPreviewContainer,
  FlagLogo,
  LevelTwoDetailsContainer,
  ChannelDetails,
  LogoContainer,
  ChannelName,
  IntractionDetails,
  SaveAdsButton,
  MediaContainer,
  DetailsImageContainer,
  DetailsVideoContainer,
  DescriptionContainer,
  DescriptionHeader,
  CopyButton,
  DestinationUrlContainer,
  DestinationUrlHeader,
  CountryConainer,
  CountryHeader,
  MapsList,
  LastSeenContainer,
  LastSeenHeader,
  OtherDetailsContainer,
  LevelTwoRow,
  AdCtaContainer,
  AdCtaRowOneContainer,
  AdCtaRowTwoContainer,
  CtaRowTwoSubContainer,
  SecondaryDetailsContainer,
  PageCategoryContainer,
  PageCategoryList,
  DescriptionWrapper,
  LikeWrapper,
} from "./index.styled";
import GlobeIcon from "../../../../../media/Dashboard/globe.svg";
import CopyImage from "../../../../../media/Dashboard/CopyImg.svg";
import HugeIcon from "../../../../../media/Dashboard/HugeIcon.svg";
import SendPost from "../../../../../media/Dashboard/SendPost.svg";
import RedirectOrangeIcon from "../../../../../media/Dashboard/RedirectOrangeIcon.svg";
import { countryList } from "../../../../../utils/maps";
import { appClient } from "../../../../../utils/axios-utils";
import { formatNumber } from "../../../../../utils/formatTotal";
import { Tooltip } from "react-tooltip";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
// import { LineChartGradient } from "./components/AreaChart";
// import ApexChartFn from "./components/GaugeChart";
import { CircleFlag } from "react-circle-flags";
import { useSaveAds } from "../../../../hooks/useSaveAds";
import { useAuth } from "../../../../../context/auth";
import copiedImage from "../../../../../media/Dashboard/copiedImage.svg";
import { useNavigate } from "react-router-dom";

const getAdDetails = async (id) => {
  const config = {
    url: `/detail/meta/${id}`,
    method: "GET",
  };

  return appClient(config);
};

const GoogleDetail = ({ basicDetail, highlightWords, onSave, crawledAd }) => {
  const navigate = useNavigate();

  const [adDetails, setAdDetails] = useState(basicDetail);
  const [detailsLoading, setDetailsLoading] = useState(true);
  const [copyContent, setCopyContent] = useState(false);
  const [copyURL, setCopyURL] = useState(false);

  const { authState, authDispatch } = useAuth();
  const useSaveAdsHook = useSaveAds();
  const getFlagEmoji = (countryName) => {
    let countryCode = countryList[countryName];
    if (countryCode) {
      return (
        <FlagLogo>
          <CircleFlag countryCode={countryCode.toLowerCase()} height="24" />
        </FlagLogo>
      );
    }
  };

  // const { mutate: adsDetailsMutate, isLoading: isDetailsLoading } = useMutation(getAdDetails, {
  //     onSuccess: (data) => {
  //         console.log("adDetails",adDetails)
  //         setAdDetails(data)
  //         setDetailsLoading(false)
  //     },
  //     onError: ((e) => {
  //         console.log("err", e)
  //         setDetailsLoading(false)
  //         setAdDetails({})
  //     })
  // })

  const onRemoveSavedAd = (ad) => {
    useSaveAdsHook.RemoveSavedAdMutate({
      projectId: authState.defaultProjectInfo.projectId,
      adId: `${ad.id}`,
      platform: "facebook",
    });
  };
  const convertTimestamp = (timestamp) => {
    // Create a new Date object based on the timestamp
    var date = new Date(timestamp);

    // Get the month name
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var month = monthNames[date.getMonth()];

    // Get the year
    var year = date.getFullYear();

    // Assemble the formatted date string
    var formattedDate = date.getDate() + " " + month + " " + year;

    return formattedDate;
  };
  const analyzeDomian = () => {
    const url = new URL(basicDetail?.url);
    const domain = url.hostname;
    navigate(`/domain-overview/details?domain=${domain}&country=global`);
  };
  useEffect(() => {
    // adsDetailsMutate(basicDetail.id)
  }, []);

  // if(detailsLoading){
  //     return <h2>Loading</h2>
  // }else{
  return (
    <DetailContainer>
      <LevelOneDetailContainer>
        <h2>Ad Details</h2>
        <AdPreviewContainer>
          <ChannelDetails>
            <LogoContainer>
              <img
                src={
                  basicDetail.logo
                    ? `https://aws-adspyder-logos.s3.ap-south-1.amazonaws.com/${basicDetail.id}`
                    : GlobeIcon
                }
              />
            </LogoContainer>
            <ChannelName>
              {basicDetail.title}
              <p>
                PageID - <span>{basicDetail.pageId}</span>
              </p>
            </ChannelName>
          </ChannelDetails>
          <IntractionDetails>
            {basicDetail.pageLikesCount && (
              <LikeWrapper>
                <img src={HugeIcon} />
                <p>{formatNumber(basicDetail.pageLikesCount)}</p>
              </LikeWrapper>
            )}
            {basicDetail.shares && (
              <LikeWrapper>
                <img src={SendPost} />
                <p>{basicDetail.shares}</p>
              </LikeWrapper>
            )}
            <SaveAdsButton
              isSaved={authState.savedAdsIds.includes(basicDetail.id)}
              onClick={(e) => {
                e.stopPropagation();
                if (authState.savedAdsIds.includes(basicDetail.id)) {
                  onRemoveSavedAd(basicDetail);
                } else {
                  onSave(basicDetail);
                }
              }}
            >
              {authState.savedAdsIds.includes(basicDetail.id)
                ? "Saved"
                : "Save"}
            </SaveAdsButton>
          </IntractionDetails>
        </AdPreviewContainer>
        <MediaContainer>
          {/* {basicDetail.image && (
            <DetailsImageContainer>
              <img
                onLoad={(e) => {
                  // setHeight(e.target.naturalHeight)
                  // setWidth(e.target.naturalWidth)
                }}
                src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${basicDetail.id}`}
              /> */}
          {/* <span>{height} X {width}</span> */}
          {/* </DetailsImageContainer>
          )} */}

          {basicDetail?.image &&
            (basicDetail?.imageIds ? (
              <Carousel
                autoPlay
                infiniteLoop
                interval={5000}
                transitionTime={500}
              >
                {basicDetail?.imageIds.map((imid) => {
                  return (
                    <DetailsImageContainer key={imid}>
                      <img
                        src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${imid}`}
                      />
                    </DetailsImageContainer>
                  );
                })}
              </Carousel>
            ) : (
              <DetailsImageContainer>
                <img
                  src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${basicDetail.id}`}
                />
              </DetailsImageContainer>
            ))}

          {/* {basicDetail.video && <DetailsVideoContainer>
                    <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${basicDetail.id}`} preload="auto" controls loop style={{width: "100%", height: "100%"}}/>
                </DetailsVideoContainer>} */}

          {basicDetail?.video &&
            (basicDetail?.videoIds ? (
              <Carousel
                autoPlay
                infiniteLoop
                interval={5000}
                transitionTime={500}
              >
                {basicDetail?.videoIds.map((vid) => {
                  return (
                    <DetailsVideoContainer key={vid}>
                      <video
                        src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${vid}`}
                        preload="auto"
                        controls
                        loop
                        style={{ width: "100%", height: "100%" }}
                      />
                    </DetailsVideoContainer>
                  );
                })}
              </Carousel>
            ) : (
              <DetailsVideoContainer>
                <video
                  src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${basicDetail.id}`}
                  preload="auto"
                  controls
                  loop
                  style={{ width: "100%", height: "100%" }}
                />
              </DetailsVideoContainer>
            ))}
        </MediaContainer>
        <DescriptionWrapper>
          <DescriptionContainer>
            <DescriptionHeader>
              <h3>Primary Text</h3>
              <CopyButton
                onClick={() => {
                  !copyContent && setCopyContent(true);
                  navigator.clipboard.writeText(basicDetail.content);
                  !copyContent &&
                    setTimeout(() => {
                      setCopyContent(false);
                    }, 3000);
                }}
                data-tooltip-id="content-tooltip"
                data-tooltip-content={copyContent ? "Copied" : "Copy"}
              >
                {!copyContent ? (
                  <img src={CopyImage} />
                ) : (
                  <img src={copiedImage} />
                )}
                <Tooltip
                  id="content-tooltip"
                  noArrow={true}
                  place={"bottom"}
                  style={{
                    backgroundColor: "#101828",
                    color: "#FFF",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    borderRadius: "8px",
                    fontWeight: 600,
                    height: "34px",
                    padding: "8px 12px",
                    display: "flex",
                    alignItems: "center",
                  }}
                />
              </CopyButton>
            </DescriptionHeader>
            <p>{basicDetail.content}</p>
          </DescriptionContainer>
        </DescriptionWrapper>
      </LevelOneDetailContainer>
      <LevelTwoDetailsContainer>
        <h2>Ad Analytics</h2>
        <LevelTwoRow>
          <OtherDetailsContainer>
            <DestinationUrlContainer>
              <DestinationUrlHeader>
                <h3>
                  Destination URL
                  <p onClick={analyzeDomian}>
                    <img src={RedirectOrangeIcon} />
                    analyze domain
                  </p>
                </h3>
                <CopyButton
                  onClick={() => {
                    !copyURL && setCopyURL(true);
                    navigator.clipboard.writeText(adDetails.url);
                    !copyURL &&
                      setTimeout(() => {
                        setCopyURL(false);
                      }, 3000);
                  }}
                  data-tooltip-id="url-tooltip"
                  data-tooltip-content={copyURL ? "Copied" : "Copy"}
                >
                  {!copyURL ? (
                    <img src={CopyImage} />
                  ) : (
                    <img src={copiedImage} />
                  )}
                  <Tooltip
                    id="url-tooltip"
                    noArrow={true}
                    place={"bottom"}
                    style={{
                      backgroundColor: "#101828",
                      color: "#FFF",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      borderRadius: "8px",
                      fontWeight: 600,
                      height: "34px",
                      padding: "8px 12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  />
                </CopyButton>
              </DestinationUrlHeader>
              <p>{basicDetail?.url}</p>
            </DestinationUrlContainer>
            <CountryConainer>
              <CountryHeader>
                <h3>Country</h3>
              </CountryHeader>
              {basicDetail.country.length > 0 ? (
                <MapsList>
                  {basicDetail.country.map((c, idx) => {
                    if (basicDetail.country.length > 5 ? idx < 5 : true) {
                      return getFlagEmoji(c);
                    }
                  })}
                  {basicDetail.country.length > 5 &&
                    "1" + (basicDetail.country - 5)}
                </MapsList>
              ) : (
                <p>---</p>
              )}
              <p>
                {basicDetail.country.length == 1
                  ? basicDetail.country[0]
                  : basicDetail.country.length > 1
                  ? basicDetail.country[0] +
                    " + " +
                    (basicDetail.country.length - 1)
                  : ""}
              </p>
            </CountryConainer>
            <LastSeenContainer>
              <LastSeenHeader>
                <h3>Last Seen</h3>
              </LastSeenHeader>
              <div>
                <p>{convertTimestamp(basicDetail.lastSeen).split(" 20")[0]}</p>
                <span>
                  20{convertTimestamp(basicDetail.lastSeen).split(" 20")[1]}
                </span>
              </div>
            </LastSeenContainer>
          </OtherDetailsContainer>
          <AdCtaContainer>
            <AdCtaRowOneContainer>
              <h3>CTA</h3>
              <p>
                <span>
                  Buy an Apple Watch SE and get 3 months of Apple Fitness+ free.
                  Featuring guided workouts and world class trainers. Terms
                  apply.
                </span>
              </p>
            </AdCtaRowOneContainer>
            <AdCtaRowTwoContainer>
              <CtaRowTwoSubContainer>
                <h3>CTA URL</h3>
                <p>
                  <span>{basicDetail.ctaUrl ? basicDetail.ctaUrl : "--"}</span>
                </p>
              </CtaRowTwoSubContainer>
              <CtaRowTwoSubContainer>
                <h3>CTA desc</h3>
                <p>
                  <span>
                    {basicDetail.ctaContent ? basicDetail.ctaContent : "--"}
                  </span>
                </p>
              </CtaRowTwoSubContainer>
            </AdCtaRowTwoContainer>
          </AdCtaContainer>
          <SecondaryDetailsContainer>
            <PageCategoryContainer>
              <h3>Page category</h3>
              <PageCategoryList>
                {basicDetail.pageCategory?.length > 0
                  ? basicDetail.pageCategory.map((category) => {
                      return <p>{category}</p>;
                    })
                  : "-"}
              </PageCategoryList>
            </PageCategoryContainer>
            {/* <MediaTypeContainer>
                        <h3>
                            Media type
                        </h3>
                        <MediaTypeList>
                            {basicDetail.image ? <p><img src={PhotoLogo}/>Image</p>: <p><img src={PhotoLogo}/>Video</p>}
                        </MediaTypeList>
                    </MediaTypeContainer> */}
          </SecondaryDetailsContainer>
        </LevelTwoRow>
      </LevelTwoDetailsContainer>
    </DetailContainer>
  );
};

export default GoogleDetail;
