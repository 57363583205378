import styled from 'styled-components';


export const Content = styled.div`
    width: 100%;
    height: 32px;

    display: flex;
    /* align-items: center; */
    /* justify-content: center; */

    margin-top: 1rem;
`;

export const Separator = styled.div`
    width: 1.5px;
    height: 15px;
    background-color: #D4D4D4;
    border-radius: 8px;

    /* margin: 0 8px; */
    margin: 8px 10px auto;
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
`;

export const SearchContainer = styled.div`
    margin-bottom: 17px;
`;


export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    max-width: 1532px;
    width: calc(100% - 108px);
    margin: 31px 54px 6px;
    
    h2{
        font-size: 22px;
        font-weight: 600;
        color: #2E2E2E;
    }
`;

export const HeaderLeft = styled.div`
display: flex;
gap: 21px;
align-items: center;
`;


export const HeaderRight = styled.div`
    display: flex;
    height: 38px;
    gap: 8px;
`;


export const TotalAdsFound = styled.div`
    margin-right: 12px;
    display: flex;
    gap: 4px;
    align-items: center;
    label{
        font-size: 13px;
        font-weight: 300;
        color: #555;
    }
    span{
        font-size: 14px;
        font-weight: 500;
        color: #555;
    }
`;

export const SearchQuery  = styled.div`
    margin-left: 8px;
    span{
        border-radius: 4px;
        color: #919191;
        background: #EFEFEF;
        padding: 1px 6px;
        font-size: 12px;
        font-weight: 300;
        margin-right: 8px;
    }
`;

export const AdsCount  = styled.div`
display: flex;
    margin: 30px 0px 35px;
    p{
        font-size: 14px;
        font-weight: 300;
        color: #2E2E2E;
        position: absolute;
        top: -7px;
        background: #f6f7fb;
        padding-right: 10px;
    }
    span{
        font-weight: 500;
        color: #2E2E2E;
    }
    div{
        height: 1px;
        position: relative;
        background: rgb(218, 223, 228);
        width: 100%;
        max-width: 1457px;
    }
`;


export const MainSearch = styled.div`
    
`;

export const ScrollableContainer = styled.div`
    overflow-y: scroll;
    height: calc(-240px + 100%);
    /* max-width: 1532px; */
    width: calc(100% - 80px);
    margin: 24px 54px;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }
`;

export const Breadcrumb = styled.div`
    width: 163px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid #DADFE4;
    background: #F6F7FB;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgba(28, 28, 28, 0.90);
    leading-trim: both;
    text-edge: cap;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
    margin: 24px 54px;
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0px 30px;
    max-width: 1532px;
    width: 100%;
`;

export const TotalResults = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: rgba(28, 28, 28, 0.50);
    p{
        display: flex;
        gap: 5px;
    }
`;

export const Divider = styled.div`
    border-bottom: solid 1px #DADFE4;
    padding: 0px 54px;
    padding-bottom: 14px;
    display: flex;
    gap: 8px;
    color: rgba(28, 28, 28, 0.50);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
`;

export const AppliedFilters = styled.div`
display: flex;
gap: 8px;
`

export const AppliedFilterItem = styled.div`
    border-radius: 4px;
    background: #FFF7E9;
    font-size: 12px;
    font-weight: 400;
    color: #646464;
    display: flex;
    padding: 0px 6px;
    gap: 6px;
    width: fit-content;
    height: 18px;
    align-items: center;
`;

export const Close = styled.div`
    color: #646464;
    opacity: 0.6;
    cursor: pointer;
`;
