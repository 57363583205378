import React, { useState } from "react";
import {
  CompetitorsDiv,
  CompetitorsList,
  GenerateBtn,
  HeadList,
  ImageAdsDiv,
  InfoDetails,
  InfoList,
  KeywordDiv,
  KeywordWrap,
  ProjectContainer,
  ProjectContent,
  ProjectDiv,
  ProjectTopDiv,
  SavedAdsDiv,
  ScrollWrapper,
  SetupAnalysisWrap,
  SetupInfo,
  TextAdsDiv,
  TimeLimit,
  VerticalLine,
  ViewMoreWraper,
} from "./index.styled";
import Statics from "../../_static";
// import { useDashboard } from "../../../../../context/dashboard";
import AddNewPopup from "../../../../../../../components/AddNewPopup";
import { useDashboardInfo } from "../../../../../hooks/useDashboardInfo";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../../../../context/auth";
import LimitExceededPopup from "../../../../../../../components/limitExceededPopup";
import { useDashboard } from "../../../../../../../../context/dashboard";
import setUpIcon from "../../assets/setupIcon.svg";
import animationGif from "../../assets/animationSetup.gif";

const ProjectDetails = () => {
  const [isAddNewPopupOpened, setAddNewPopupOpened] = useState(false);
  const [isAddCategory, setAddCategory] = useState(null);
  const navigate = useNavigate();
  const { authState, authDispatch } = useAuth();

  const {
    projectData: { heading },
  } = Statics;

  const {
    dashboardState: {
      selectedProject,
      keywordChoices,
      selectedCountry,
      competitorChoices,
    },
    dashboardDispatch,
  } = useDashboard();
  const useDashboardInfoHook = useDashboardInfo();

  const openAddNewPopup = (category) => {
    setAddNewPopupOpened(true);
    setAddCategory(category);
  };
  const closeAddNewPopup = () => {
    setAddNewPopupOpened(false);
  };

  const displayHeadings = authState.defaultProjectInfo.aiProcessed
    ? heading
    : heading.slice(0, 3);

  const handleSetup = (chosenChoicesIndices, userSelectedList) => {
    if (isAddCategory && isAddCategory !== "CompetitorCategory") {
      const keywordData = chosenChoicesIndices.map((index) => {
        return {
          keyword: keywordChoices[index].keyword,
          source: "suggested",
          country: selectedCountry,
        };
      });

      const userEnteredKeywordData = userSelectedList.map((data) => {
        return {
          keyword: data,
          source: "user",
          country: selectedCountry,
        };
      });
      useDashboardInfoHook.addKeywordsForTrackingMutate({
        projectId: selectedProject.id,
        keywords: [...keywordData, ...userEnteredKeywordData],
      });
    } else {
      const competitorsData = chosenChoicesIndices.map((index) => {
        return {
          domain: competitorChoices[index].domainName,
          source: "suggested",
        };
      });
      const userEnteredCompetitorData = userSelectedList.map((data) => {
        return {
          domain: data,
          source: "user",
          country: selectedCountry,
        };
      });
      useDashboardInfoHook.addCompetitorsForTrackingMutate({
        projectId: selectedProject.id,
        domains: [...competitorsData, ...userEnteredCompetitorData],
      });
    }
    closeAddNewPopup();
  };

  return (
    <ProjectContainer>
      <ScrollWrapper>
        <ProjectTopDiv>
          {displayHeadings.map((title, idx) => (
            <HeadList widths={title.widthValue} key={idx}>
              <img src={title.projectLogo} alt={`${title.name} logo`} />
              <p>{title.name}</p>
            </HeadList>
          ))}
          {authState.defaultProjectInfo.setupDomains &&
            authState.defaultProjectInfo.setupKeywords &&
            (authState.defaultProjectInfo.aiProcessed ? (
              ""
            ) : (
              <HeadList widths={"36%"} className="setUpHead">
                <img src={setUpIcon} />
                <p>Setup Analysis in Progress</p>
              </HeadList>
            ))}
        </ProjectTopDiv>
        <ProjectContent>
          <ProjectDiv>
            {selectedProject.website ? selectedProject.website : ""}
          </ProjectDiv>
          <KeywordDiv>
            {selectedProject?.keywords?.length > 0 ? (
              <KeywordWrap>
                {selectedProject.keywords.map((keyword) => {
                  return <p>{keyword}</p>;
                })}
                {/* {selectedProject.keywords.length >= 5 && ( */}
                <ViewMoreWraper
                  onClick={() => openAddNewPopup("keywordCategory")}
                >
                  <span>+ Add new keyword</span>
                </ViewMoreWraper>
                {/* )} */}
              </KeywordWrap>
            ) : (
              <ProjectDiv id="setupNow">
                <ViewMoreWraper
                  onClick={() => openAddNewPopup("keywordCategory")}
                >
                  + setup now
                </ViewMoreWraper>
              </ProjectDiv>
            )}
          </KeywordDiv>
          <CompetitorsDiv>
            {selectedProject?.competitors?.length > 0 ? (
              <KeywordWrap>
                {selectedProject.competitors.map((track, colorIndex) => {
                  const className = colorIndex % 2 === 0 ? "" : "evenClass";
                  return (
                    <CompetitorsList
                      colorIndex={colorIndex}
                      className={className}
                    >
                      {track}
                    </CompetitorsList>
                  );
                })}
                {/* {selectedProject.competitors.length >= 5 && ( */}
                <ViewMoreWraper
                  onClick={() => openAddNewPopup("CompetitorCategory")}
                >
                  <span>+ Add new Comp</span>
                </ViewMoreWraper>
                {/* )} */}
              </KeywordWrap>
            ) : (
              <ProjectDiv id="setupNow">
                <ViewMoreWraper
                  onClick={() => openAddNewPopup("CompetitorCategory")}
                >
                  + setup now
                </ViewMoreWraper>
              </ProjectDiv>
            )}
          </CompetitorsDiv>

          {authState.defaultProjectInfo.setupDomains &&
            authState.defaultProjectInfo.setupKeywords &&
            (!authState.defaultProjectInfo.aiProcessed ? (
              <SetupAnalysisWrap>
                <TimeLimit>
                  <img src={animationGif} />
                  <p>Max 2 hours</p>
                </TimeLimit>
                <VerticalLine></VerticalLine>
                <SetupInfo>
                  <InfoDetails>
                    We're analyzing your setup to fine-tune ad personas and
                    results. Once Complete, You’ll Unlock :
                  </InfoDetails>
                  <InfoList>
                    <li>Advanced filters in AdSpy search</li>
                    <li>Competitor-based personas</li>
                    <li>More precise ad generation results</li>
                  </InfoList>
                </SetupInfo>
              </SetupAnalysisWrap>
            ) : (
              <>
                <TextAdsDiv>
                  {selectedProject.textAdsCount != 0 ? (
                    selectedProject.textAdsCount
                  ) : (
                    <GenerateBtn
                      onClick={() => navigate(`/adgenerator/text-ad`)}
                    >
                      Generate
                    </GenerateBtn>
                  )}
                </TextAdsDiv>
                <ImageAdsDiv>
                  {selectedProject.imageAdsCount != 0 ? (
                    selectedProject.imageAdsCount
                  ) : (
                    <GenerateBtn
                      onClick={() => navigate(`/adgenerator/image-ad`)}
                    >
                      Generate
                    </GenerateBtn>
                  )}
                </ImageAdsDiv>
                <SavedAdsDiv>
                  {selectedProject.savedAdsCount != "0" ? (
                    selectedProject.savedAdsCount
                  ) : (
                    <ViewMoreWraper id="saveAdCopy">
                      Explore adcopy & save now
                    </ViewMoreWraper>
                  )}
                </SavedAdsDiv>
              </>
            ))}
        </ProjectContent>
      </ScrollWrapper>
      {isAddNewPopupOpened && (
        <AddNewPopup
          handleSetup={handleSetup}
          handleClose={closeAddNewPopup}
          forKeyword={isAddCategory === "keywordCategory"}
        />
      )}
    </ProjectContainer>
  );
};

export default ProjectDetails;
