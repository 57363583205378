import React, { useState, useEffect, useRef } from "react";
import {
  AdHeadWrap,
  AdHeader,
  AdHeaderContainer,
  AdResultWrap,
  CalendarContainer,
  CalendarDropDown,
  CalenderWrap,
  ContainerToggle,
  CustomContainer,
  CustomDiv,
  DropDownDiv,
  DropDownWrap,
  InputDiv,
  ManageButton,
  ManageWrap,
  PlatformAccount,
  PlatformAccountWrap,
  ResultList,
  ResultWrapper,
  SelectWrap,
  StatusContainer,
  StatusDiv,
  VerticalLine,
} from "./index.styled";
import calendarImg from "../../../../../../media/Dashboard/calendarAd.svg";
import rightArrowImg from "../../../../../../media/Dashboard/arrow-right.svg";
import downArrowImg from "../../../../../../media/Dashboard/angle-down.svg";
import checkImg from "../../../../../../media/Dashboard/check.svg";
import markImg from "../../../../../../media/Dashboard/mark.svg";
import adsIcon from "../../../../../../media/Dashboard/ads-1.svg";
import adResultData from "../../static";
import AccountDataDetails from "./components/AccountDataDetails";
import CampaignComponent from "./components/CampaignComponent";
import DetailsComponent from "./components/DetailsComponent";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
// import moment from "moment";
import { extendMoment } from "moment-range";
import { useAdIntegration } from "../../context/AdManagementContext";
import ManagePopup from "./components/ManagePopup";
import SelectAccount from "../InputPages/components/selectAccount";
import { useAdIntegrationInfo } from "../../hooks/useAdManagementInfo";
import { useAuth } from "../../../../../../context/auth";
import searchIcon from "../../assets/searchIcon.svg";
import selectTick from "../../assets/blackTick.png";
import moment from "moment";
import { useOutsideAlerter } from "../../../../../../utils/outsiderAlert";
import GoogleAdsImg from "../../../../../../media/Dashboard/GoogleAds.svg";
import facebookAdsImg from "../../../../../../media/Dashboard/facebookNew.svg";
import linkedinAdsImg from "../../../../../../media/Dashboard/linkedin.svg";
import amazonAdsImg from "../../../../../../media/Dashboard/amazon.svg";
// const moment = extendMoment(originalMoment);

const ResultPages = () => {
  const { platformArray } = adResultData;
  // const today = moment();
  const [isOpen, setIsOpen] = useState(false);
  // const [value, setValue] = useState(
  //   moment.range(today.clone().subtract(3, "months"), today.clone())
  // );
  const [show, setShow] = useState(false);
  const [dateOption, setDateOption] = useState(null);

  const {
    newAdManagementState: {
      manageAccount,
      selectAdAccount,
      connectByProjectId,
      dateStartEnd,
      isStatusDetail,
      isUpdateApiToggle,
      selectedId,
    },
    newAdManagementDispatch,
  } = useAdIntegration();
  const today = moment();
  const { authState, authDispatch } = useAuth();
  const {
    setUpdateCampaignMetric,
    setCampaignOverview,
    setProjectIdByConnect,
  } = useAdIntegrationInfo();
  let customerRef = useRef(null);
  let dateRangeRef = useRef(null);

  const onSelect = (val, states) => {
    newAdManagementDispatch({ type: "SET_DATE_START_END", value: val });
    if (
      val.start._d >= new Date(selectedId.existingStartDate) &&
      val.end._d <= new Date(selectedId.existingEndDate)
    ) {
      newAdManagementDispatch({
        type: "SET_UPDATE_API_TOGGLE",
        value: !isUpdateApiToggle,
      });
      setCampaignOverview({
        projectId: authState.defaultProjectInfo.projectId,
        customerId: selectedId.customerId,
        startDate: val.start.format("YYYY-MM-D"),
        endDate: val.end.format("YYYY-MM-D"),
      });
    } else {
      setUpdateCampaignMetric({
        projectId: authState.defaultProjectInfo.projectId,
        customerId: selectedId?.customerId,
        startDate: val.start.format("YYYY-MM-D"),
        endDate: val.end.format("YYYY-MM-D"),
      });
    }

    setIsOpen(false);
  };

  const onToggle = () => {
    setDateOption(null);
    setIsOpen(!isOpen);
  };

  let initialDate = moment.range(
    today.clone().subtract(3, "months"),
    today.clone()
  );

  useOutsideAlerter(customerRef, setShow);
  useOutsideAlerter(dateRangeRef, setIsOpen);

  return (
    <ResultWrapper>
      <AdResultWrap>
        <AdHeadWrap>
          <AdHeaderContainer>
            <AdHeader>
              Ad Account Overview - <span>flonnect</span>
            </AdHeader>
            <p>
              Detailed Insights into Campaign Performance, Audience Behavior,
              and Budget Utilization
            </p>
          </AdHeaderContainer>
          <StatusContainer>
            <StatusDiv
              isSelected={isStatusDetail == "all"}
              onClick={() =>
                newAdManagementDispatch({
                  type: "SET_STATUS_DETAIL",
                  value: "all",
                })
              }
            >
              all
            </StatusDiv>
            <StatusDiv
              isSelected={isStatusDetail == "enabled"}
              onClick={() =>
                newAdManagementDispatch({
                  type: "SET_STATUS_DETAIL",
                  value: "enabled",
                })
              }
            >
              enabled
            </StatusDiv>
            <StatusDiv
              isSelected={isStatusDetail == "paused"}
              onClick={() =>
                newAdManagementDispatch({
                  type: "SET_STATUS_DETAIL",
                  value: "paused",
                })
              }
            >
              paused
            </StatusDiv>
            <CalenderWrap ref={dateRangeRef}>
              <CalendarContainer CalendarContainer onClick={onToggle} isArrow={isOpen}>
                <img src={calendarImg} />
                {dateStartEnd.start.format("MMM D, YYYY")}
                <img src={rightArrowImg} />
                {dateStartEnd.end.format("MMM D, YYYY")}
                <img src={downArrowImg} className="arrowToggle"/>
              </CalendarContainer>
              {isOpen && (
                <CalendarDropDown>
                  <CustomContainer>
                    <CustomDiv
                      // isActive={dateOption == 0}
                      onClick={() => {
                        setDateOption(0);
                        onSelect(
                          moment.range(today.startOf("day"), today.clone())
                        );
                      }}
                    >
                      Today
                    </CustomDiv>
                    <CustomDiv
                      // isActive={dateOption == 1}
                      onClick={() => {
                        setDateOption(1);
                        onSelect(
                          moment.range(
                            today.clone().subtract(1, "days").startOf("day"),
                            today.clone().subtract(1, "days").endOf("day")
                          )
                        );
                      }}
                    >
                      Yesterday
                    </CustomDiv>
                    <CustomDiv
                      // isActive={dateOption == 2}
                      onClick={() => {
                        setDateOption(2);
                        onSelect(
                          moment.range(
                            today.clone().subtract(7, "days"),
                            today.clone().subtract(1, "days")
                          )
                        );
                      }}
                    >
                      Last 7 days
                    </CustomDiv>{" "}
                    <CustomDiv
                      // isActive={dateOption == 3}
                      onClick={() => {
                        setDateOption(3);
                        onSelect(
                          moment.range(
                            today.clone().subtract(1, "month").startOf("month"),
                            today.clone().subtract(1, "month").endOf("month")
                          )
                        );
                      }}
                    >
                      Last month
                    </CustomDiv>{" "}
                    <CustomDiv
                      // isActive={dateOption == 4}
                      onClick={() => {
                        setDateOption(4);
                        onSelect(
                          moment.range(
                            today.clone().subtract(3, "months"),
                            today.clone()
                          )
                        );
                      }}
                    >
                      Last 3 months
                    </CustomDiv>
                    <CustomDiv
                      // isActive={dateOption == 5}
                      onClick={() => {
                        setDateOption(5);
                      }}
                    >
                      Custom
                    </CustomDiv>
                  </CustomContainer>
                  {dateOption == 5 && (
                    <DateRangePicker
                      value={dateStartEnd}
                      onSelect={onSelect}
                      singleDateRange={true}
                    />
                  )}
                </CalendarDropDown>
              )}
            </CalenderWrap>
          </StatusContainer>
        </AdHeadWrap>
        <ManageWrap>
          <PlatformAccountWrap>
            <PlatformAccount
              ref={customerRef}
              isConnect={true}
              isDisable={false}
            >
              <ContainerToggle
                isToggle={show}
                onClick={() => {
                  setShow(!show);
                }}
              >
                <img src={GoogleAdsImg} height={"20px"} />
                <p>{selectedId?.customerId}</p>
                <img src={checkImg} height={"24px"} />
                <img src={downArrowImg} className="dropArrow" width={"24px"} />
              </ContainerToggle>
              {show && (
                <DropDownWrap>
                  <p>
                    Select Google Ads account to View
                    <span>{`(${connectByProjectId.length})`}</span>
                  </p>
                  <InputDiv>
                    <img src={searchIcon} />
                    <input placeholder="Search Google ads account here" />
                  </InputDiv>
                  <ResultList>
                    {connectByProjectId.map((item, index) => {
                      return (
                        <VerticalLine
                          isActive={selectedId.customerId == item.customerId}
                          onClick={() => {
                            newAdManagementDispatch({
                              type: "SET_DATE_START_END",
                              value: initialDate,
                            });
                            newAdManagementDispatch({
                              type: "SET_SELECTED_CUSTOMER",
                              value: item,
                            });
                            setProjectIdByConnect({
                              projectId: authState.defaultProjectInfo.projectId,
                            });
                            setShow(false);
                          }}
                        >
                          <p>
                            {item.name ? item.name : "Unknown"}
                            {selectedId.customerId == item.customerId && (
                              <img src={selectTick} />
                            )}
                          </p>
                        </VerticalLine>
                      );
                    })}
                  </ResultList>
                </DropDownWrap>
              )}
            </PlatformAccount>
            <PlatformAccount isConnect={false} isDisable={true}>
              <img src={facebookAdsImg} height={"20px"} />
              <p>Facebook ad account</p>
              {/* <img src={markImg} height={"24px"} /> */}
            </PlatformAccount>
            <PlatformAccount isConnect={false} isDisable={true}>
              <img src={linkedinAdsImg} height={"20px"} />
              <p>LinkedIn ad account</p>
              {/* <img src={markImg} height={"24px"} /> */}
            </PlatformAccount>
            <PlatformAccount isConnect={false} isDisable={true}>
              <img src={amazonAdsImg} height={"20px"} />
              <p>Amazon ad account</p>
              {/* <img src={markImg} height={"24px"} /> */}
            </PlatformAccount>
          </PlatformAccountWrap>
          <ManageButton
            onClick={() =>
              newAdManagementDispatch({
                type: "SET_MANAGE_ACCOUNT",
                value: true,
              })
            }
          >
            <img src={adsIcon} />
            Manage
          </ManageButton>
        </ManageWrap>
        <AccountDataDetails />
        <CampaignComponent value={65} maxValue={100} />
        {/* <DetailsComponent /> */}
        {manageAccount && <ManagePopup />}
        {selectAdAccount && <SelectAccount />}
      </AdResultWrap>
    </ResultWrapper>
  );
};

export default ResultPages;
