import React, { useEffect, useState } from "react";
import {
  AccountDataContainer,
  AccountDataDiv,
  AccountDataWrap,
  IncreaseWrap,
  SpendValue,
} from "./index.styled";
// import adResultData from "../../../../static";
// import increaseArrow from "../../../../../../../../media/Dashboard/arrow-up-green.svg";
import AccountDetailsGraph from "./AccountDetailsGraph";
import { useAdIntegration } from "../../../../context/AdManagementContext";
import { countryNewData } from "../../../../../../../../utils/countryData";
// import { useAuth } from "../../../../../../../../context/auth";
// import { useAdIntegrationInfo } from "../../../../hooks/useAdManagementInfo";
const AccountDataDetails = () => {
  // const { metricDetails } = adResultData;
  const {
    newAdManagementState: {
      isCampaignOverview,
      selectedId,
      isStatusDetail,
    },
    newAdManagementDispatch,
  } = useAdIntegration();

  // const { setProjectIdByConnect } = useAdIntegrationInfo();
  // const { authState, authDispatch } = useAuth();

  // useEffect(() => {
  //   setProjectIdByConnect({
  //     projectId: authState.defaultProjectInfo.projectId,
  //   });
  // }, [dateStartEnd]);
  const getCurrencySymbol = (codeForCurrency) => {
    return countryNewData.find((c) => c.currencyCode === codeForCurrency)?.currencySymbol;
  };
  

  return (
    <AccountDataWrap>
      <AccountDataContainer>
        {/* {metricDetails.map((item) => ( */}
        <AccountDataDiv>
          <p>Return on Ad Spend (ROAS)</p>
          <SpendValue>
            {isCampaignOverview &&
              isCampaignOverview?.overviewDetails[
                isStatusDetail == "enabled" ? "active" : isStatusDetail
              ]?.roas}
            x
          </SpendValue>
          {/* <IncreaseWrap>
              <img src={increaseArrow} />
              <span>14%</span>
              vs Last month
            </IncreaseWrap> */}
        </AccountDataDiv>
        <AccountDataDiv>
          <p>Cost-per-click (CPC)</p>
          <SpendValue>
            {getCurrencySymbol(selectedId?.currency)}
            {isCampaignOverview &&
              isCampaignOverview?.overviewDetails[
                isStatusDetail == "enabled" ? "active" : isStatusDetail
              ]?.cpc}
          </SpendValue>
          {/* <IncreaseWrap>
              <img src={increaseArrow} />
              <span>14%</span>
              vs Last month
            </IncreaseWrap> */}
        </AccountDataDiv>
        <AccountDataDiv>
          <p>Conversion</p>
          <SpendValue>
            {isCampaignOverview &&
              isCampaignOverview?.overviewDetails[
                isStatusDetail == "enabled" ? "active" : isStatusDetail
              ]?.conversionRate}
            %
          </SpendValue>
          {/* <IncreaseWrap>
              <img src={increaseArrow} />
              <span>14%</span>
              vs Last month
            </IncreaseWrap> */}
        </AccountDataDiv>
        {/* ))} */}
      </AccountDataContainer>
      <AccountDetailsGraph />
    </AccountDataWrap>
  );
};

export default AccountDataDetails;
