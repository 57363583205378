import styled from "styled-components";

export const Container = styled.div`
display: flex;
flex-direction: column;
gap: 36px;
`;

export const Property = styled.div`
`;

export const Label = styled.div`
color: #1C1C1C;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 16px;
display: flex;
gap: 16px;
align-items: center;
`;

export const BigContainer = styled.div`
width: 100%;//1000px
height: 14.081632653%;//138px
border-radius: 8px;
background: rgba(255, 255, 255, 0.60);
padding: 22px 34px 22px 24px;
position: relative;
display: flex;
gap: 16px;
flex-direction: column;
`;

export const SmallContainer = styled.div`
width: 100%;
height: 44px;
border-radius: 4px;
border: 1px solid #E3E3E3;
background: rgba(255, 255, 255, 0.60);
padding: 12px 10px 12px 16px;
display: flex;
justify-content: space-between;
color: #1C1C1C;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
position: relative;
cursor: pointer;
`;

export const Age = styled.div`
display: flex;
gap: 10px;
align-items: center;
color: #000;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-bottom: 16px;
`;

export const Input = styled.input`
display: flex;
width: 92px;
padding: 10px 24px;
justify-content: center;
align-items: center;
text-align: center;
gap: 10px;
border: 1px solid #E8E8E8;
background: rgba(255, 255, 255, 0.60);
:focus {
    outline: none;
    border: 1px solid #E8E8E8;
}
`;

export const Gender = styled.div`
display: flex;
gap: 12px;
`;

export const Choice = styled.div`
width: 80px;
height: 39px;
display: flex;
align-items: center;
justify-content: center;
color: ${(props) => props.isSelected ? "#FFF" : "#1C1C1C"};
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
border:  1px solid #E8E8E8;
background: ${(props) => props.isSelected ? "#1C1C1C" : "rgba(255, 255, 255, 0.60)"};
cursor: pointer;
`;

export const Icon = styled.img`
position: absolute;
top: 50%;
transform: translateY(-50%);
right: 34px;
`;

export const FlexContainer = styled.div`
display: grid;
grid-template-columns: repeat(2, 38.6%);
gap: 36px;
`;

export const DropdownContainer = styled.div`
display: flex;
width: 24px;
height: 24px;
padding: 8.207px 5.293px 8.5px 5.293px;
justify-content: center;
align-items: center;
flex-shrink: 0;
`;

export const Image = styled.img``;

export const LocationContainer = styled.div`
width: 100%;
height: 414px;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid #E8E8E8;
background: rgba(255, 255, 255, 0.60);
display: flex;
`;

export const CountryDropdownContainer = styled.div`
width: 41%;
height: 100%;
border-right: 1px solid #ECECEC;
`;

export const SearchBarContainer = styled.div`
width: 94.376528117%;
height: 44px;
padding: 14px 16px;
display: flex;
align-items: center;
gap: 16px;
margin: 16px 12px;
border-radius: 4px;
border: 1px solid #E8E8E8;
position: relative;
`;

export const SearchBar = styled.input`
border: none;
outline: none;
color: #1C1C1C;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
background: transparent;

:focus{
border: none;
outline: none;
}
:placeholder{
color: #9C9C9C;
}
`;

export const Clear = styled.img`
position: absolute;
top: 50%;
transform: translateY(-50%);
right: 10px;
cursor: pointer;
`;

export const SelectedCountries = styled.div`
height: 79%;
width: 100%;
border-top: 1px solid #ECECEC;
overflow-y: auto;
`;

export const Country = styled.div`
height: 48px;
border-bottom: 1px solid #ECECEC;
padding: 12px 12px 12px 19px;
display: flex;
gap: 16px;
align-items: center;
position: relative;
color: #1C1C1C;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 125% */
`;

export const FlagContainer = styled.div`
width: 24px;
height: 24px;
flex-shrink: 0;
`;

export const Remove = styled.img`
position: absolute;
transform: translateY(-50%);
top: 50%;
right: 12px;
cursor: pointer;
`;

export const MapContainer = styled.div`
width: 59%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;
`;

export const NextContainer = styled.div`
    width: 100%;
    
`;

export const Next = styled.div`
    display: inline-flex;
    padding: 16px 28px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: #FF711E;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    cursor: pointer;
    float: right;
    opacity: ${props => props.isDisabled ? "0.5": "1"};
    pointer-events: ${props => props.isDisabled ? "none": "all"};
`;
