export default {
  baseUrl: process.env.REACT_APP_BASEURL,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  adspyPage: "/adspy/google",
  paymentSuccess: "payment-success",
  paymentCancel: "payment-cancel",
  optExpiresAt: 900,
  lighthouseBaseUrl: process.env.REACT_APP_LIGHTHOUSE_BASEURL,
  lighthouseKey: process.env.REACT_APP_LIGHTHOUSE_KEY,
  backendUrl: process.env.REACT_APP_BACKEND_URL,
  frontendUrl: process.env.REACT_APP_FRONTEND_URL,
  
  authRouteRoot: "/auth",
  paymentRouteRoot: "/payment",
  adGenerationRouteRoot: "/adgeneration",
  trackRouteRoot: "/track",
  notificationRouteRoot: "/notification",
  feedbackRouteRoot: "/feedback",
  accountRouteRoot: "/account",
  searchRouteRoot: "/search",
  detailRouteRoot: "/detail",
  keywordRouteRoot: "/keyword",
  aggs: "/aggs",
  intelligence: "/intelligence",
  landing: "/landing",
  adgeneration: "/adgeneration",

  projectCreationCost: 5,
  keywordTrackingCost: 5,
  competitorTrackingCost: 5,
  searchCost: 1,
  textAdGenerationCost: 5,
  imageAdGenerationCost: 5,

  freePlan: "free"
};