import React, {useEffect, useRef} from 'react'
import { useLocation, useNavigate, useSearchParams} from 'react-router-dom'

// Styles
import {
    Container,
    Header,
    Breadcrumb,
    ScrollableContainer,
    HeaderLeft,
    HeaderRight,
    TotalAdsFound,
    Footer,
    TotalResults,
    AppliedFilters,
    AppliedFilterItem,
    Close,
    RefereshButton, 
    RefreshButtonContainer,
    Divider
} from "./index.styled"
import _static from "../../../../../../config/static"

import Pagination from './Pagination'
import {useSearch} from "../../context/search"


import {useFacebook} from "../context/facebook"

// Icons
import Results from './Results'
import {appClient} from '../../../../../../utils/axios-utils'
import { useAuth } from '../../../../../../context/auth'
import { getSearchParams, urlPatternValidation, dateFormatter,formatWithCommas } from "../../../../../../utils/helper.js"
import CrawlRequestResults from './CrawlRequestResults/index.js'
import { useFacebookInfo } from '../hooks/useFacebookInfo.js'
import SortType from './NewFilters/SortType'
import MajorFilters from './NewFilters/MajorFilters'
import TimerIcon from "../../../../../../media/Dashboard/Timer.svg"

import { useRequestData } from '../../../../../hooks/useRequestData.js'
import SortFocus from '../../../utils/SortFocus/index.js'
import back from "../../assets/back.svg"
const { baseUrl } = _static

const keywordBoardFilterMatch = async (data) => {
    const config = {
        url: "/search/facebook/keyword/broad/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseFilterMatch = async (data) => {
    const config = {
        url: "/search/facebook/keyword/phrase/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlDomainFilterMatch = async (data) => {
    const config = {
        url: "/search/facebook/url/domain/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlTextFilterMatch = async (data) => {
    const config = {
        url: "/search/facebook/url/text/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlExactFilterMatch = async (data) => {
    const config = {
        url: "/search/facebook/url/exact/filter",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordBroadMatch = async (data) => {
    const config = {
        url: "/search/facebook/keyword/broad",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseMatch = async (data) => {
    const config = {
        url: "/search/facebook/keyword/phrase",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlDomainMatch = async (data) => {
    const config = {
        url: "/search/facebook/url/domain",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlExactMatch = async (data) => {
    const config = {
        url: "/search/facebook/url/exact",
        method: "POST",
        data
    }

    return appClient(config)
}

const urlTextMatch = async (data) => {
    const config = {
        url: "/search/facebook/url/text",
        method: "POST",
        data
    }

    return appClient(config)
}

const getCrawledData = async (data) => {
    const config = {
        url: "/search/facebook/url/domain/realtime",
        method: "POST",
        data
    }

    return appClient(config)
}

const FacebookAds = () => {
    const {facebookState, facebookDispatch} = useFacebook()
    const [searchParams, setSearchParams] = useSearchParams()

    const {searchState, searchDispatch} = useSearch()
    const {authState, authDispatch} = useAuth()
    const useRequestDataHook = useRequestData()
    const navigate = useNavigate()
    const location = useLocation()
    const useFacebookInfoHook = useFacebookInfo()

    const myRef = useRef(null);


    useEffect(() => {
        if(location.pathname.includes("meta")){
            authDispatch({type: 'SET_SEARCH_PLATFORM', value: 'meta'})
        }
    }, [])

    const onDataRequest = () => {
        if (!authState.isDataRequested) {
            // useRequestDataHook.requestDataMutate({ id: googleSearchState.queryId })
            useRequestDataHook.requestRealtimeDataForFaceBookMutate({ id: facebookState.queryId })
        }
    }

    useEffect(() => {
        // console.log('search param updated')
        const searchParamFromState = getSearchParams(location.search)

        const {
            searchMode,
            keywords,
            phraseInput,
            domainUrlInput,
            keywordSearchType,
            urlSearchType,
            searchIn,
            minimumShouldMatch,
            excludedTerm,
            page,
            selectedCountries,
            startDate,
            endDate,
            sortType,
            crawlRequestData,
            keywordsArray,
            competitorsArray,
            sortArray
        } = searchParamFromState

        facebookDispatch({type:'UPDATE_DETAILS_MODAL', value: false})

        console.log("crawlRequestData", crawlRequestData, searchParamFromState)
        if(searchMode){
            {
                if(searchMode == "keyword"){
                    authDispatch({type: 'SET_SEARCH_MODE', value: searchMode})
                    if(keywordSearchType){
                        authDispatch({type: 'SET_KEYWORD_SEARCHTYPE', value: keywordSearchType })
                        if(keywordSearchType == "phrase"){
                            if(phraseInput){
                                authDispatch({type: 'SET_PHRASE_INPUT', value: phraseInput})
                            }
                            if(searchIn && searchIn.length > 0){
                                let finalSearchIn = ["title","content"].map(si=>{
                                    if(searchIn.includes(si)){
                                        facebookDispatch({ type: "SET_PHRASE_SEARCH_IN", payload: {
                                            name: si.charAt(0).toUpperCase() + si.slice(1),
                                            value: si,
                                            checked: true
                                        }})
                                    }else{
                                        facebookDispatch({ type: "SET_PHRASE_SEARCH_IN", payload: {
                                            name: si.charAt(0).toUpperCase() + si.slice(1),
                                            value: si,
                                            checked: true
                                        }})
                                    }
                                })
                            }
                        }
                        if(keywordSearchType == "broad"){
                            if(keywords && keywords.length > 0){
                                authDispatch({type: 'SET_KEYWORDS', value: keywords.split(",")})
                            }
    
                            facebookDispatch({ type: "SET_BROAD_MIN_SHOULD_MATCH", value: { name: 'Min. match', value: minimumShouldMatch ? minimumShouldMatch : 0 }})
                            
                            if(searchIn && searchIn.length > 0){
                                let finalSearchIn = ["title","content"].map(si=>{
                                    if(searchIn.includes(si)){
                                        facebookDispatch({ type: "SET_BROAD_SEARCH_IN", payload: {
                                            name: si.charAt(0).toUpperCase() + si.slice(1),
                                            value: si,
                                            checked: true
                                        }})
                                    }else{
                                        facebookDispatch({ type: "SET_BROAD_SEARCH_IN", payload: {
                                            name: si.charAt(0).toUpperCase() + si.slice(1),
                                            value: si,
                                            checked: false
                                        }})
                                    }
                                })
                            }
                        }
                        if(excludedTerm){
                            facebookDispatch({ type: 'UPDATE_EXCLUDED_TERM', value: excludedTerm})
                        }
                    }
                }
    
                if (searchMode == "url"){
                    authDispatch({type: 'SET_SEARCH_MODE', value: searchMode})
                    if(urlSearchType){
                        authDispatch({type: 'SET_URL_SEARCHTYPE', value: urlSearchType })
                        if(urlSearchType == "exact"){
                            if(domainUrlInput){
                                authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: urlPatternValidation(domainUrlInput)}})
                            }
                        }
                        if(urlSearchType == "domain"){
                            if(domainUrlInput){
                                authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: urlPatternValidation(domainUrlInput)}})
                            }
                        }
                        if(urlSearchType == "text"){
                            if(domainUrlInput){
                                authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: domainUrlInput.length > 0}})
                            }
                        }
                    }
                }
            }

            if(selectedCountries && selectedCountries.length > 0){
                facebookDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: selectedCountries.split(",")})
            }else{
                facebookDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: []})
            }

            if(startDate){
                facebookDispatch({type: 'UPDATE_START_DATE',value: new Date(Date.parse(startDate))})
            }else{
                facebookDispatch({type: 'UPDATE_END_DATE',value: ''})
            }

            if(endDate){
                facebookDispatch({type: 'UPDATE_END_DATE',value: new Date(Date.parse(endDate))})
            }else{
                facebookDispatch({type: 'UPDATE_END_DATE',value: ''})
            }

            if(sortType){
                console.log("sortType", sortType, {
                    name: sortType.charAt(0).toUpperCase() + sortType.slice(1),
                    type: sortType
                })
                facebookDispatch({type: 'UPDATE_SELECTED_SORT_TYPE',value: {
                    name: sortType.charAt(0).toUpperCase() + sortType.slice(1),
                    type: sortType
                }})
            }

            if(page){
                facebookDispatch({type: 'SET_CURRENT_PAGE_NO', value: page})
            }
        }else{
            facebookDispatch({type: 'CLEAR_ALL'})
        }


        if(crawlRequestData){
            facebookDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

            useFacebookInfoHook.getCrawledDataMutate({
                query: authState.searchMode == "url" ? searchState.domainUrlInput.value : keywordSearchType == "phrase" ? phraseInput : keywords.join(" "),
                page
            })

        }else if((selectedCountries && selectedCountries.length > 0) || (startDate) || (endDate) || sortType != "relevant"){
            // console.log("inside filter")
            if (searchMode == "keyword") {
                if((keywordSearchType === "broad" && (keywords && keywords.length > 0)) || (keywordSearchType === "phrase" && (phraseInput && phraseInput.length > 0))){
                    if (keywordSearchType == "broad") {
                        let fields = searchIn.split(",")
                        let query = keywords.split(",").join(" ")
                        

                        const reqBody = {
                            query,
                            minimumShouldMatch: minimumShouldMatch,
                            searchIn: fields,
                            exclude: excludedTerm,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }
                        facebookDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useFacebookInfoHook.keywordBoardFilterMutate(reqBody)
                    } else if (keywordSearchType == "phrase") {
                        // console.log("innnnnnnnnn")
                        let fields = searchIn.split(",")
                        

                        const reqBody = {
                            query: phraseInput,
                            searchIn: fields,
                            exclude: excludedTerm,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }
                        facebookDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useFacebookInfoHook.keywordPhraseFilterMutate(reqBody)
                    }
                }
            } else if (searchMode == "url") {
                if((urlSearchType == "domain" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) || urlSearchType == "text" && (domainUrlInput && domainUrlInput.length > 0) || urlSearchType == "exact" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)){
                    if (urlSearchType == "domain") {
                        
                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }
                        facebookDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useFacebookInfoHook.urlDomainFilterMutate(reqBody)
                    } else if (urlSearchType == "text") {

                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }
                        facebookDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useFacebookInfoHook.urlTextFilterMutate(reqBody)
                    } else if (urlSearchType == "exact") {
                        
                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }
                        facebookDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useFacebookInfoHook.urlExactFilterMatchMutate(reqBody)
                    }
                }
            }
        }else{
            // console.log("without filter")
            // on not filter applied
            if (searchMode == "keyword") {
                if((keywordSearchType === "broad" && (keywords && keywords.length > 0)) || (keywordSearchType === "phrase" && (phraseInput && phraseInput.length > 0))){
                    if (keywordSearchType == "broad") {
                        let fields = searchIn.split(",")
                        let query = keywords.split(",").join(" ")
                
                        const reqBody = {
                            query,
                            minimumShouldMatch: minimumShouldMatch,
                            searchIn: fields,
                            exclude: excludedTerm,
                            page: page,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }
                        facebookDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useFacebookInfoHook.keywordBroadMutate(reqBody)
                    } else if (keywordSearchType == "phrase") {
                        let fields = searchIn.split(",")
                        
                        const reqBody = {
                            query: phraseInput,
                            searchIn: fields,
                            exclude: excludedTerm,
                            page: page,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }
                        facebookDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useFacebookInfoHook.keywordPhraseMutate(reqBody)
                    }
                }
            } else if (searchMode == "url") {
                if((urlSearchType == "domain" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) || urlSearchType == "text" && (domainUrlInput && domainUrlInput.length > 0) || urlSearchType == "exact" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)){
                    if (urlSearchType == "domain") {
                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }
                        facebookDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useFacebookInfoHook.urlDomainMutate(reqBody)
                    } else if (urlSearchType == "text") {
                
                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }
                        facebookDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useFacebookInfoHook.urlTextMutate(reqBody)
                    } else if (urlSearchType == "exact") {
                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }
                        facebookDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useFacebookInfoHook.urlExactMutate(reqBody)
                    }
                }
            }
        }



    }, [location.search])

    let feedbackPopupOpen = localStorage.getItem("feedbackpopup");

    const ClearCountry = () => {
        searchParams.set("selectedCountries", "")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearSortType = () => {
        searchParams.set("sortType", "relevant")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearDateFilter = () => {
        searchParams.set("startDate", "")
        searchParams.set("endDate", "")
        facebookDispatch({type: "UPDATE_DATE_FILTER_TYPE", value: ""})
        setSearchParams(searchParams)
    }

    const OpenAdvancedFilter = () => {
        searchParams.set("tone", "")
        searchParams.set("cta", "")
        searchParams.set("sentiment", "")
        searchParams.set("otherFilters", "")
        setSearchParams(searchParams)
    }

    // return <Container ref={myRef}>
    //     {feedbackPopupOpen && authState.feedbackPopup  ? (authState.features && authState.features.searchesMade && authState.features.searchesMade == 5) ? <FeedbackPopup type={"after-search"}/> : <FeedbackPopup type={"after-onboarding"}/> : ""}
        
    //     {
    //         searchState.isCrawlRequestPage ? <CrawlRequestResults/> : 
    //         <>
    //             <SearchContainer>
    //             <ResultPageSearch/>
    //             </SearchContainer>
    //             <ScrollableContainer>
    //                 {
    //                 !searchState.limitExceeded && < Filters />
    //                 }
    //                 {
    //                 facebookState.ads && facebookState.ads.length > 0 && (
    //                     <AdsCount>
    //                         <div><p>
    //                             <span>facebook Search ads{" "}</span>
    //                             - {(facebookState.currentPageNo - 1) * 20 != 0 ? (facebookState.currentPageNo - 1) * 20 + 1 : 1} - {(((facebookState.currentPageNo - 1) * 20) + 20) > facebookState.totalAds ? facebookState.totalAds : ((facebookState.currentPageNo - 1) * 20) + 20} of <span>{
    //                                 facebookState.totalAds
    //                             }</span>
    //                                 {" "}results
    //                         </p></div>
    //                     </AdsCount>
    //                 )
    //                 }
    //                 <Results/> 
    //             </ScrollableContainer>
    //             {
    //                 !searchState.limitExceeded && facebookState.ads && facebookState.ads.length > 0 && <Pagination/>
    //             } 
    //         </>
    //     }
    // </Container>



    return <Container ref={myRef}>
    {
        authState.isCrawlRequestPage
            ?
            <CrawlRequestResults />
            :
            <>
                <Breadcrumb onClick={() => navigate('/overview')} >
                        <img src={back} />
                    Back to overview
                </Breadcrumb>
                <Header>
                    <HeaderLeft>
                        <h2>{`Meta Search ads (${formatWithCommas(facebookState.totalAds)})`}</h2>
                        <AppliedFilters>
                            {facebookState.selectedCountries.length > 0 && <AppliedFilterItem>
                                <p>{facebookState.selectedCountries.length == 1 ? facebookState.selectedCountries[0] : facebookState.selectedCountries.length > 1 ? facebookState.selectedCountries[0] + " + " + (
                                    facebookState.selectedCountries.length - 1
                                ) : "no country"}</p>
                                <Close onClick={()=>ClearCountry()}>
                                    x 
                                </Close>
                            </AppliedFilterItem>}
                            {facebookState.selectedSortType.type !== "relevant" && <AppliedFilterItem>
                                <p>{facebookState.selectedSortType.name}</p>
                                <Close onClick={()=>ClearSortType()}>
                                    x 
                                </Close>
                            </AppliedFilterItem>}
                            {facebookState.dateFilterType.length > 0 && <AppliedFilterItem>
                                <p>{facebookState.dateFilterType}</p>
                                <Close onClick={()=>ClearDateFilter()}>
                                    x 
                                </Close>
                            </AppliedFilterItem>}
                            {(facebookState.selectedCta?.length > 0 || facebookState.selectedTone?.length > 0 || facebookState.sentimentFilter?.length > 0 || facebookState.selectedOtherFilters?.length > 0) && <AppliedFilterItem>
                                <p>Advanced filters</p>
                                <Close onClick={()=>OpenAdvancedFilter()}>
                                    x 
                                </Close>
                            </AppliedFilterItem>}
                        </AppliedFilters>
                    </HeaderLeft>
                    <HeaderRight>
                        {/* <TotalAdsFound>
                            <label>Total ads found :</label>
                            <span>
                                {formatWithCommas(facebookState.totalAds)}
                            </span>
                        </TotalAdsFound> */}
                        <SortFocus />
                        <SortType />
                        <MajorFilters />
                    </HeaderRight>
                </Header>
                <Divider>
                    Display ads, content, etc., sorted based on your selected focus: Keywords or Competitors.
                </Divider>
                {facebookState.queryId && !facebookState.noResults && <RefreshButtonContainer>
                        <RefereshButton 
                            isDisabled={useRequestDataHook.isRequestRealtimeDataForFacebookLoading || authState.isDataRequested}
                            onClick={onDataRequest}
                        >
                            <img src={TimerIcon}/><p>{authState.isDataRequested ? "Request sent" : useRequestDataHook.isRequestRealtimeDataForFacebookLoading ? "Requesting..." : "Refresh data"}</p>
                        </RefereshButton>
                    </RefreshButtonContainer>}
                <ScrollableContainer>
                    <Results />
                    <Footer>
                        {(!authDispatch.limitExceeded && facebookState.ads && !facebookState.loadingResults && facebookState.ads.length > 0) && <TotalResults>
                            <p>Showing {(facebookState.currentPageNo - 1) * 20 != 0 ? (facebookState.currentPageNo - 1) * 20 + 1 : 1} - {(((facebookState.currentPageNo - 1) * 20) + 20) > facebookState.totalAds ? facebookState.totalAds : ((facebookState.currentPageNo - 1) * 20) + 20} of <span>{
                                facebookState.totalAds
                            }</span>
                            results</p>
                        </TotalResults>}
                        {!authDispatch.limitExceeded && facebookState.ads && !facebookState.loadingResults && facebookState.ads.length > 0 && <Pagination />}
                    </Footer>
                </ScrollableContainer>
            </>
        }
        </Container>
}

export default FacebookAds
