import { useAuth } from "../../context/auth";
import { appClient } from "../../utils/axios-utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import produce from "immer";
import { useSaveAds } from "./useSaveAds";
import _static from "../../config/static";
const userPlan = () => {
  const config = {
    url: "/payment/user-info",
    method: "GET",
  };

  return appClient(config);
};

const userFav = () => {
  const config = {
    url: "/adgeneration/saved-ads",
    method: "GET",
  };

  return appClient(config);
};

const currentUser = () => {
  const config = {
    url: "/auth/current-user",
    method: "GET",
  };

  return appClient(config);
};

const getRemainingCredits = async () => {
  const config = {
    url: `/account/credits`,
    method: "GET",
  };

  return appClient(config);
};

export const useGetUserInfo = () => {
  const { authState, authDispatch } = useAuth();
  const useSaveAdsHook = useSaveAds()
  const navigate = useNavigate();

  const { mutate: getUserPlanData } = useMutation(userPlan, {
    onSuccess: (data) => {
      authDispatch({ type: "UPDATE_SEARCHES_MADE", value: data.searchesMade });
      if (data.searchesMade == 5) {
        authDispatch({ type: "UPDATE_FEEDBACK_POPUP", value: true });
      }
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const { mutate: getUserFavourite } = useMutation(userFav, {
    onSuccess: (data) => {
      authDispatch({ type: "SET_FAV_ADS_LIST", value: data });
    },
    onError: (err) => {
      console.log(err);
    },
    onMutate: () => {},
  });

  const { mutate: getCurrentUser, isLoading: isGetCurrentUserLoading } =
    useMutation(currentUser, {
      onSuccess: (data, variables) => {
        let renamedRoutes = {
          facebook: "meta",
          "bing-ecom": "bingpla",
          "google-ecom": "googlepla",
        };

        if (data.features) {
          data = produce(data, (draft) => {
            let alteredArray = data?.features?.allowedPlatforms.map(
              (platform) => {
                if (platform in renamedRoutes) {
                  return renamedRoutes[platform];
                }

                return platform;
              }
            );

            draft.features.allowedPlatforms = alteredArray;
          });
        }
        if (data.plan) {
          const currenPeriodEnd = new Date(data?.plan?.currenPeriodEnd);
          const trialPeriodEnd = new Date(data?.plan?.trialEnd);
          // data.plan.planName = data.plan.planName.toLowerCase()
          const currentDate = new Date();
          if (currenPeriodEnd < currentDate || trialPeriodEnd < currentDate) {
            authDispatch({ type: "UPDATE_PLAN_EXPIRED_POPUP", value: true });
          }
        }

        authDispatch({
          type: "CURRENT_USER_UPDATE",
          data: {...data,
          plan:{
            ...data.plan,
            planName: data.plan.planName?.toLowerCase()
          }},
          isAuthenticated: true,
        });
        
        if(data.defaultProjectInfo.projectId)
        useSaveAdsHook.GetSavedAdsMutate({projectId: data.defaultProjectInfo.projectId})

        authDispatch({ type: "STOP_LOADER" });
        authDispatch({
          type: "UPDATE_USER_LOCATION",
          value: {
            ...authState.userLocation,
            countryCode: data.countryCode}
        })
        if (variables?.redirect) {
          navigate("/overview");
        }
      },
      onError: (e) => {
        authDispatch({ type: "LOGOUT_SUCCESS" });
        authDispatch({ type: "STOP_LOADER" });
      },
    });

    const { mutate: getRemainingCreditsMutate, isLoading: isRemainingCreditsLoading } = 
    useMutation(getRemainingCredits, {
      onSuccess: (data) => {
        authDispatch({type: "SET_USER_REMAINING_CREDITS", value: data})
        if(data && ((data.status != "active") || (data.creditLimit <= data.creditsSpent))){
          authDispatch({type: 'SET_SHOW_CREDIT_EXPIRED_MODAL', value: true})
        }
      },
      onError: (e) => {},
    });

  const canAccessFeature = ({type, requestLength = 1}) => {
    const { projectCreationCost, keywordTrackingCost, competitorTrackingCost, searchCost, textAdGenerationCost, imageAdGenerationCost, freePlan } = _static
    const { plan: {creditsSpent, creditLimit, planName} } = authState
    const remainingCredits = creditLimit - creditsSpent
    switch(type){
      case "add-project":
      if(remainingCredits < projectCreationCost)
        return false
      return true

      case "keyword-tracking":
      if(remainingCredits < (keywordTrackingCost * requestLength))
        return false
      return true

      case "competitor-tracking":
      if(remainingCredits < (competitorTrackingCost * requestLength))
        return false
      return true

      case "adspy":
      if(remainingCredits < searchCost)
        return false
      return true

      case "text-ad-generation":
      if(remainingCredits < textAdGenerationCost)
        return false
      return true

      case "image-ad-generation":
      if(remainingCredits < imageAdGenerationCost)
        return false
      return true
    }
  }

  return {
    getUserPlanData,
    getUserFavourite,
    getCurrentUser,
    isGetCurrentUserLoading,
    getRemainingCreditsMutate,
    isRemainingCreditsLoading,
    
    canAccessFeature
  };
};
