import React, {useEffect, useState} from "react";
import { Container, Header, HeaderLeft, HeaderRight, PublishButton, ContentContainer, CampaignNameContainer, ContentWrapper, LeftContentContainer, RightContentContainer, InputTab, ErrorMsg } from "./index.styled"
import LeftArrow from "../../../../../../../media/Dashboard/backarrow.svg";
import { useAdIntegration } from "../../../context/AdManagementContext";
import AdGroups from "./components/AdGroups";
import Keywords from "./components/Keywords";
import Targeting from "./components/Targeting";
import Budget from "./components/Budget";
import { useAdIntegrationInfo } from "../../../hooks/useAdManagementInfo";
import { getSearchParams } from "../../../../../../../utils/helper";
import { useLocation } from "react-router-dom"
import CampaignPublished from "./components/CampaignPublish";

const AdDetailsInput = () => {
    const {
        newAdManagementState,
        newAdManagementDispatch,
      } = useAdIntegration();

  const adManagementHook = useAdIntegrationInfo();


    const location = useLocation()
    const [randomNumber, setRandomNumber] = useState(-54);
    const [randomCampaign, setRandomCampaign] = useState(-27);

    const generateRandomNumber = () => {
      const min = -100; // Minimum negative number
      const max = -1;   // Maximum negative number
      const newRandomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      setRandomNumber(newRandomNumber);
    }
    const generateRandomCampaignNo = () => {
        const min = -100; // Minimum negative number
        const max = -1;   // Maximum negative number
        const newRandomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
        setRandomCampaign(newRandomNumber);
      }
    
    const renderAdDetailsTab = () => {
        switch(newAdManagementState.activeAdInputTab) {

            case 1:
            return <AdGroups/>

            case 2: 
            return <Keywords/>

            case 3:
            return <Targeting/>

            case 4:
            return <Budget/>

            default:
            return

        }
    }
    const renderContent = () => {

        const AdInputTabs = [
            {
                id: 1,
                name: "Ad Groups"
            },
            {
                id: 2,
                name: "Keywords"
            },
            {
                id: 3,
                name: "Targeting"
            },
            {
                id: 4,
                name: "Budget"
            }
        ]

        return <ContentWrapper>
            <LeftContentContainer>
                {AdInputTabs.map((tab) => {
                    return <InputTab 
                        isActive={newAdManagementState.activeAdInputTab == tab.id}
                        onClick={()=>{
                            newAdManagementDispatch({type: "SET_ACTIVE_ADINPUT_TAB", value: tab.id})
                        }}>
                        {tab.name}
                    </InputTab>
                })}
            </LeftContentContainer>
            <RightContentContainer>
                {renderAdDetailsTab()}
            </RightContentContainer>
        </ContentWrapper>
    }
    const publishFunction = () =>{
      
        const headlinesObjects = newAdManagementState?.adGroupList[0]?.titleList.map((headline, index) => {
            return { text: headline.substring(0, 27) };
        });
        newAdManagementDispatch({ type: "SET_CREATE_AD_HEADLINE", value: headlinesObjects })
        const descriptionObjects = newAdManagementState?.adGroupList[0]?.descriptionList.map((descrip, index) => {
            return { text: descrip.substring(0, 85) };
        });
        newAdManagementDispatch({ type: "SET_CREATE_AD_DESCRIPTION", value: descriptionObjects })
        const transformedKeywordsList = newAdManagementState.keywordsList.map(keyword => ({
            matchType: keyword.type.toUpperCase(), // Convert type to uppercase
            text: keyword.keyword
        }));
        newAdManagementDispatch({ type: "SET_TRANSFORMED_KEYWORD", value: transformedKeywordsList })

        generateRandomNumber()
        generateRandomCampaignNo()
        adManagementHook.setCreateCampaign({
            "connectionId": newAdManagementState.connectedAdAccount[0].connectionId,
            "customerId": newAdManagementState.connectedAdAccount[0].customerId,
            "campaignBudgetData" : {
              "amountMicros": newAdManagementState.isBudgetData* 1000000,
              "explicitlyShared": false,
              "resourceName": `customers/${newAdManagementState.connectedAdAccount[0].customerId}/campaignBudgets/${randomNumber}`  
            },
            "campaignData": {
              "name": newAdManagementState.campaignName,
              "advertisingChannelType": "SEARCH",
              "resourceName": `customers/${newAdManagementState.connectedAdAccount[0].customerId}/campaigns/${randomCampaign}`,
              "status": "PAUSED",
              "campaignBudget": `customers/${newAdManagementState.connectedAdAccount[0].customerId}/campaignBudgets/${randomNumber}`
            },
            "biddingStrategy": newAdManagementState.selectedStrategy == 'maximizeClicks' ? 'targetSpend' : newAdManagementState.selectedStrategy,
            "adGroupData": {
              "name": `${newAdManagementState.campaignName}-AdGroup`,
              "status": "PAUSED",
              "type": "SEARCH_STANDARD",
              "campaign": `customers/${newAdManagementState.connectedAdAccount[0].customerId}/campaigns/${randomCampaign}`
            }
        })
    }
    function isActive() {
        const adGroup = newAdManagementState?.adGroupList[0];
        const isTitleValid = adGroup?.titleList?.length > 2 && adGroup?.titleList?.length <= 15;
        const isDescriptionValid = adGroup?.descriptionList?.length > 2 && adGroup?.descriptionList?.length <= 4;
        const areKeywordsValid = newAdManagementState?.keywordsList?.length > 2;
        const locationValid = newAdManagementState.locationsList?.length > 0;
        const budgetValid = newAdManagementState.isBudgetData;
        const bidStrategy = newAdManagementState.selectedStrategy;
        const destinationUrl = newAdManagementState.isDestinationError == null && adGroup?.destinationUrl;
        if (isTitleValid && isDescriptionValid && areKeywordsValid && locationValid && budgetValid && bidStrategy && destinationUrl) {
            return true;
        } else {
            return false;
        }
    }
    useEffect(() => {
        if((!newAdManagementState.campaignName && !newAdManagementState.customCampaignName) || !newAdManagementState.selectedAdAccount || !newAdManagementState.selectedAdType){
            newAdManagementDispatch({type: "SET_CURRENT_CAMPAIGN_STEP", value: 1})
        }
        newAdManagementDispatch({ type: "SET_SELECTED_STRATEGY", value: "maximizeClicks" })
        const searchParamFromState = getSearchParams(location.search)
        const {
            id
        } = searchParamFromState

        adManagementHook.setSingleAdGroup({
            "adGroupId": id
        }  )
        if(newAdManagementState.isDestinationUrl == (null || "")){
            newAdManagementDispatch({ type: "SET_DESTINATION_ERROR", value:'Destination url is required' })
        }
    }, [])
    useEffect(() => {
        newAdManagementState?.adGroupList.forEach((item) => {
            if (item.descriptionList.length > 4) {

                newAdManagementDispatch({ type: "SET_ERROR_MESSAGE", value: 'Only upto 4 descriptions allowed' });
            }else{
                newAdManagementDispatch({ type: "SET_ERROR_MESSAGE", value: null });
            }
          });

         newAdManagementState?.adGroupList.forEach((item) => {    
            if (item.descriptionList.length <= 4) {
                if (item.titleList.length > 15) {
                    newAdManagementDispatch({ type: "SET_ERROR_MESSAGE", value: 'Only up to 10 titles allowed' });
                } else {
                    newAdManagementDispatch({ type: "SET_ERROR_MESSAGE", value: null });
                }
              }
          });
        
    }, [newAdManagementState?.adGroupList])
    return <Container>
        {!newAdManagementState.isCampaignPublished ? <>
            <Header>
                <HeaderLeft>
                    <img onClick={()=>newAdManagementDispatch({type: "SET_CURRENT_CAMPAIGN_STEP", value: 1})} src={LeftArrow} />
                    <h2>New text ad campaign</h2>
                </HeaderLeft>
                <HeaderRight>
                    {newAdManagementState.isErrorMsg && <ErrorMsg>{newAdManagementState.isErrorMsg}</ErrorMsg>}
                    <PublishButton isActive={isActive()} onClick={publishFunction}>
                        Publish
                    </PublishButton>
                </HeaderRight>
            </Header>
            <ContentContainer>
                <CampaignNameContainer>
                    {newAdManagementState.campaignName || newAdManagementState.customCampaignName} 
                </CampaignNameContainer>
                {renderContent()}   
            </ContentContainer>
            </>:
            <CampaignPublished/>
            }
    </Container>
}

export default AdDetailsInput