import React, {useEffect, useRef} from 'react'
import { useLocation, useNavigate, useSearchParams} from 'react-router-dom'


// Styles
import {
    Container,
    Header,
    Breadcrumb,
    ScrollableContainer,
    HeaderLeft,
    HeaderRight,
    TotalAdsFound,
    Footer,
    TotalResults,
    AppliedFilters,
    AppliedFilterItem,
    Close,
    Divider
} from "./index.styled"
import _static from "../../../../../../config/static"

import Pagination from './Pagination'
import {useSearch} from "../../context/search"

import {useTikTok} from "../context/tiktok.js"

import { getSearchParams, urlPatternValidation, dateFormatter, formatWithCommas } from "../../../../../../utils/helper.js"

// Icons
import Results from './Results'
import { useAuth } from '../../../../../../context/auth.js'
import { useTikTokInfo } from '../hooks/useTikTokInfo.js'
import SortType from './NewFilters/SortType'
import MajorFilters from './NewFilters/MajorFilters'
import SortFocus from '../../../utils/SortFocus/index.js'
import back from "../../assets/back.svg"
const TikTokAds = () => {
    const { tikTokState, tikTokDispatch } = useTikTok()
    const [searchParams, setSearchParams] = useSearchParams()
    const {searchState, searchDispatch} = useSearch()
    const location = useLocation()
    const {authState, authDispatch} = useAuth()

    const useTikTokInfoHook = useTikTokInfo()

    const myRef = useRef(null);
    const navigate = useNavigate()
    function padDigits(number, digits) {
        return String(number).padStart(digits, '0');
    }

    useEffect(() => {
        if(location.pathname.includes("twitter")){
            authDispatch({type: 'SET_SEARCH_PLATFORM', value: 'twitter'})
        }
    }, [])

    useEffect(() => {
        // console.log('search param updated')
        const searchParamFromState = getSearchParams(location.search)

        const {
            searchMode,
            keywords,
            phraseInput,
            domainUrlInput,
            keywordSearchType,
            urlSearchType,
            searchIn,
            minimumShouldMatch,
            excludedTerm,
            page,
            selectedCountries,
            startDate,
            endDate,
            sortType,
            keywordsArray,
            competitorsArray,
            sortArray
        } = searchParamFromState

        tikTokDispatch({type:'UPDATE_DETAILS_MODAL', value: false})

        if(searchMode){
            if(searchMode == "keyword"){
                authDispatch({type: 'SET_SEARCH_MODE', value: searchMode})
                if(keywordSearchType){
                    authDispatch({type: 'SET_KEYWORD_SEARCHTYPE', value: keywordSearchType })
                    if(keywordSearchType == "phrase"){
                        if(phraseInput){
                            authDispatch({type: 'SET_PHRASE_INPUT', value: phraseInput})
                        }
                        if(searchIn && searchIn.length > 0){
                            let finalSearchIn = ["title","content"].map(si=>{
                                if(searchIn.includes(si)){
                                    tikTokDispatch({ type: "SET_PHRASE_SEARCH_IN", payload: {
                                        name: si.charAt(0).toUpperCase() + si.slice(1),
                                        value: si,
                                        checked: true
                                    }})
                                }else{
                                    tikTokDispatch({ type: "SET_PHRASE_SEARCH_IN", payload: {
                                        name: si.charAt(0).toUpperCase() + si.slice(1),
                                        value: si,
                                        checked: true
                                    }})
                                }
                            })
                        }
                    }
                    if(keywordSearchType == "broad"){
                        if(keywords && keywords.length > 0){
                            authDispatch({type: 'SET_KEYWORDS', value: keywords.split(",")})
                        }

                        tikTokDispatch({ type: "SET_BROAD_MIN_SHOULD_MATCH", value: { name: 'Min. match', value: minimumShouldMatch ? minimumShouldMatch : 0 }})
                        
                        if(searchIn && searchIn.length > 0){
                            let finalSearchIn = ["title","content"].map(si=>{
                                if(searchIn.includes(si)){
                                    tikTokDispatch({ type: "SET_BROAD_SEARCH_IN", payload: {
                                        name: si.charAt(0).toUpperCase() + si.slice(1),
                                        value: si,
                                        checked: true
                                    }})
                                }else{
                                    tikTokDispatch({ type: "SET_BROAD_SEARCH_IN", payload: {
                                        name: si.charAt(0).toUpperCase() + si.slice(1),
                                        value: si,
                                        checked: false
                                    }})
                                }
                            })
                        }
                    }
                    if(excludedTerm){
                        tikTokDispatch({ type: 'UPDATE_EXCLUDED_TERM', value: excludedTerm})
                    }
                }
            }

            if (searchMode == "url"){
                authDispatch({type: 'SET_SEARCH_MODE', value: searchMode})
                if(urlSearchType){
                    authDispatch({type: 'SET_URL_SEARCHTYPE', value: urlSearchType })
                    if(urlSearchType == "exact"){
                        if(domainUrlInput){
                            authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: urlPatternValidation(domainUrlInput)}})
                        }
                    }

                    if(urlSearchType == "domain"){
                        if(domainUrlInput){
                            authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: urlPatternValidation(domainUrlInput)}})
                        }
                    }

                    if(urlSearchType == "text"){
                        if(domainUrlInput){
                            authDispatch({type: 'SET_DOMAIN_URL_INPUT', payload: {value: domainUrlInput,valid: domainUrlInput.length > 0}})
                        }
                    }
                }
            }

            if(selectedCountries && selectedCountries.length > 0){
                tikTokDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: selectedCountries.split(",")})
            }else{
                tikTokDispatch({type: 'UPDATE_SELECTED_COUNTRIES', value: []})
            }

            if(startDate){
                tikTokDispatch({type: 'UPDATE_START_DATE',value: new Date(Date.parse(startDate))})
            }else{
                tikTokDispatch({type: 'UPDATE_END_DATE',value: ''})
            }

            if(endDate){
                tikTokDispatch({type: 'UPDATE_END_DATE',value: new Date(Date.parse(endDate))})
            }else{
                tikTokDispatch({type: 'UPDATE_END_DATE',value: ''})
            }


            if(sortType){
                tikTokDispatch({type: 'UPDATE_SELECTED_SORT_TYPE',value: {
                    name: sortType.charAt(0).toUpperCase() + sortType.slice(1),
                    type: sortType
                }})
            }

            if(page){
                tikTokDispatch({type: 'SET_CURRENT_PAGE_NO', value: page})
            }
        }else{
            tikTokDispatch({type: 'CLEAR_ALL'})
        }

        // on filter applied
        if((selectedCountries && selectedCountries.length > 0) || (startDate) || (endDate) || sortType != "relevant"){
            // console.log("inside filter")
            if (searchMode == "keyword") {
                if((keywordSearchType === "broad" && (keywords && keywords.length > 0)) || (keywordSearchType === "phrase" && (phraseInput && phraseInput.length > 0))){
                    if (keywordSearchType == "broad") {
                        let fields = searchIn.split(",")
                        let query = keywords.split(",").join(" ")
                        

                        const reqBody = {
                            query,
                            minimumShouldMatch: minimumShouldMatch,
                            searchIn: fields,
                            exclude: excludedTerm,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }
                        tikTokDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useTikTokInfoHook.keywordBoardFilterMutate(reqBody)
                    } else if (keywordSearchType == "phrase") {
                        // console.log("innnnnnnnnn")
                        let fields = searchIn.split(",")
                        

                        const reqBody = {
                            query: phraseInput,
                            searchIn: fields,
                            exclude: excludedTerm,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }
                        tikTokDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        useTikTokInfoHook.keywordPhraseFilterMutate(reqBody)
                    }
                }
            } else if (searchMode == "url") {
                if((urlSearchType == "domain" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) || urlSearchType == "text" && (domainUrlInput && domainUrlInput.length > 0) || urlSearchType == "exact" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)){
                    if (urlSearchType == "domain") {
                        
                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }
                        tikTokDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        // useTikTokInfoHook.urlDomainFilterMutate(reqBody)
                    } else if (urlSearchType == "text") {

                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }
                        tikTokDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        // useTikTokInfoHook.urlTextFilterMutate(reqBody)
                    } else if (urlSearchType == "exact") {
                        
                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(","): [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }
                        tikTokDispatch({type: 'UPDATE_LOADING_RESULTS', value: true})

                        // useTikTokInfoHook.urlExactFilterMatchMutate(reqBody)
                    }
                }
            }
        }else{
            // console.log("without filter")
            // on not filter applied
                if (searchMode == "keyword") {
                    if((keywordSearchType === "broad" && (keywords && keywords.length > 0)) || (keywordSearchType === "phrase" && (phraseInput && phraseInput.length > 0))){
                        if (keywordSearchType == "broad") {
                            let fields = searchIn.split(",")
                            let query = keywords.split(",").join(" ")
                    
                            const reqBody = {
                                query,
                                minimumShouldMatch: minimumShouldMatch,
                                searchIn: fields,
                                exclude: excludedTerm,
                                page: page,
                                keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                                competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                            }
                    
                            useTikTokInfoHook.keywordBroadMutate(reqBody)
                        } else if (keywordSearchType == "phrase") {
                            let fields = searchIn.split(",")
                            
                            const reqBody = {
                                query: phraseInput,
                                searchIn: fields,
                                exclude: excludedTerm,
                                page: page,
                                keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                                competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                            }
                    
                            useTikTokInfoHook.keywordPhraseMutate(reqBody)
                        }
                    }
                } else if (searchMode == "url") {
                    if((urlSearchType == "domain" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) || urlSearchType == "text" && (domainUrlInput && domainUrlInput.length > 0) || urlSearchType == "exact" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)){
                        if (urlSearchType == "domain") {
                            const reqBody = {
                                query: domainUrlInput,
                                page: page,
                                keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                                competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                            }
                    
                            // useTikTokInfoHook.urlDomainMutate(reqBody)
                        } else if (urlSearchType == "text") {
                    
                            const reqBody = {
                                query: domainUrlInput,
                                page: page,
                                keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                                competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                            }
                            // useTikTokInfoHook.urlTextMutate(reqBody)
                        } else if (urlSearchType == "exact") {
                            const reqBody = {
                                query: domainUrlInput,
                                page: page,
                                keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                                competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                            }
                            // useTikTokInfoHook.urlExactMutate(reqBody)
                        }
                    }
                }
        }

    }, [location.search])

    const ClearCountry = () => {
        searchParams.set("selectedCountries", "")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearSortType = () => {
        searchParams.set("sortType", "relevant")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearDateFilter = () => {
        searchParams.set("startDate", "")
        searchParams.set("endDate", "")
        tikTokDispatch({type: "UPDATE_DATE_FILTER_TYPE", value: ""})
        setSearchParams(searchParams)
    }

    const OpenAdvancedFilter = () => {
        searchParams.set("tone", "")
        searchParams.set("cta", "")
        searchParams.set("sentiment", "")
        searchParams.set("otherFilters", "")
        setSearchParams(searchParams)
    }



    // return <Container ref={myRef}>
    //     <SearchContainer>
    //         {/* <Content>
    //             <PlatformDropDown/>
    //             <SearchMode/>
    //             <Separator/> {
    //             tikTokState.searchMode === "keyword" ? <KeywordComponent/>: <UrlComponent/>
    //         } </Content>
    //         {
    //         !searchState.limitExceeded && < Filters />
    //         } */}
    //         <ResultPageSearch/>
    //      </SearchContainer>
    //      <ScrollableContainer>
    //     {
    //         !searchState.limitExceeded && < Filters />
    //     }
    //     {
    //     tikTokState.ads && tikTokState.ads.length > 0 && (
    //         <AdsCount>
    //             <div><p>
    //                 <span>reddit Search ads{" "}</span>
    //                 - {(tikTokState.currentPageNo - 1) * 20 != 0 ? (tikTokState.currentPageNo - 1) * 20 + 1 : 1} - {(((tikTokState.currentPageNo - 1) * 20) + 20) > tikTokState.totalAds ? tikTokState.totalAds : ((tikTokState.currentPageNo - 1) * 20) + 20} of <span>{
    //                     tikTokState.totalAds
    //                 }</span>
    //                     {" "}results
    //             </p></div>
    //             {/* <SearchQuery> {
    //                 tikTokState.keywords.length > 0 ? (tikTokState.keywords.map((word, idx) => {
    //                     return <span>{word}</span>;
    //                 })) : tikTokState.phraseInput ? (
    //                     <span>{
    //                         tikTokState.phraseInput
    //                     }</span>
    //                 ) : (
    //                     <span>{
    //                         tikTokState.domainUrlInput.value
    //                     }</span>
    //                 )
    //             } </SearchQuery> */}
    //         </AdsCount>
    //     )
    // }
    //     <Results/> {
    //     !searchState.limitExceeded && tikTokState.ads && tikTokState.ads.length > 0 && <Pagination/>
    // }
    // </ScrollableContainer>
    // </Container>

    return <Container ref={myRef}>
    {
            <>
                <Breadcrumb onClick={() => navigate('/overview')} >
                        <img src={back} />
                    Back to overview
                </Breadcrumb>
                <Header>
                    <HeaderLeft>
                        <h2>{`Tiktok Search ads (${formatWithCommas(tikTokState.totalAds)})`}</h2>
                        <AppliedFilters>
                            {tikTokState.selectedCountries.length > 0 && <AppliedFilterItem>
                                <p>{tikTokState.selectedCountries.length == 1 ? tikTokState.selectedCountries[0] : tikTokState.selectedCountries.length > 1 ? tikTokState.selectedCountries[0] + " + " + (
                                    tikTokState.selectedCountries.length - 1
                                ) : "no country"}</p>
                                <Close onClick={()=>ClearCountry()}>
                                    x 
                                </Close>
                            </AppliedFilterItem>}
                            {tikTokState.selectedSortType.type !== "relevant" && <AppliedFilterItem>
                                <p>{tikTokState.selectedSortType.name}</p>
                                <Close onClick={()=>ClearSortType()}>
                                    x 
                                </Close>
                            </AppliedFilterItem>}
                            {tikTokState.dateFilterType.length > 0 && <AppliedFilterItem>
                                <p>{tikTokState.dateFilterType}</p>
                                <Close onClick={()=>ClearDateFilter()}>
                                    x 
                                </Close>
                            </AppliedFilterItem>}
                            {/* {(tikTokState.selectedCta?.length > 0 || tikTokState.selectedTone?.length > 0 || tikTokState.sentimentFilter?.length > 0 || tikTokState.selectedOtherFilters?.length > 0) && <AppliedFilterItem>
                                <p>Advanced filters</p>
                                <Close onClick={()=>OpenAdvancedFilter()}>
                                    x 
                                </Close>
                            </AppliedFilterItem>} */}
                        </AppliedFilters>
                    </HeaderLeft>
                    <HeaderRight>
                        {/* <TotalAdsFound>
                            <label>Total ads found :</label>
                            <span>
                                {tikTokState.totalAds}
                            </span>
                        </TotalAdsFound> */}
                        <SortFocus />
                        <SortType />
                        <MajorFilters />
                    </HeaderRight>
                </Header>
                <Divider>
                    Display ads, content, etc., sorted based on your selected focus: Keywords or Competitors.
                </Divider>
                <ScrollableContainer>
                    <Results />
                    <Footer>
                        {(!authDispatch.limitExceeded && tikTokState.ads && !tikTokState.loadingResults && tikTokState.ads.length > 0) && <TotalResults>
                            <p>Showing {(tikTokState.currentPageNo - 1) * 20 != 0 ? (tikTokState.currentPageNo - 1) * 20 + 1 : 1} - {(((tikTokState.currentPageNo - 1) * 20) + 20) > tikTokState.totalAds ? tikTokState.totalAds : ((tikTokState.currentPageNo - 1) * 20) + 20} of <span>{
                                tikTokState.totalAds
                            }</span>
                            results</p>
                        </TotalResults>}
                        {!authDispatch.limitExceeded && tikTokState.ads && !tikTokState.loadingResults && tikTokState.ads.length > 0 && <Pagination />}
                    </Footer>
                </ScrollableContainer>
            </>
        }
    </Container>

}

export default TikTokAds
