import React, { useEffect, useRef } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

// Styles
import {
    Container,
    Header,
    Breadcrumb,
    ScrollableContainer,
    HeaderLeft,
    HeaderRight,
    TotalAdsFound,
    Footer,
    TotalResults,
    AppliedFilters,
    AppliedFilterItem,
    Close,
    RefereshButton,
    RefreshButtonContainer,
    Divider
} from "./index.styled"
import _static from "../../../../../../config/static"

import Pagination from './Pagination'

import { useGoogleSearch } from "../context/google"

import FeedbackPopup from '../../../../../components/feedbackPopup'
import { getSearchParams, urlPatternValidation, dateFormatter } from "../../../../../../utils/helper.js"

import { useGetUserInfo } from './../../../../../hooks/useGetUserInfo'
// Icons
import Results from './Results'
import { useAuth } from '../../../../../../context/auth'
import CrawlRequestResults from '../../Google/components/CrawlRequestResults'
import { useGoogleInfo } from '../hooks/useGoogleInfo.js'
import SortType from './NewFilters/SortType/index.js'
import MajorFilters from './NewFilters/MajorFilters/index.js'
import AdvancerFilter from './NewFilters/AdvancedFilters/index.js'
import TimerIcon from "../../../../../../media/Dashboard/Timer.svg"
import { useRequestData } from '../../../../../hooks/useRequestData.js'
import {formatWithCommas} from "../../../../../../utils/helper"
import SortFocus from '../../../utils/SortFocus/index.js'
import back from "../../assets/back.svg"

const GoogleSearchAds = () => {
    const { googleSearchState, googleSearchDispatch } = useGoogleSearch()
    const [searchParams, setSearchParams] = useSearchParams()
    const { authState, authDispatch } = useAuth()
    const location = useLocation()
    const navigate = useNavigate()
    const { searched } = googleSearchState
    const useRequestDataHook = useRequestData()

    const userInfoHook = useGetUserInfo()
    const useGoogleInfoHook = useGoogleInfo()

    const myRef = useRef(null);

    // set current platform
    useEffect(() => {
        if (location.pathname.includes("google")) {
            authDispatch({ type: 'SET_SEARCH_PLATFORM', value: 'google' })
        }
    }, [])

    const onDataRequest = () => {
        if (!authState.isDataRequested) {
            // useRequestDataHook.requestDataMutate({ id: googleSearchState.queryId })
            useRequestDataHook.requestRealtimeDataForGoogleMutate({ id: googleSearchState.queryId })
        }
    }

    useEffect(() => {
        console.log("google useEffect")
        const searchParamFromState = getSearchParams(location.search)

        const {
            searchMode,
            keywords,
            phraseInput,
            domainUrlInput,
            keywordSearchType,
            urlSearchType,
            searchIn,
            minimumShouldMatch,
            excludedTerm,
            page,
            selectedCountries,
            startDate,
            endDate,
            sortType,
            crawlRequestData,
            cta,
            tone,
            otherFilters,
            sentiment,
            language,
            keywordsArray,
            competitorsArray,
            sortArray
        } = searchParamFromState

        googleSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })


        console.log(searchMode)
        // setting data from params to state
        if (searchMode) {
            if (searchMode == "keyword") {
                authDispatch({ type: 'SET_SEARCH_MODE', value: searchMode })
                if (keywordSearchType) {
                    authDispatch({ type: 'SET_KEYWORD_SEARCHTYPE', value: keywordSearchType })

                    if (keywordSearchType == "phrase") {

                        if (phraseInput) {
                            authDispatch({ type: 'SET_PHRASE_INPUT', value: phraseInput })
                        }

                        if (searchIn && searchIn.length > 0) {

                            let finalSearchIn = ["title", "content"].map(si => {
                                if (searchIn.includes(si)) {
                                    googleSearchDispatch({
                                        type: "SET_PHRASE_SEARCH_IN", payload: {
                                            name: si.charAt(0).toUpperCase() + si.slice(1),
                                            value: si,
                                            checked: true
                                        }
                                    })
                                } else {
                                    googleSearchDispatch({
                                        type: "SET_PHRASE_SEARCH_IN", payload: {
                                            name: si.charAt(0).toUpperCase() + si.slice(1),
                                            value: si,
                                            checked: true
                                        }
                                    })
                                }
                            })
                        }

                    }

                    if (keywordSearchType == "broad") {

                        if (keywords && keywords.length > 0) {
                            authDispatch({ type: 'SET_KEYWORDS', value: keywords.split(",") })
                        }

                        googleSearchDispatch({ type: "SET_BROAD_MIN_SHOULD_MATCH", value: { name: 'Min. match', value: minimumShouldMatch ? minimumShouldMatch : 0 } })

                        if (searchIn && searchIn.length > 0) {

                            let finalSearchIn = ["title", "content"].map(si => {
                                if (searchIn.includes(si)) {
                                    googleSearchDispatch({
                                        type: "SET_BROAD_SEARCH_IN", payload: {
                                            name: si.charAt(0).toUpperCase() + si.slice(1),
                                            value: si,
                                            checked: true
                                        }
                                    })
                                } else {
                                    googleSearchDispatch({
                                        type: "SET_BROAD_SEARCH_IN", payload: {
                                            name: si.charAt(0).toUpperCase() + si.slice(1),
                                            value: si,
                                            checked: false
                                        }
                                    })
                                }
                            })
                        }
                    }

                    if (excludedTerm) {
                        googleSearchDispatch({ type: 'UPDATE_EXCLUDED_TERM', value: excludedTerm })
                    }
                }
            }

            if (searchMode == "url") {
                authDispatch({ type: 'SET_SEARCH_MODE', value: searchMode })
                if (urlSearchType) {
                    authDispatch({ type: 'SET_URL_SEARCHTYPE', value: urlSearchType })

                    if (urlSearchType == "exact") {

                        if (domainUrlInput) {
                            authDispatch({ type: 'SET_DOMAIN_URL_INPUT', payload: { value: domainUrlInput, valid: urlPatternValidation(domainUrlInput) } })
                        }
                    }

                    if (urlSearchType == "domain") {

                        if (domainUrlInput) {
                            authDispatch({ type: 'SET_DOMAIN_URL_INPUT', payload: { value: domainUrlInput, valid: urlPatternValidation(domainUrlInput) } })
                        }
                    }

                    if (urlSearchType == "text") {

                        if (domainUrlInput) {
                            authDispatch({ type: 'SET_DOMAIN_URL_INPUT', payload: { value: domainUrlInput, valid: domainUrlInput.length > 0 } })
                        }
                    }
                }
            }

            if (selectedCountries && selectedCountries.length > 0) {
                googleSearchDispatch({ type: 'UPDATE_SELECTED_COUNTRIES', value: selectedCountries.split(",") })
            } else {
                googleSearchDispatch({ type: 'UPDATE_SELECTED_COUNTRIES', value: [] })
            }

            if (cta && cta.length > 0) {
                googleSearchDispatch({ type: 'SET_SELECTED_CTA', value: cta.split(",") })
            } else {
                googleSearchDispatch({ type: 'SET_SELECTED_CTA', value: [] })
            }

            if (language && language.length > 0) {
                googleSearchDispatch({ type: 'SET_SELECTED_LANGUAGE', value: language.split(",") })
            } else {
                googleSearchDispatch({ type: 'SET_SELECTED_LANGUAGE', value: [] })
            }

            if (tone && tone.length > 0) {
                googleSearchDispatch({ type: 'SET_SELECTED_TONE', value: tone.split(",") })
            } else {
                googleSearchDispatch({ type: 'SET_SELECTED_TONE', value: [] })
            }

            if (otherFilters && otherFilters.length > 0) {
                googleSearchDispatch({ type: 'SET_SELECTED_OTHER_FILTERS', value: otherFilters.split(",") })
            } else {
                googleSearchDispatch({ type: 'SET_SELECTED_OTHER_FILTERS', value: [] })
            }

            if (sentiment) {
                googleSearchDispatch({ type: 'SET_SENTIMENT_ADVANCED_FILTER', value: sentiment })
            } else {
                googleSearchDispatch({ type: 'SET_SENTIMENT_ADVANCED_FILTER', value: "" })
            }
 
            if (startDate) {
                googleSearchDispatch({ type: 'UPDATE_START_DATE', value: new Date(Date.parse(startDate)) })
            } else {
                googleSearchDispatch({ type: 'UPDATE_END_DATE', value: '' })
            }

            if (endDate) {
                googleSearchDispatch({ type: 'UPDATE_END_DATE', value: new Date(Date.parse(endDate)) })
            } else {
                googleSearchDispatch({ type: 'UPDATE_END_DATE', value: '' })
            }

            if (sortType) {
                console.log("sortType", sortType, {
                    name: sortType.charAt(0).toUpperCase() + sortType.slice(1),
                    type: sortType
                })
                googleSearchDispatch({
                    type: 'UPDATE_SELECTED_SORT_TYPE', value: {
                        name: sortType.charAt(0).toUpperCase() + sortType.slice(1),
                        type: sortType
                    }
                })
            }

            if (page) {
                googleSearchDispatch({ type: 'SET_CURRENT_PAGE_NO', value: page })
            }
        }

        // if crawl request data
        if (crawlRequestData) {
            googleSearchDispatch({ type: 'UPDATE_LOADING_RESULTS', value: true })

            useGoogleInfoHook.getCrawledDataMutate({
                query: searchMode == "url" ? domainUrlInput : keywordSearchType == "phrase" ? phraseInput : keywords.join(" "),
                page
            })
        } else if ((selectedCountries && selectedCountries.length > 0) || (startDate) || (endDate) || sortType != "relevant" || (cta && cta.length > 0) || (tone && tone.length) || (otherFilters && otherFilters.length > 0) || (language && language.length > 0)) { //if filters data available
            if (searchMode == "keyword") {

                if ((keywordSearchType === "broad" && (keywords && keywords.length > 0)) || (keywordSearchType === "phrase" && (phraseInput && phraseInput.length > 0))) {

                    if (keywordSearchType == "broad") {

                        let fields = searchIn.split(",")
                        let query = keywords.split(",").join(" ")

                        const reqBody = {
                            query,
                            minimumShouldMatch: minimumShouldMatch,
                            searchIn: fields,
                            exclude: excludedTerm,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(",") : [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType,
                            cta: cta ? cta.split(",") : [],
                            tone: tone ? tone.split(",") : [],
                            sentiment: sentiment ? sentiment : "",
                            languages: language ? language.split(",") : [],
                            advancedfilters: otherFilters ? otherFilters.split(",") : [],
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }

                        googleSearchDispatch({ type: 'UPDATE_LOADING_RESULTS', value: true })
                        useGoogleInfoHook.keywordBoardFilterMutate(reqBody)

                    } else if (keywordSearchType == "phrase") {

                        let fields = searchIn.split(",")

                        const reqBody = {
                            query: phraseInput,
                            searchIn: fields,
                            exclude: excludedTerm,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(",") : [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType,
                            cta: cta ? cta.split(",") : [],
                            tone: tone ? tone.split(",") : [],
                            sentiment: sentiment ? sentiment : "",
                            languages: language ? language.split(",") : [],
                            advancedfilters: otherFilters ? otherFilters.split(",") : [],
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }

                        googleSearchDispatch({ type: 'UPDATE_LOADING_RESULTS', value: true })
                        useGoogleInfoHook.keywordPhraseFilterMutate(reqBody)
                    }
                }

            } else if (searchMode == "url") {

                if ((urlSearchType == "domain" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) || urlSearchType == "text" && (domainUrlInput && domainUrlInput.length > 0) || urlSearchType == "exact" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) {

                    if (urlSearchType == "domain") {

                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(",") : [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType,
                            cta: cta ? cta.split(",") : [],
                            tone: tone ? tone.split(",") : [],
                            sentiment: sentiment ? sentiment : "",
                            languages: language ? language.split(",") : [],
                            advancedfilters: otherFilters ? otherFilters.split(",") : [],
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }

                        googleSearchDispatch({ type: 'UPDATE_LOADING_RESULTS', value: true })
                        useGoogleInfoHook.urlDomainFilterMutate(reqBody)

                    } else if (urlSearchType == "text") {

                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(",") : [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType,
                            cta: cta ? cta.split(",") : [],
                            tone: tone ? tone.split(",") : [],
                            sentiment: sentiment ? sentiment : "",
                            languages: language ? language.split(",") : [],
                            advancedfilters: otherFilters ? otherFilters.split(",") : [],
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }

                        googleSearchDispatch({ type: 'UPDATE_LOADING_RESULTS', value: true })
                        useGoogleInfoHook.urlTextFilterMutate(reqBody)

                    } else if (urlSearchType == "exact") {

                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            countries: selectedCountries ? selectedCountries.split(",") : [],
                            startDate: dateFormatter(startDate),
                            endDate: dateFormatter(endDate),
                            sort: sortType,
                            cta: cta ? cta.split(",") : [],
                            tone: tone ? tone.split(",") : [],
                            sentiment: sentiment ? sentiment : "",
                            languages: language ? language.split(",") : [],
                            advancedfilters: otherFilters ? otherFilters.split(",") : [],
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }

                        googleSearchDispatch({ type: 'UPDATE_LOADING_RESULTS', value: true })
                        useGoogleInfoHook.urlExactFilterMatchMutate(reqBody)

                    }
                }
            }
        } else {// on no filter applied
            if (searchMode == "keyword") {

                if ((keywordSearchType === "broad" && (keywords && keywords.length > 0)) || (keywordSearchType === "phrase" && (phraseInput && phraseInput.length > 0))) {

                    if (keywordSearchType == "broad") {

                        let fields = searchIn.split(",")
                        let query = keywords.split(",").join(" ")

                        const reqBody = {
                            query,
                            minimumShouldMatch: minimumShouldMatch,
                            searchIn: fields,
                            exclude: excludedTerm,
                            page: page,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }

                        googleSearchDispatch({ type: 'UPDATE_LOADING_RESULTS', value: true })
                        useGoogleInfoHook.keywordBroadMutate(reqBody)

                    } else if (keywordSearchType == "phrase") {

                        let fields = searchIn.split(",")

                        const reqBody = {
                            query: phraseInput,
                            searchIn: fields,
                            exclude: excludedTerm,
                            page: page,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }

                        googleSearchDispatch({ type: 'UPDATE_LOADING_RESULTS', value: true })
                        useGoogleInfoHook.keywordPhraseMutate(reqBody)

                    }
                }

            } else if (searchMode == "url") {

                if ((urlSearchType == "domain" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) || urlSearchType == "text" && (domainUrlInput && domainUrlInput.length > 0) || urlSearchType == "exact" && (domainUrlInput && domainUrlInput.length > 0) && urlPatternValidation(domainUrlInput)) {

                    if (urlSearchType == "domain") {

                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }

                        googleSearchDispatch({ type: 'UPDATE_LOADING_RESULTS', value: true })
                        useGoogleInfoHook.urlDomainMutate(reqBody)

                    } else if (urlSearchType == "text") {

                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }

                        googleSearchDispatch({ type: 'UPDATE_LOADING_RESULTS', value: true })
                        useGoogleInfoHook.urlTextMutate(reqBody)

                    } else if (urlSearchType == "exact") {

                        const reqBody = {
                            query: domainUrlInput,
                            page: page,
                            keywordsArray: sortArray == 'keywords' && keywordsArray ? keywordsArray.split(",") : [],
                            competitorsArray :sortArray == 'competitors' && competitorsArray ? competitorsArray.split(",") : [],
                        }

                        googleSearchDispatch({ type: 'UPDATE_LOADING_RESULTS', value: true })
                        useGoogleInfoHook.urlExactMutate(reqBody)

                    }
                }
            }
        }

    }, [location.search])

    let feedbackPopupOpen = localStorage.getItem("feedbackpopup");

    // return <Container ref={myRef}>
    //     {feedbackPopupOpen && authState.feedbackPopup  ? (authState.features && authState.features.searchesMade && authState.features.searchesMade == 5) ? <FeedbackPopup type={"after-search"}/> : <FeedbackPopup type={"after-onboarding"}/> : ""}
    //     {
    //         authDispatch.isCrawlRequestPage ? <CrawlRequestResults /> :
    //         <>
    //             <Breadcrumb>
    //                 {"Overview > "} <span>{authState.searchMode === "keyword" ? "Text Search in Ad library" : "Url Search in Ad library"}</span>
    //             </Breadcrumb>
    //             <SearchContainer>
    //                 <ResultPageSearch/>
    //             </SearchContainer>
    //             <ScrollableContainer>
    //                 {
    //                     !authDispatch.limitExceeded && < Filters />
    //                 }
    //                 {
    //                 googleSearchState.ads && googleSearchState.ads.length > 0 && (
    //                 <AdsCount>

                        // <div><p>
                        //     <span>Google Search ads{" "}</span>
                        //     {/* <span> */}
                        //     - {(googleSearchState.currentPageNo - 1) * 20 != 0 ? (googleSearchState.currentPageNo - 1) * 20 + 1 : 1} - {(((googleSearchState.currentPageNo - 1) * 20) + 20) > googleSearchState.totalAds ? googleSearchState.totalAds : ((googleSearchState.currentPageNo - 1) * 20) + 20} of <span>{
                        //         googleSearchState.totalAds
                        //     }</span>
                        //         {" "}results 
                        //         {/* </span> */}
                        // </p></div>
    //                 </AdsCount>
    //                 )
    //                 }
    //             <Results/> 
    //             {
    //             !authDispatch.limitExceeded && googleSearchState.ads && !googleSearchState.loadingResults && googleSearchState.ads.length > 0 && <Pagination/>
    //             } 
    //             </ScrollableContainer>
    //         </>
    //     }
    // </Container>

    const ClearCountry = () => {
        searchParams.set("selectedCountries", "")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearSortType = () => {
        searchParams.set("sortType", "relevant")
        searchParams.set("page", 1)
        setSearchParams(searchParams)
    }

    const ClearDateFilter = () => {
        searchParams.set("startDate", "")
        searchParams.set("endDate", "")
        googleSearchDispatch({type: "UPDATE_DATE_FILTER_TYPE", value: ""})
        setSearchParams(searchParams)
    }

    const OpenAdvancedFilter = () => {
        searchParams.set("tone", "")
        searchParams.set("cta", "")
        searchParams.set("sentiment", "")
        searchParams.set("otherFilters", "")
        setSearchParams(searchParams)
    }
  
    // console.log(googleSearchState.queryId)
    
    return <Container ref={myRef}>
        {feedbackPopupOpen && authState.feedbackPopup ? /* (authState.features && authState.features.searchesMade && authState.features.searchesMade == 5) ? <FeedbackPopup type={"after-search"} /> : */ <FeedbackPopup type={"after-onboarding"} /> : ""}
        {
            googleSearchState.openAdvancedFilter && <AdvancerFilter />
        }
        {
            authState.isCrawlRequestPage
                ?
                <CrawlRequestResults />
                :
                <>
                    <Breadcrumb onClick={() => navigate('/overview')} >
                        <img src={back} />
                        Back to overview
                    </Breadcrumb>
                    <Header>
                        <HeaderLeft>
                            <h2>{`Google Search ads (${formatWithCommas(googleSearchState.totalAds)})`}</h2>
                            <AppliedFilters>
                                {googleSearchState.selectedCountries.length > 0 && <AppliedFilterItem>
                                    <p>{googleSearchState.selectedCountries.length == 1 ? googleSearchState.selectedCountries[0] : googleSearchState.selectedCountries.length > 1 ? googleSearchState.selectedCountries[0] + " + " + (
                                        googleSearchState.selectedCountries.length - 1
                                    ) : "no country"}</p>
                                    <Close onClick={()=>ClearCountry()}>
                                        x 
                                    </Close>
                                </AppliedFilterItem>}
                                {googleSearchState.selectedSortType.type !== "relevant" && <AppliedFilterItem>
                                    <p>{googleSearchState.selectedSortType.name}</p>
                                    <Close onClick={()=>ClearSortType()}>
                                        x 
                                    </Close>
                                </AppliedFilterItem>}
                                {googleSearchState.dateFilterType.length > 0 && <AppliedFilterItem>
                                    <p>{googleSearchState.dateFilterType}</p>
                                    <Close onClick={()=>ClearDateFilter()}>
                                        x 
                                    </Close>
                                </AppliedFilterItem>}
                                {(googleSearchState.selectedCta?.length > 0 || googleSearchState.selectedTone?.length > 0 || googleSearchState.sentimentFilter?.length > 0 || googleSearchState.selectedOtherFilters?.length > 0) && <AppliedFilterItem>
                                    <p>Advanced filters</p>
                                    <Close onClick={()=>OpenAdvancedFilter()}>
                                        x 
                                    </Close>
                                </AppliedFilterItem>}
                            </AppliedFilters>
                        </HeaderLeft>
                        <HeaderRight>
                            {/* <TotalAdsFound>
                                <label>Total ads found :</label>
                                <span>
                                    {formatWithCommas(googleSearchState.totalAds)}
                                </span>
                            </TotalAdsFound> */}
                            <SortFocus />
                            <SortType />
                            <MajorFilters />
                        </HeaderRight>
                    </Header>
                    <Divider>
                        Display ads, content, etc., sorted based on your selected focus: Keywords or Competitors.
                    </Divider>
                    {googleSearchState.queryId && !googleSearchState.noResults && <RefreshButtonContainer>
                        <RefereshButton 
                            isDisabled={useRequestDataHook.isRequestRealtimetDataForGoogleLoading || authState.isDataRequested}
                            onClick={onDataRequest}
                        >
                            <img src={TimerIcon}/><p>{authState.isDataRequested ? "Request sent" : useRequestDataHook.isRequestRealtimetDataForGoogleLoading ? "Requesting..." : "Refresh data"}</p>
                        </RefereshButton>
                    </RefreshButtonContainer>}
                    <ScrollableContainer>
                        <Results />
                        <Footer>
                            {(!authDispatch.limitExceeded && googleSearchState.ads && !googleSearchState.loadingResults && googleSearchState.ads.length > 0) && <TotalResults>
                                <p>Showing {(googleSearchState.currentPageNo - 1) * 20 != 0 ? (googleSearchState.currentPageNo - 1) * 20 + 1 : 1} - {(((googleSearchState.currentPageNo - 1) * 20) + 20) > googleSearchState.totalAds ? googleSearchState.totalAds : ((googleSearchState.currentPageNo - 1) * 20) + 20} of <span>{
                                    googleSearchState.totalAds
                                }</span>
                                results</p>
                            </TotalResults>}
                            {!authDispatch.limitExceeded && googleSearchState.ads && !googleSearchState.loadingResults && googleSearchState.ads.length > 0 && <Pagination />}
                        </Footer>
                    </ScrollableContainer>
                </>
        }
    </Container>
}

export default GoogleSearchAds
