import { appClient } from "../../../../../../utils/axios-utils";
import { useMutation } from "@tanstack/react-query";
import { useGetUserInfo } from "../../../../../hooks/useGetUserInfo";
import { useSearch } from "../../context/search";
import { useTikTok } from "../context/tiktok";


const keywordBoardFilterMatch = async (data) => {
    const config = {
        url: `/search/twitter/keyword/broad/filter`,
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseFilterMatch = async (data) => {
    const config = {
        url: `/search/twitter/keyword/phrase/filter`,
        method: "POST",
        data
    }

    return appClient(config)
}



const keywordBroadMatch = async (data) => {
    const config = {
        url: "/search/twitter/keyword/broad",
        method: "POST",
        data
    }

    return appClient(config)
}

const keywordPhraseMatch = async (data) => {
    const config = {
        url: "/search/twitter/keyword/phrase",
        method: "POST",
        data
    }

    return appClient(config)
}



export const useTikTokInfo = () => {
    const { tikTokState, tikTokDispatch } = useTikTok()
    const {searchState, searchDispatch} = useSearch()

    const userInfoHook = useGetUserInfo()


    const { mutate: keywordBoardFilterMutate, isLoading: isKeywordBoardFilterLoading } = useMutation(keywordBoardFilterMatch, {
        onSuccess: (data) => {
            tikTokDispatch({ type: "SET_ADS", value: data.documents })
            tikTokDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            tikTokDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
            tikTokDispatch({ type: "SET_IFRAME_LOADER", value: true })

            if(data.documents && data.documents.length > 0){
                tikTokDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                tikTokDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                tikTokDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                tikTokDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                tikTokDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                tikTokDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            tikTokDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            tikTokDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const { mutate: keywordPhraseFilterMutate, isLoading: isKeywordPhraseFilterLoading } = useMutation(keywordPhraseFilterMatch, {
        onSuccess: (data) => {
            tikTokDispatch({ type: "SET_ADS", value: data.documents })
            tikTokDispatch({ type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments })
            tikTokDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})
            tikTokDispatch({ type: "SET_IFRAME_LOADER", value: true })

            if(data.documents && data.documents.length > 0){
                tikTokDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                tikTokDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                tikTokDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                tikTokDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                tikTokDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                tikTokDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            tikTokDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            tikTokDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })



    const {mutate: keywordBroadMutate, isLoading: isKeywordBroadLoading} = useMutation(keywordBroadMatch, {
        onSuccess: (data) => {
            tikTokDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})
            tikTokDispatch({ type: "SET_IFRAME_LOADER", value: true })

            // Update search results
            tikTokDispatch({type: "SET_ADS", value: data.documents})
            tikTokDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            tikTokDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                tikTokDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                tikTokDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                tikTokDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                tikTokDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                tikTokDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                tikTokDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            tikTokDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            tikTokDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })

    const {mutate: keywordPhraseMutate, isLoading: isKeywordPhraseLoading} = useMutation(keywordPhraseMatch, {
        onSuccess: (data) => {
            // console.log(data)
            tikTokDispatch({type: 'UPDATE_FILTER_STATUS', value: 'loaded'})
            tikTokDispatch({ type: "SET_IFRAME_LOADER", value: true })
            // Update search results
            tikTokDispatch({type: "SET_ADS", value: data.documents})
            tikTokDispatch({type: "SET_TOTAL_ADS", value: data.totalNumberOfDocuments})
            tikTokDispatch({type: "SET_AVAILABLE_COUNTRIES", value: data.countries})

            if(data.documents && data.documents.length > 0){
                tikTokDispatch({ type: "UPDATE_SEARCHED", value: true })
            }else{
                tikTokDispatch({ type: "UPDATE_SEARCHED", value: false })
            }
            if(data.totalNumberOfDocuments == 0){
                tikTokDispatch({type: 'UPDATE_NO_RESULTS', value: true})
                tikTokDispatch({type: 'SET_EMPTY_RESULT_ID', value: data.queryId})
            }else{
                tikTokDispatch({type: 'UPDATE_NO_RESULTS', value: false})
                tikTokDispatch({type: 'SET_EMPTY_RESULT_ID', value: ""})
            }
            tikTokDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})
            userInfoHook.getRemainingCreditsMutate()
            searchDispatch({type: 'SET_SEARCH_DISABLED', value: true})

        },
        onError: (err) => {
            tikTokDispatch({type: 'UPDATE_LOADING_RESULTS', value: false})

        }
    })


    return {
        keywordBoardFilterMutate,
        isKeywordBoardFilterLoading,
        keywordPhraseFilterMutate,
        isKeywordPhraseFilterLoading,
        keywordBroadMutate,
        isKeywordBroadLoading,
        keywordPhraseMutate,
        isKeywordPhraseLoading
    };
};
    