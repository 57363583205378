import React, { useEffect, useState } from "react";
import {
  ChartHeadWrap,
  GraphContainer,
  NoDataDiv,
  PlatformDiv,
  PlatformName,
  PlatformchartWrap,
  TabDiv,
  TabOptions,
} from "./index.styled";
import adResultData from "../../../../../static";
import { Line } from "react-chartjs-2";
import { useAdIntegration } from "../../../../../context/AdManagementContext";
import { useAdIntegrationInfo } from "../../../../../hooks/useAdManagementInfo";
import { useAuth } from "../../../../../../../../../context/auth";
import Skeleton from "react-loading-skeleton";

const AccountDetailsGraph = () => {
  const {
    newAdManagementState: {
      dateStartEnd,
      isMetricChart,
      isStatusDetail,
      selectedId,
      isUpdateApiToggle,
    },
    newAdManagementDispatch,
  } = useAdIntegration();
  const { authState, authDispatch } = useAuth();
  const { setMetricChart, isMetricChartLoading } = useAdIntegrationInfo();
  const { tabOptions } = adResultData;
  const [tabToggle, setTabToggle] = useState("impressions");

  useEffect(() => {
    setMetricChart({
      projectId: authState.defaultProjectInfo.projectId,
      customerId: selectedId.customerId,
      startDate: dateStartEnd.start.format("YYYY-MM-D"),
      endDate: dateStartEnd.end.format("YYYY-MM-D"),
      metric: tabToggle == "total Spent" ? "totalSpent" : tabToggle,
      status: isStatusDetail.toUpperCase(),
    });
  }, [tabToggle, isStatusDetail, isUpdateApiToggle,selectedId]);

  let dataset = {
    data: isMetricChart,
  };

  const aggregateData = (data, interval) => {
    const aggregated = {};

    data.forEach((point) => {
      // Parse the date from dd-mm-yyyy format
      const [year, month, day] = point.data.split("-");
      const date = new Date(`${year}-${month}-${day}`); // Convert to yyyy-mm-dd format

      // Determine the aggregation key based on the interval
      let key;
      if (interval === "daily") {
        key = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`; // Daily: yyyy-mm-dd
      } else if (interval === "weekly") {
        const week = Math.ceil(date.getDate() / 7); // Get the week of the month
        key = `${date.getFullYear()}-${date.getMonth() + 1}-W${week}`; // Weekly: yyyy-mm-Wweek
      } else if (interval === "monthly") {
        key = `${date.getFullYear()}-${date.getMonth() + 1}`; // Monthly: yyyy-mm
      }

      if (!aggregated[key]) {
        aggregated[key] = { value: 0, count: 0 };
      }

      aggregated[key].value += Number(point.value);
      aggregated[key].count += 1;
    });

    return Object.entries(aggregated).map(([key, { value, count }]) => ({
      data: key, // Aggregated date (key)
      value: value, // Average value for the interval
    }));
  };

  const countDaysInRange = (range) => {
    let count = 0;
    for (let day of range.by("days")) {
      count++;
    }
    if (count <= 30) {
      return "daily";
    } else if (count > 30 && count <= 90) {
      return "weekly";
    } else {
      return "monthly";
    }
  };
  // Prepare labels and datasets from the incoming data
  const prepareChartData = () => {
    const labelsSet = new Set();
    const dayCount = countDaysInRange(dateStartEnd);
    // Aggregate data for both datasets
    const aggregatedData = dataset.data.map((item) => {
      return aggregateData(item.data, dayCount); // Change to 'weekly' if needed
    });

    // Collect unique labels from all aggregated datasets
    aggregatedData.forEach((item) => {
      item.forEach((dataPoint) => {
        labelsSet.add(dataPoint.data);
      });
    });

    // Convert Set to Array and sort
    const labels = Array.from(labelsSet);
    // Now map each dataset to align with the labels and fill missing months with 0
    const datasets = aggregatedData.map((item, index) => {
      // Create a map of the aggregated data for easier access
      const dataMap = item.reduce((acc, dataPoint) => {
        acc[dataPoint.data] = dataPoint.value;
        return acc;
      }, {});

      // For each label (month), if no data is available, use 0
      const data = labels.map((label) => dataMap[label] || 0);

      return {
        label: "", // Keeping the label empty to maintain the original design
        data, // Data now aligned with labels, filled with 0 for missing values
        fill: "start",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);

          if (index === 0) {
            gradient.addColorStop(0, "rgba(37, 205, 37, 0.40)"); // Green for first dataset
            gradient.addColorStop(0.9959, "rgba(37, 205, 37, 0)");
          } else {
            gradient.addColorStop(0.0522, "rgba(232, 230, 249, 0.00)"); // Light gray for second dataset
            gradient.addColorStop(0.9675, "rgba(232, 230, 249, 0.80)");
          }

          return gradient;
        },
        borderColor: index === 0 ? "#25CD25" : "#1F4DDA",
        borderWidth: 1,
        pointBackgroundColor: "#fff",
        lineTension: 0.4,
      };
    });

    return {
      labels,
      datasets,
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "category", // Ensure x-axis is treated as categories
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 10,
          font: {
            family: "Inter",
            size: 12,
            weight: 400,
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          tickColor: "#fff",
        },
        ticks: {
          font: {
            family: "Inter",
            size: 12,
            weight: 400,
          },
        },
      },
    },
    elements: {
      point: {
        radius: 3,
        // hoverRadius: 8, // Keeping point radius intact
      },
    },
    plugins: {
      filler: {
        propagate: false,
      },
      legend: {
        display: false, // Keeping legend hidden as per original design
      },
    },
  };

  return (
    <PlatformchartWrap>
      <ChartHeadWrap>
        <TabOptions>
          {tabOptions.map((item, index) => (
            <TabDiv
              key={index}
              onClick={() => setTabToggle(item)}
              isActive={tabToggle === item}
            >
              {item}
            </TabDiv>
          ))}
        </TabOptions>
        <PlatformDiv>
          {isMetricChart &&
            isMetricChart.map((plat) => (
              <PlatformName>
                <p></p>
                {plat.platform}
              </PlatformName>
            ))}
        </PlatformDiv>
      </ChartHeadWrap>
      {!isMetricChartLoading ? (
        <GraphContainer>
          {prepareChartData().labels.length > 0 ? (
            <Line data={prepareChartData()} options={options} />
          ) : (
            <NoDataDiv>No data found</NoDataDiv>
          )}
        </GraphContainer>
      ) : (
        <GraphContainer>
          <Skeleton height={'100%'}/>
        </GraphContainer>
      )}
    </PlatformchartWrap>
  );
};

export default AccountDetailsGraph;
