import styled from "styled-components";

export const ResultWrapper = styled.div``;

export const AdResultWrap = styled.div`
  max-width: 1438px;
  margin: 33px auto;
  @media screen and (max-width: 1750px) {
    margin: 33px 30px;
  }
`;
export const AdHeader = styled.div`
  color: #000;
  font-size: 24px;
  font-weight: 600;
  line-height: 38px; /* 158.333% */
  span {
    color: #ff711e;
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
  }
`;
export const AdHeaderContainer = styled.div`
  p {
    color: #9c9c9c;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%; /* 21px */
    margin-top: 8px;
  }
`;
export const AdHeadWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
`;
export const CalenderWrap = styled.div`
  position: relative;
`;
export const PlatformAccountWrap = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;
export const PlatformAccount = styled.div`
  border-radius: 24px;
  border: ${(props) =>
    props.isConnect ? "1px solid #adddc5" : "1px solid #FFDBDB"};
  background: ${(props) =>
    props.isConnect ? "#f2faf6" : !props.isDisable ? " #FFFBFB" : "#D9D9D9B2"};
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* cursor: ${(props) => props.isConnect && !props.isDisable && "pointer"}; */
  position: relative;
  opacity: ${(props) => props.isDisable && "0.5"};
  p {
    color: rgba(28, 28, 28, 0.7);
    font-size: 14px;
    font-weight: 500;
    line-height: 150%; /* 21px */
  }
`;
export const CalendarContainer = styled.div`
  border-radius: var(--8, 8px);
  border: 1px solid #dadfe4;
  background: #fff;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  img {
    height: 16px;
  }
  .arrowToggle {
    transform: ${(props) => !props.isArrow && "rotate(180deg)"};
  }
`;
export const CalendarDropDown = styled.div`
  position: absolute;
  background: #fff;
  top: 42px;
  right: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 9px 3px;
  z-index: 2;
  display: flex;
  height: fit-content;
  padding: 15px;
  gap: 20px;
  .DateRangePicker {
    background: #f8fcff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 9px 3px;
    margin-bottom: unset;
  }
  .DateRangePicker__CalendarSelection {
    background-color: rgb(255, 113, 30);
    border: 1px solid rgb(255, 113, 30);
  }
`;
export const ManageButton = styled.div`
  border-radius: 24px;
  border: 1px solid #dadfe4;
  background: #fff;
  display: flex;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
  width: 132px;
  padding: 10px;
  cursor: pointer;
  img {
    height: 18px;
  }
`;
export const ManageWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  flex-wrap: wrap;
  gap: 10px;
`;
export const StatusContainer = styled.div`
  display: flex;
  gap: 10px;
`;
export const StatusDiv = styled.div`
  cursor: pointer;
  width: 100px;
  height: 40px;
  padding: 10px;
  gap: 10px;
  border-radius: var(--8, 8px);
  border: ${(props) =>
    props.isSelected ? "1px solid #87A0E5" : "1px solid #dadfe4"};
  background: ${(props) => (props.isSelected ? "#E8EFFD" : "#fff")};
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
`;
export const DropDownDiv = styled.div``;
export const DropDownWrap = styled.div`
  width: 390px;
  height: 346px;
  border-radius: 6px;
  border: 1px solid #dadfe4;
  background: #fff;
  position: absolute;
  z-index: 2;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  left: 0;
  top: 56px;

  p {
    color: rgba(28, 28, 28, 0.9);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const InputDiv = styled.div`
  display: flex;
  gap: 16px;
  padding: 0 10px;
  background: #f7f8f9;
  height: 36px;
  align-items: center;
  input {
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    background: none;
  }
`;
export const ResultList = styled.div`
  border-top: 1px solid #dadfe4;
  height: 73%;
  overflow-y: auto;
`;
export const VerticalLine = styled.div`
  /* border-top: 1px solid #dadfe4; */
  border-bottom: 1px solid #dadfe4;
  padding: 4px 0;
  p {
    background: ${(props) => (props.isActive ? "#ebf2fd" : "")};
    height: 36px;
    padding: 8px 10px;
    color: rgba(28, 28, 28, 0.8);
    font-size: 14px;
    font-weight: ${(props) => (props.isActive ? "600" : "400")};
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;
    text-transform: capitalize;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background: #f4f8fe;
    }
  }
`;
export const DateDisplay = styled.div`
  display: flex;
  align-items: center;
`;

// export const EndDateContainer = styled.div`
//   input {
//     border: 1px solid #ececec;
//     border-radius: 8px;
//     height: 32px;
//     width: 117px;
//     outline: none;
//     font-size: 14px;
//     font-weight: 300;
//     color: #2e2e2e;
//     /* text-align: center; */
//     padding: 6px 12px;
//   }
//   .react-datepicker-popper {
//     transform: translate(389px, 50px) !important;
//   }
// `;

// export const StartDateContainer = styled.div`
//   input {
//     border: 1px solid #ececec;
//     border-radius: 8px;
//     height: 32px;
//     width: 117px;
//     outline: none;
//     font-size: 14px;
//     font-weight: 300;
//     color: #2e2e2e;
//     /* text-align: center; */
//     padding: 6px 12px;
//   }
//   .react-datepicker-popper {
//     transform: translate(136px, 50px) !important;
//   }
// `;
export const CustomContainer = styled.div`
  width: max-content;
`;
export const CustomDiv = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  /* color: ${(props) =>
    props.isActive ? "#fff" : "rgba(28, 28, 28, 0.8)"}; */
  color: rgba(28, 28, 28, 0.8);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.14px;
  /* background: ${(props) => (props.isActive ? "rgb(255, 113, 30)" : "")}; */
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background: rgb(255, 113, 30);
    color: #fff;
  }
`;
export const ContainerToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  .dropArrow {
    transform: ${(props) => !props.isToggle && "rotate(180deg)"};
  }
`;
