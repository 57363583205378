import React, { useEffect, useState } from "react";
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg";
import { useRequestData } from "../../../../../../hooks/useRequestData";

// Styles
import {
  CountriesList,
  TagsList,
  TagsContainer,
  CountriesContainer,
  AvgPosition,
  DateRange,
  AdDetailsBody,
  CloseDetails,
  DetailsHeader,
  DetailsContainer,
  FooterRight,
  AdCountries,
  AdDate,
  FooterLeft,
  CardFooter,
  CardBody,
  FloatingButtonsContainer,
  Card,
  CardsList,
  ResultsContainer,
  ResultsWraper,
  BusinessName,
  DetailsChannelDetails,
  LogoContainer,
  ChannelName,
  DetailsVideoContainer,
  DetailsImageContainer,
  DetailsDescription,
  Headline,
  DestinationUrl,
  ShortUrl,
  PublishedOnDetails,
  CallToAction,
  MediaType,
  NoResults,
  RequestDataBtn,
  SkeletonWrap,
} from "./index.styled";

import { useTwitter } from "../../context/twitter";
import SearchLoader from "../../../../../../HOC/SearchLoader";

import CloseBtn from "../../../../../../../media/Dashboard/detailsPaneClose.svg";
import TickImg from "../../../../../../../media/Dashboard/tick-grey.svg";

import DateIcon from "../../../../../../../media/Images/DateIcon.svg";
import CountryIcon from "../../../../../../../media/Images/CountryIcon.svg";
import LimitExceededPopup from "../../../../../../components/limitExceededPopup";
import PlatformRestrictedPopup from "../../../../../../components/platformRestrictionPopup";
import { useAuth } from "../../../../../../../context/auth";
import { useSearch } from "../../../context/search";
import SaveAdsPopup from "../../../../../../components/SaveAdsPopup";
import { useSaveAds } from "../../../../../../hooks/useSaveAds";
import { Helmet } from "react-helmet";
import { TwitterTweetEmbed } from "react-twitter-embed";
import Skeleton from "react-loading-skeleton";

const convertTimestamp = (timestamp) => {
  if (!timestamp) {
    return "-";
  }
  // Create a new Date object based on the timestamp
  var date = new Date(Number(timestamp));

  // Get the month name
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var month = monthNames[date.getMonth()];

  // Get the year
  var year = date.getFullYear();

  // Assemble the formatted date string
  var formattedDate = date.getDate() + " " + month + " " + year;

  return formattedDate;
};

const CardElement = ({ ad, idx }) => {
  const { authState, authDispatch } = useAuth();
  const useSaveAdsHook = useSaveAds();

  const { twitterState, twitterDispatch } = useTwitter();
  const [isLoading, setIsLoading] = useState(true);

  const embededLink = ad.url ? ad.url.split("/").pop() : "";

  const toggleDetailsModal = (ad) => {
    if (twitterState.adDetails.id !== ad.id && twitterState.detailsModal) {
      twitterDispatch({ type: "SET_AD_DETAILS", value: ad });
    } else {
      twitterDispatch({ type: "SET_AD_DETAILS", value: ad });
      twitterDispatch({
        type: "UPDATE_DETAILS_MODAL",
        value: !twitterState.detailsModal,
      });
    }
  };

  const onSave = (ad) => {
    useSaveAdsHook.SaveAdMutate({
      projectId: authState.defaultProjectInfo.projectId,
      adId: `${ad.id}`,
      platform: "google",
    });
    // authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'reddit'})
    // authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
    // authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})
  };

  return (
    <Card key={ad.id}>
      <CardBody>
        {/* <FloatingButtonsContainer> */}
          {/* <Favorite isFav={idx == 1} onClick={()=>{
                    onSave(ad)}}>
                    <img src={authState.favAdsList["reddit"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon}/>
                </Favorite> */}
          {/* |
                <DropdownIcon>
                    <span/>
                </DropdownIcon> */}
        {/* </FloatingButtonsContainer> */}

        {
          twitterState.iframeLoading && (
            //   <SkeletonWrap>
            <Skeleton height={400} />
          )
          //   </SkeletonWrap>
        }
        <TwitterTweetEmbed
          tweetId={embededLink}
          onLoad={() => twitterDispatch({ type: "SET_IFRAME_LOADER", value: false })}
        />
      </CardBody>

      <CardFooter>
        <FooterLeft>
          <AdDate>
            {ad.lastSeen && <img src={DateIcon} />}
            {convertTimestamp(ad.lastSeen)}
          </AdDate>
          <AdCountries>
            {ad.country.length > 0 && <img src={CountryIcon} />}
            {ad.country.length == 1
              ? ad.country[0]
              : ad.country.length > 1
              ? ad.country[0] + " + " + (ad.country.length - 1)
              : ""}
          </AdCountries>
        </FooterLeft>
        <FooterRight>
          {/* <DetailsButton onClick={()=>toggleDetailsModal(ad)}>
                    More details
                </DetailsButton> */}
        </FooterRight>
      </CardFooter>
    </Card>
  );
};

const DetailsElement = () => {
  const { twitterState, twitterDispatch } = useTwitter();

  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  useEffect(() => {
    if (twitterState.adDetails.image) {
      const img = new Image();
      img.onload = function () {
        // setTimeout(() => {
        setHeight(img.height);
        setWidth(img.width);
        // }, 1000);
      };
      img.src = `https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${twitterState.adDetails.id}`;
    }
    if (twitterState.adDetails.video) {
      var video = document.createElement("video");
      video.src = `https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${twitterState.adDetails.id}`;

      video.addEventListener("loadedmetadata", function () {
        setHeight(video.videoHeight);
        setWidth(video.videoWidth);
      });
    }
  }, []);

  return (
    <DetailsContainer>
      <DetailsHeader>
        <h3>Ad details</h3>{" "}
        <CloseDetails
          onClick={() => {
            twitterDispatch({ type: "UPDATE_DETAILS_MODAL", value: false });
          }}
        >
          <img src={CloseBtn} />
        </CloseDetails>
      </DetailsHeader>
      <AdDetailsBody>
        <DetailsChannelDetails>
          <LogoContainer>
            <img
              src={`https://aws-adspyder-logos.s3.ap-south-1.amazonaws.com/${twitterState.adDetails.id}`}
            />
          </LogoContainer>
          <ChannelName>
            {twitterState.adDetails.title}
            <span>Promoted</span>
          </ChannelName>
        </DetailsChannelDetails>
        {twitterState.adDetails.image && (
          <DetailsImageContainer>
            <img
              onLoad={(e) => {
                setHeight(e.height);
                setWidth(e.width);
              }}
              src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${twitterState.adDetails.id}`}
            />
          </DetailsImageContainer>
        )}
        {twitterState.adDetails.video && (
          <DetailsVideoContainer>
            <video
              src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${twitterState.adDetails.id}`}
              preload="auto"
              controls
              loop
              style={{ width: "100%", height: "100%" }}
            />
          </DetailsVideoContainer>
        )}
        <BusinessName>
          <label>Business name:</label>
          {twitterState.adDetails.title}
        </BusinessName>
        <DetailsDescription>
          <label>Introductory text:</label>
          {twitterState.adDetails.content}
        </DetailsDescription>
        <Headline>
          <label>Headline:</label>
          {twitterState.adDetails.innerdescription
            ? twitterState.adDetails.innerdescription
            : "-"}
        </Headline>
        <DestinationUrl>
          <label>Destination URL:</label>
          <a href={twitterState.adDetails.url}>{twitterState.adDetails.url}</a>
        </DestinationUrl>
        <ShortUrl>
          <label>Short URL:</label>
          {twitterState.adDetails.innertitle ? (
            <a href={twitterState.adDetails.innertitle}>
              {twitterState.adDetails.innertitle}
            </a>
          ) : (
            "-"
          )}
        </ShortUrl>
        <CallToAction>
          <label>Call to action:</label>
          {twitterState.adDetails.cta ? twitterState.adDetails.cta : "-"}
        </CallToAction>
        <MediaType>
          <label>Media type:</label>
          {twitterState.adDetails.video ? "Video" : "Image"}
        </MediaType>
        <MediaType>
          <label>Media size:</label>
          {width + " X " + height}
        </MediaType>
        <DateRange>
          <label>Date Range:</label>27 Jan 2021 - 21 Nov 2022
        </DateRange>
        <AvgPosition>
          <label>Average position:</label>51
        </AvgPosition>
        {twitterState.adDetails.tags && (
          <TagsContainer>
            <label>Tags:</label>
            <TagsList>
              {twitterState.adDetails.tags.map((tag, idx) => {
                return <span>{tag}</span>;
              })}
            </TagsList>
          </TagsContainer>
        )}
        <PublishedOnDetails>
          <label>Published on:</label>
          {convertTimestamp(twitterState.adDetails.publishedOn)}
        </PublishedOnDetails>
        {/* {twitterState.adDetails.country && <CountriesContainer>
            <label>Tags:</label><CountriesList>{twitterState.adDetails.country.map((tag,idx)=>{
                return <span>{tag}</span>
            })}</CountriesList>
        </CountriesContainer>} */}
        {twitterState.adDetails.country && (
          <CountriesContainer>
            <label>Tags:</label>
            <CountriesList>
              <span>{twitterState.adDetails.country}</span>
            </CountriesList>
          </CountriesContainer>
        )}
      </AdDetailsBody>
    </DetailsContainer>
  );
};

const Results = () => {
  const { twitterState, twitterDispatch } = useTwitter();
  const useRequestDataHook = useRequestData();

  const { searchState, searchDispatch } = useSearch();
  const { authState, authDispatch } = useAuth();

  const onDataRequest = () => {
    if (!useRequestDataHook.isDataRequested) {
      useRequestDataHook.requestDataMutate({ id: twitterState.queryId });
    }
  };

  if (authState.isRestrictedPopup) {
    return (
      <ResultsContainer
        restricted={true}
        detailsModal={twitterState.detailsModal}
      >
        <PlatformRestrictedPopup err={searchState.searchError} />
      </ResultsContainer>
    );
  }

  if (searchState.limitExceeded) {
    return (
      <ResultsContainer
        restricted={true}
        detailsModal={twitterState.detailsModal}
      >
        <LimitExceededPopup onClose={()=>{searchDispatch({type:"UPDATE_LIMIT_EXCEEDED", value: false})}} closeEnabled={true} err={searchState.searchError} />
      </ResultsContainer>
    );
  }

  return (
    <>
      <Helmet>
        <script
          async
          src="https://platform.twitter.com/widgets.js"
          charset="utf-8"
        ></script>
      </Helmet>
      <ResultsContainer
        resultLoading={twitterState.loadingResults}
        noResults={twitterState.noResults}
        detailsModal={twitterState.detailsModal}
      >
        {twitterState.loadingResults ? (
          <div className="loader">
            <SearchLoader />
          </div>
        ) : twitterState.noResults ? (
          <NoResults>
            <img src={NoResultsLogo} />
            <h2>No Results Found</h2>
            <p>
              We're sorry, but we don't currently have the data matching your
              search parameters. Our database is constantly updated, so please
              try again later or try modifying your search.
            </p>
            <RequestDataBtn
              // dataRequested={authState.isDataRequested}
              isDisabled={
                useRequestDataHook.isRequestDataLoading ||
                authState.isDataRequested
              }
              onClick={onDataRequest}
            >
              {authState.isDataRequested ? (
                <p>
                  Your request for data crawl has been accepted
                  <img src={TickImg} />
                </p>
              ) : (
                <p>
                  {useRequestDataHook.isRequestDataLoading
                    ? "Requesting..."
                    : "Request data crawl for this query"}
                </p>
              )}
            </RequestDataBtn>
          </NoResults>
        ) : (
          <ResultsWraper>
            {authState.saveAdsPopupOpen && <SaveAdsPopup />}
            <CardsList detailsModal={twitterState.detailsModal}>
              {twitterState.ads &&
                twitterState.ads.length > 0 &&
                twitterState.ads.map((ad, idx) => {
                  return <CardElement ad={ad} idx={idx} />;
                })}
            </CardsList>
            {twitterState.detailsModal && <DetailsElement />}
          </ResultsWraper>
        )}
      </ResultsContainer>
    </>
  );
};

export default Results;
