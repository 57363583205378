import styled from "styled-components";

export const Container = styled.div`
display: flex;
gap: 10px;

`;

export const UrlBox = styled.div`
width: 538px;
height: 56px;
flex-shrink: 0;
padding: 18px;
display: flex;
align-items: center;
gap: 11px;
border-radius: 6px;
border: 1px solid #E8E8E8;
background: #FFF;
`;

export const Icon = styled.img``

export const Url = styled.div`
display: flex;
align-items: center;
width: 100%;
`;

export const Placeholder = styled.div`
color: #9C9C9C;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
`;

export const UrlInput = styled.input`
border: none;
outline: none;
color: #1C1C1C;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
width: 100%;

::focus{
    border: none;
    outline: none; 
}
`;

export const Next = styled.div`
display: inline-flex;
padding: 18px 20px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 6px;
background: #FF711E;
color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
cursor: pointer;
`;