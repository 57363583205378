const theme = {
    colors: {
        red: '#f10000',
        black: '#000000',
        white: '#FFFFFF',
        lightBlack: '#2E2E2E',
        Grey: '#707070',
        lightGrey: '#ECECEC',
        lightDimGrey: '#C4C4C4',
        dimGrey: '#F9F9F9',
        linkBlue: '#003FBC'
    },
    breakPoints: {
        mobileExtraSmall: "320px",
        mobileSmall: "360px",
        mobile: "400px",
        tab: "768px",
        laptop: "1024px",
        desktop: "1280px",
        largeScreen: "1500px",
        extraLargeScreen: "1920px"
        
    }
}
export default theme