import styled from 'styled-components'

export const Container = styled.div`
    position: fixed; 
    width: 100%;
    display: flex;
    height: ${props => props.announcementBar ? "calc(100% - 76px)": "100%"};
`;

export const Wrapper = styled.div`
    /* display: flex; */
    position: relative;
    /* margin: auto; */
    width: ${props => props.noSideNav ? "100vw" : props.isNavCollapsed ? "calc(100vw - 80px)" : "calc(100vw - 285px)"};
    /* height: 100%; */
    /* min-height: calc(100vh - 105px); */
    /* max-height: calc(100vh - 54px); */
    
`;

export const Content = styled.div`
    background: #F6F7FB;
    /* width: ${props => props.noSideNav ?  "100vw": "calc(100vw - 200px)"}; */
    width: ${props => props.noSideNav ?  "100vw": "100%"};
    /* padding-bottom: 10px; */
    height: ${props => props.announcement ? "calc(100vh - 164px)" : "calc(100vh - 64px)"};
    /* overflow: auto; */
    // height: calc(100vh - 24px);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* position: relative; */
    overflow-y: auto;
    ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: #00000030;
        border-radius: 10px;
    }
`;

export const AppWrapper = styled.div`
    height: 100vh;
    width: 100vw;
`;
