import React, { useState, useEffect } from 'react'

import {
  useMutation
} from "@tanstack/react-query"
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"
import { useRequestData } from "../../../../../../hooks/useRequestData"
import locationImg from '../../../../../../../media/Dashboard/locationCard.svg'
import RightUpperBlackArrow from "../../../../../../../media/Dashboard/RightUpperBlackArrow.svg"

// Styles
import {
  ResultsContainer,
  CardContainer,
  ResultsWraper,
  AdContainer,
  ImageContainer,
  AdBodyContainer,
  ProductName,
  ProductPrice,
  AdBodyBottomContainer,
  AdDate,
  NoResults,
  RequestDataBtn,
  LinkWrap,
  CountryWrap,
  Country,
  HoverCardWrapper,
  HoverCard,
  SaveAdsButton,
  AdDetailsButton
} from "./index.styled"
import _static from "../../../../../../../config/static"
import SearchLoader from '../../../../../../HOC/SearchLoader'

import { useGoogleEcomSearch } from "../../context/googleecom"


import AltImage from '../../../../../../../media/Dashboard/altimage.png'
import TickImg from '../../../../../../../media/Dashboard/tick-grey.svg'

import { appClient } from '../../../../../../../utils/axios-utils'
import LimitExceededPopup from '../../../../../../components/limitExceededPopup'
import PlatformRestrictedPopup from '../../../../../../components/platformRestrictionPopup'
import { useAuth } from '../../../../../../../context/auth'
import { useSearch } from '../../../context/search'
import SaveAdsPopup from '../../../../../../components/SaveAdsPopup'
import AdDetails from '../../../../../../components/AdDetailsModal'
import { useSaveAds } from '../../../../../../hooks/useSaveAds'

const adDetails = async (id) => {
  const config = {
    url: `/detail/google-ecom/${id}`,
    method: "GET"
  }

  return appClient(config)
}

const Card = ({ ad, idx, basicAdDetails, setBasicAdDetails, containerRef }) => {
  const { googleEcomSearchState, googleEcomSearchDispatch } = useGoogleEcomSearch()
  const [detaislOpen, setDetailsOpen] = useState(false)
  const useRequestDataHook = useRequestData()
  const useSaveAdsHook = useSaveAds()

  const [seeMoreCountries, setSeeMoreCountries] = useState(false)
  const [seeMoreKeywords, setSeeMoreKeyword] = useState(false)
  const { authState, authDispatch } = useAuth()

  const image = ad.image ? <img src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`} onError={() => { }} /> : <img src={AltImage} onError={() => { }} />;

  const convertTimestamp = (timestamp) => {
    // Create a new Date object based on the timestamp
    var date = new Date(timestamp);

    // Get the month name
    var monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    var month = monthNames[date.getMonth()];

    // Get the year
    var year = date.getFullYear();

    // Assemble the formatted date string
    var formattedDate = date.getDate() + ' ' + month + ' ' + year;

    return formattedDate;
  }

  const onSave = (ad) => {
    useSaveAdsHook.SaveAdMutate({
      projectId: authState.defaultProjectInfo.projectId,
      adId: `${ad.id}`,
      platform: "google-ecom"
    })
    // authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'google-ecom' })
    // authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id })
    // authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
  }

  const { mutate: adsDetailsMutate, isLoading: isDetailsLoading } = useMutation(adDetails, {
    onSuccess: (data) => {
      googleEcomSearchDispatch({ type: 'SET_AD_DETAILS', value: data })
      if (googleEcomSearchState.adDetails.id == data.id || !googleEcomSearchState.adDetails.id) {
        googleEcomSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: !googleEcomSearchState.detailsModal })
      }
    },
    onError: ((e) => {
      console.log(e)
    })
  })

  const closeDetailsModal = () => {
    googleEcomSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: !googleEcomSearchState.detailsModal })
    googleEcomSearchDispatch({ type: 'SET_AD_DETAILS', value: {} })
  }

  const toggleDetailsModal = (ad) => {
    console.log(ad, 'googleEcomSearchState.adDetails')
    // if(googleEcomSearchState.adDetails.id){
    //   if((googleEcomSearchState.adDetails.id == ad.id) && googleEcomSearchState.detailsModal){
    //if details is open and clicked on close button on ads card
    setBasicAdDetails(ad)
    googleEcomSearchDispatch({ type: 'SET_AD_DETAILS', value: ad })
    googleEcomSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: !googleEcomSearchState.detailsModal })
    //   }else{
    //       setBasicAdDetails(ad)
    //       adsDetailsMutate(ad.id)
    //   }
    // }else{
    //     setBasicAdDetails(ad)
    //     adsDetailsMutate(ad.id)
    // }
  }


  const onRemoveSavedAd = (ad) => {
    useSaveAdsHook.RemoveSavedAdMutate({
      projectId: authState.defaultProjectInfo.projectId,
      adId:  `${ad.id}`,
      platform: "google-ecom"
    })
  }

  useEffect(() => {
    if (ad.country && ad.country.length > 5) {
      setSeeMoreCountries(true)
    }

    if (googleEcomSearchState.adDetails.keywords && googleEcomSearchState.adDetails.keywords.length > 5) {
      setSeeMoreKeyword(true)
    }
  }, [])
console.log('checking')
  return (
      <CardContainer noResults={googleEcomSearchState.noResults} detaislOpen={googleEcomSearchState.adDetails.id == ad.id}>
        <HoverCard className='hover-element' onClick={() => toggleDetailsModal(ad, idx)}>
          <HoverCardWrapper>
            <AdDetailsButton onClick={() => toggleDetailsModal(ad, idx)}>
              <img src={RightUpperBlackArrow} />
              <span>Ad Details</span>
            </AdDetailsButton>
            <SaveAdsButton isSaved={authState.savedAdsIds.includes(ad.id)} onClick={(e) => {
              e.stopPropagation();
              if (authState.savedAdsIds.includes(ad.id)) {
                onRemoveSavedAd(ad)
              } else {
                onSave(ad);
              }

            }}>
              {authState.savedAdsIds.includes(ad.id) ? "Saved" : "Save"}
            </SaveAdsButton>
          </HoverCardWrapper>
        </HoverCard>
        {googleEcomSearchState.adDetails.id != ad.id && 
        <AdContainer>
          <ImageContainer>
            {image}
          </ImageContainer>
          {/* <FloatingButtonsContainer>
              <Favorite isFav={idx == 1} onClick={()=>{
                    authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'google-ecom'})
                    authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                    authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}>
                  <img src={authState.favAdsList["google-ecom"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon}/>
              </Favorite> */}
          {/* |
              <DropdownIcon>
                  <span/>
              </DropdownIcon> */}
          {/* </FloatingButtonsContainer> */}
          <AdBodyContainer>
            <ProductName>
              {ad.title}
            </ProductName>
            <LinkWrap>
              {ad.url}
            </LinkWrap>
            <AdDate>
              {convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
            </AdDate>
            {/* <ProductPrice>
                {ad.price}
              </ProductPrice> */}
            {/* <WebsiteUrl>
                {ad.sitename}
              </WebsiteUrl> */}
            <AdBodyBottomContainer>
              <CountryWrap>
                <img src={locationImg} />
                <Country>{ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : "--"}</Country>
              </CountryWrap>
              <ProductPrice>
                {ad.price}
              </ProductPrice>
              {/* <GoogleIcon>
                    <img src={googleImg}/>
                </GoogleIcon> */}
              {/* <AdDate>
                {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                </AdDate> */}
              {/* <AdCountries>
                  <img src={CountryIcon}/>{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries> */}
            </AdBodyBottomContainer>
            {/* <DetailsButton onClick={()=>toggleDetailsModal(ad)}>
              {(googleEcomSearchState.adDetails.id == ad.id) && googleEcomSearchState.detailsModal ? "Close details" : "More details"}
                </DetailsButton> */}
          </AdBodyContainer>
        </AdContainer>}
        
        {/* {googleEcomSearchState.adDetails.id == ad.id && <DetailsContainer >
            <Left>
              <FloatingButtonsContainer> */}
        {/* <Favorite isFav={idx == 1}>
                    <img src={FavorietIcon}/>
                </Favorite>
                |
                <DropdownIcon>
                    <span/>
                </DropdownIcon> */}
        {/* </FloatingButtonsContainer>
              <DetailsImageContainer>
                {image}
              </DetailsImageContainer>
              <DetailsButton onClick={()=>closeDetailsModal(ad.id)}>
                  Close details
                </DetailsButton>
            </Left>
            
            <Right>
              <DetailsHeading>
                Ad Detail
                <CloseButton onClick={()=>closeDetailsModal(ad.id)}>
                  ×
                </CloseButton>
              </DetailsHeading>
              <DetailsWraper>
                <DetailsProductName>
                  <label>Title</label>
                  <p>{ad.title}</p>
                </DetailsProductName>
                <ProductPrice>
                  <label>Price</label>
                  <p>{ad.price}</p>
                </ProductPrice>
                <WebsiteUrl>
                  <label>Destination URL:</label>
                  <p><a href={ad.url} target="_blank">{ad.url}</a></p>
                </WebsiteUrl>
                <DisplayLink>
                  <label>Display Link:</label>
                  <p>{ad.domainId}</p>
                </DisplayLink>
                <AdDetailsDate>
                  <label>Capture Date</label>
                  <p>{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}</p>
                </AdDetailsDate>
                <AdDetailsCountries>
                  <label>Country</label>
                  <p>{ad.country.map((country,idx)=>{
                    if(seeMoreCountries){
                      if(idx < 6){
                        return <span>{country}</span>
                      }
                    }else{
                      return <span>{country}</span>
                    }
                  })}
                  <SeeMoreMoreCountries onClick={()=>setSeeMoreCountries(prev => !prev)}>
                    {seeMoreCountries ? `... ${ad.country.length - 5}+ see more` : ad.country.length > 5 ? "see less" : ""}
                  </SeeMoreMoreCountries>
                  </p>
                </AdDetailsCountries>
                
                <AveragePosition>
                  <label>Average position:</label><p>{Math.round(ad.position)}</p>
                </AveragePosition>
                <AdKeywords>
                  <label>Search terms:</label>
                  <p>{googleEcomSearchState.adDetails.keywords.map((term,idx)=>{
                      if(seeMoreKeywords){
                        if(idx < 6){
                          return <span>{term}</span>
                        }
                      }else{
                        return <span>{term}</span>
                      }
                    })}
                    <SeeMoreMoreKeywords onClick={()=>setSeeMoreKeyword(prev => !prev)}>
                      {seeMoreKeywords ? `... ${googleEcomSearchState.adDetails.keywords.length - 5}+ see more` : googleEcomSearchState.adDetails.keywords.length > 5 ? "see less" : ""}
                    </SeeMoreMoreKeywords>
                    </p>
                </AdKeywords>
                
              </DetailsWraper>
            </Right>
          </DetailsContainer>} */}
      </CardContainer>
  )
}

const Results = () => {
  const { googleEcomSearchState, googleEcomSearchDispatch } = useGoogleEcomSearch()
  const [basicAdDetails, setBasicAdDetails] = useState({})
  const useRequestDataHook = useRequestData()

  const { searchState, searchDispatch } = useSearch()
  const { authState, authDispatch } = useAuth()
  const useSaveAdsHook = useSaveAds()

  const onDetailsClose = () => {
    setBasicAdDetails({})
    googleEcomSearchDispatch({ type: 'SET_AD_DETAILS', value: {} })
    googleEcomSearchDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })
  }

  const onSave = (ad) => {
    useSaveAdsHook.SaveAdMutate({
      projectId: authState.defaultProjectInfo.projectId,
      adId: `${ad.id}`,
      platform: "google-ecom"
    })
    // authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'google-ecom' })
    // authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id })
    // authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
  }

  const onDataRequest = () => {
    if (!useRequestDataHook.isDataRequested) {
      useRequestDataHook.requestDataCrawl({ id: googleEcomSearchState.queryId })
    }
  }

  if (authState.isRestrictedPopup) {
    return <ResultsContainer restricted={true} detailsModal={googleEcomSearchState.detailsModal}>
      <PlatformRestrictedPopup err={searchState.searchError} />
    </ResultsContainer>
  }

  if (searchState.limitExceeded) {
    return <ResultsContainer restricted={true} detailsModal={googleEcomSearchState.detailsModal}>
      <LimitExceededPopup onClose={()=>{searchDispatch({type:"UPDATE_LIMIT_EXCEEDED", value: false})}} closeEnabled={true} err={searchState.searchError} />
    </ResultsContainer>
  }

  return <ResultsContainer resultLoading={googleEcomSearchState.loadingResults} noResults={googleEcomSearchState.noResults}>
    {googleEcomSearchState.loadingResults ? <div className='loader'>
      <SearchLoader />
    </div> : googleEcomSearchState.noResults ? <NoResults>
      <img src={NoResultsLogo} />
      <h2>No Results Found</h2>
      <p>We're sorry, but we don't currently have the data matching your search parameters. Our database is constantly updated, so please try again later or try modifying your search.</p>
      <RequestDataBtn
        // dataRequested={authState.isDataRequested}
        isDisabled={useRequestDataHook.isRequestDataLoading || authState.isDataRequested}
        onClick={onDataRequest}
      >
        {authState.isDataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg} /></p> : <p>{useRequestDataHook.isRequestDataLoading ? "Requesting..." : "Request data crawl for this query"}</p>}
      </RequestDataBtn>
    </NoResults> :
      <ResultsWraper>
        {authState.saveAdsPopupOpen && <SaveAdsPopup />}
        {(googleEcomSearchState.ads.length > 0) && googleEcomSearchState.ads.map((ad, idx) => {
          console.log('googleEcom rendering')
          return <Card basicAdDetails={basicAdDetails} setBasicAdDetails={setBasicAdDetails} ad={ad} idx={idx} />
        })}
      </ResultsWraper>
    }
    {
          googleEcomSearchState.detailsModal &&
          <AdDetails
            // navigateTo={navigateTo}
            platform="googleEcom"
            basicDetail={basicAdDetails}
            onDetailsClose={onDetailsClose}
            highlightWords={googleEcomSearchState.highlightWords}
            onSave={onSave}
          />
        }
  </ResultsContainer>
}

export default Results