import styled from "styled-components";

export const TempWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 180px;
`;
export const ComingSoonDiv = styled.div`
  color: #ff711e;
  font-size: 14px;
  font-weight: 500;
  padding-top: 40px;
  display: flex;
  column-gap: 9px;
  span {
    border-left: 2px solid #ff711e;
    height: 15px;
  }
`;
export const ExperienceLine = styled.div`
  padding-top: 16px;
  padding-bottom: 32px;
  font-size: 24px;
  font-weight: 600;
  line-height: 38px;
  text-align: center;
`;
export const ImageWrapper = styled.div`
  background: rgba(247, 250, 251, 1);
  width: 1485px;
  max-width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  .tempImgClass {
    margin-top: 62px;
    width: 1132px;
    max-width: 100%;
    margin-bottom: 45px;
  }
`;
export const GradientWrapper = styled.div`
  width: 100%;
  height: 100px;
  background: linear-gradient(
    2.24deg,
    rgba(247, 250, 251, 0.5) 1.88%,
    #ecfaff 98.12%
  );
  position: absolute;
`;
export const GradientWrapperDown = styled.div`
  background-color: red;
  width: 100%;
  height: 320px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    2.24deg,
    #ecfaff 1.88%,
    rgba(247, 250, 251, 0.8) 98.12%
  );
  display: flex;
  justify-content: center;
  align-items: end;
`;
export const DownContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 49%;
  row-gap: 8px;
  width: 1132px;
  max-width: 100%;
`;
export const DownContent = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 38px;
  color: rgba(0, 0, 0, 1);

  span {
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
    color: #ff711e;
  }
`;
export const DownDecsription = styled.div`
  max-width: 962px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: rgba(103, 103, 103, 1);
`;
export const SampleInfo = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  height: 29px;
  border-radius: 4px;
  border: 1px dashed rgba(0, 0, 0, 1);
  display: flex;
  column-gap: 8px;
  align-items: center;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(78, 61, 77, 1);

  img {
    height: 20px;
  }
`;
export const NotifyDiv = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(103, 103, 103, 1);
  padding-top: 38px;
  input[type="checkbox"] {
    /* Hide the default checkbox */
    appearance: none;
    width: 16px;
    height: 16px;
    border: ${(props) =>
      props.isSelected ? "1px solid rgba(255, 149, 0, 1)" : "1px solid rgba(208, 213, 221, 1)"};
    border-radius: 4px;
    position: relative;
    cursor: pointer;
  }

  /* Style for checked state */
  input[type="checkbox"]:checked::before {
    content: "✔";
    color: orange;
    position: absolute;
    left: 2px;
  }
`;
