import React, { useRef, useState } from 'react'

import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg"
import { useRequestData } from "../../../../../../hooks/useRequestData"

// Styles
import {
    CountriesList,
    TagsList,
    TagsContainer,
    CountriesContainer,
    DateRange,
    AdDetailsBody,
    CloseDetails,
    DetailsHeader,
    DetailsContainer,
    CardFooter,
    CardBody,
    Card,
    CardsList,
    ResultsContainer,
    ResultsWraper,
    VideoContainer,
    VideoTitle,
    ChannelDetails,
    ChannelName,
    VideoDescription,
    DetailsVideoContainer,
    Category,
    PublishedOnDetails,
    NoResults,
    RequestDataBtn,
    CopyImg,
    Header,
    LogoWrapper,
    TitleWrap,
    CountryWrap,
    Country,
    ChannelDiv,
    HoverCardWrapper,
    AdDetailsButton,
    SaveAdsButton,
    HoverCard
} from "./index.styled"
import SearchLoader from '../../../../../../HOC/SearchLoader'

import WebsiteIcon from '../../../../../../../media/Dashboard/globeWhiteIcon.svg'
import { useYoutube } from "../../context/youtube"

import CloseBtn from "../../../../../../../media/Dashboard/detailsPaneClose.svg"
import TickImg from '../../../../../../../media/Dashboard/tick-grey.svg'
import CopyBtn from '../../../../../../../media/Dashboard/copy.png'

import LimitExceededPopup from '../../../../../../components/limitExceededPopup'
import PlatformRestrictedPopup from '../../../../../../components/platformRestrictionPopup'
import SaveAdsPopup from '../../../../../../components/SaveAdsPopup'

import { useAuth } from '../../../../../../../context/auth'
import { useSearch } from '../../../context/search'
import locationImg from '../../../../../../../media/Dashboard/locationCard.svg'
import RightUpperBlackArrow from "../../../../../../../media/Dashboard/RightUpperBlackArrow.svg"
import AdDetails from '../../../../../../components/AdDetailsModal'
import { useSaveAds } from '../../../../../../hooks/useSaveAds'

const convertTimestamp = (timestamp) => {
    // Create a new Date object based on the timestamp
    var date = new Date(timestamp);

    // Get the month name
    var monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    var month = monthNames[date.getMonth()];

    // Get the year
    var year = date.getFullYear();

    // Assemble the formatted date string
    var formattedDate = date.getDate() + ' ' + month + ' ' + year;

    return formattedDate;
}

const CardElement = ({ ad, idx, containerRef }) => {
    const { youtubeState, youtubeDispatch } = useYoutube()
    const { authState, authDispatch } = useAuth()
    const useSaveAdsHook = useSaveAds()

    const scrollToElement = (index) => {
        const element = containerRef.current.children[index];
        const scrollPosition = element.offsetTop - 20;
        containerRef.current.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    };

    const toggleDetailsModal = (ad, idx) => {
        // console.log("addd yt details")
        if ((youtubeState.adDetails.id !== ad.id) && youtubeState.detailsModal) {
            youtubeDispatch({ type: 'SET_AD_DETAILS', value: ad })
        } else {
            youtubeDispatch({ type: 'SET_AD_DETAILS', value: ad })
            youtubeDispatch({ type: 'UPDATE_DETAILS_MODAL', value: !youtubeState.detailsModal })
        }
        setTimeout(() => {
            scrollToElement(idx)
        }, 100);
    }
    
    const onSave = (ad) => {
        useSaveAdsHook.SaveAdMutate({
            projectId: authState.defaultProjectInfo.projectId,
            adId: `${ad.id}`,
            platform: "youtube"
        })
        // authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'youtube' })
        // authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id })
        // authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
    }

    const onRemoveSavedAd = (ad) => {
        useSaveAdsHook.RemoveSavedAdMutate({
            projectId: authState.defaultProjectInfo.projectId,
            adId:  `${ad.id}`,
            platform: "youtube"
        })
    }

    return <Card detailsOpen={youtubeState.adDetails.id == ad.id} key={
        ad.id
    } onClick={() => toggleDetailsModal(ad, idx)}>
        <HoverCard className='hover-element' >
            <HoverCardWrapper>
                <AdDetailsButton onClick={() => toggleDetailsModal(ad, idx)}>
                    <img src={RightUpperBlackArrow} />
                    <span>Ad Details</span>
                </AdDetailsButton>
                <SaveAdsButton isSaved={authState.savedAdsIds.includes(ad.id)} onClick={(e) => {
                    e.stopPropagation();
                    if (authState.savedAdsIds.includes(ad.id)) {
                        onRemoveSavedAd(ad)
                    } else {
                        onSave(ad);
                    }

                }}>
                    {authState.savedAdsIds.includes(ad.id) ? "Saved" : "Save"}
                </SaveAdsButton>
            </HoverCardWrapper>
        </HoverCard>
        <VideoContainer>
            <iframe width="100%" height="190" style={{ borderRadius: "11px 11px 0px 0px", border: "none" }}
                src={`https://www.youtube.com/embed/${ad.videoId}`} controls="0">
            </iframe>
        </VideoContainer>

        <CardBody>
            <Header>
                <LogoWrapper>
                    <img src={WebsiteIcon} />
                </LogoWrapper>
                <TitleWrap>
                    <VideoTitle>{ad.title}</VideoTitle>
                    <ChannelDiv>{ad.channel}</ChannelDiv>
                </TitleWrap>
            </Header>
            {/* <VideoTitle>
                {ad.title}
            </VideoTitle>
            <ChannelDetails> */}
            {/* <LogoContainer>
                    <img src={WebsiteIcon}/>
                </LogoContainer> */}
            {/* <ChannelName>
                    {ad.channel}
                </ChannelName>
            </ChannelDetails> */}
            <VideoDescription>
                {ad.content}
            </VideoDescription>
            {/* <FloatingButtonsContainer>
                <Favorite isFav={idx == 1} onClick={()=>{
                    authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'youtube'})
                    authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                    authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}>
                    <img src={authState.favAdsList["youtube"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon}/>
                </Favorite> */}
            {/* |
                <DropdownIcon>
                    <span/>
                </DropdownIcon> */}
            {/* </FloatingButtonsContainer> */}
        </CardBody>
        <CardFooter>
            {ad.country && <CountryWrap>
                <img src={locationImg} />
                <Country>{ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}</Country>
            </CountryWrap>}
            {/* <GoogleIcon>
                <img src={googleImg}/>
            </GoogleIcon> */}
            {/* <FooterLeft> */}
            {/* <AdDate>
                {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>}{convertTimestamp(ad.firstSeen)} - {convertTimestamp(ad.lastSeen)}
                </AdDate>
                {ad.country && <AdCountries>
                    <img src={CountryIcon}/>{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries>} */}
            {/* </FooterLeft> */}
            {/* <FooterRight> */}
            {/* <PublishedOn>
                    <label>Published on:</label>
                    {convertTimestamp(ad.publishedOn)}
                </PublishedOn>
                <DetailsButton onClick={()=>toggleDetailsModal(ad,idx)}>
                    {(youtubeState.adDetails.id == ad.id) && youtubeState.detailsModal ? "Close details" : "More details"}
                </DetailsButton> */}
            {/* </FooterRight> */}
        </CardFooter>
    </Card>
}

const DetailsElement = () => {
    // console.log("details")

    const { youtubeState, youtubeDispatch } = useYoutube()
    const [showTick, setShowTick] = useState(false)
    const [copiedItem, setCopiedItem] = useState('')

    const onCopyClick = (value) => {
        navigator.clipboard.writeText(value);
        setCopiedItem(value)
        setShowTick(true)
        setTimeout(() => {
            setShowTick(false)
        }, 1500);
    }


    return <DetailsContainer>
        <DetailsHeader>
            <h3>Ad details</h3> <CloseDetails onClick={() => {
                youtubeDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })
                youtubeDispatch({ type: 'SET_AD_DETAILS', value: {} })
            }}><img src={CloseBtn} /></CloseDetails>
        </DetailsHeader>
        <AdDetailsBody>
            <DetailsVideoContainer>
                <iframe width="100%" height="378" style={{ borderRadius: "11px 11px 0px 0px", border: "none" }}
                    src={`https://www.youtube.com/embed/${youtubeState.adDetails.videoId}`}>
                </iframe>
            </DetailsVideoContainer>
            <VideoTitle>
                {youtubeState.adDetails.title}
            </VideoTitle>
            <ChannelDetails>
                {/* <LogoContainer>
                    <img src={WebsiteIcon}/>
                </LogoContainer> */}
                <ChannelName>
                    {youtubeState.adDetails.channel}
                </ChannelName>
            </ChannelDetails>
            <VideoDescription>
                {youtubeState.adDetails.content} {youtubeState.adDetails.content && <CopyImg onClick={() => onCopyClick(youtubeState.adDetails.content)} src={showTick && youtubeState.adDetails.content == copiedItem ? TickImg : CopyBtn} />}
            </VideoDescription>
            <Category>
                <label>Category:</label>{youtubeState.adDetails.genre}
            </Category>
            <DateRange>
                <label>Date Range:</label>{convertTimestamp(youtubeState.adDetails.firstSeen)} - {convertTimestamp(youtubeState.adDetails.lastSeen)}
            </DateRange>
            {/* <AvgPosition>
            <label>Average position:</label>{youtubeState.adDetails.position}
        </AvgPosition> */}
            {youtubeState.adDetails.tags && <TagsContainer>
                <label>Tags:</label><TagsList>{youtubeState.adDetails.tags.map((tag, idx) => {
                    return <span>{tag}</span>
                })}</TagsList>
            </TagsContainer>}
            <PublishedOnDetails>
                <label>Published on:</label>{convertTimestamp(youtubeState.adDetails.publishedOn)}
            </PublishedOnDetails>
            {youtubeState.adDetails.country && <CountriesContainer>
                <label>Country:</label><CountriesList>{youtubeState.adDetails.country.map((tag, idx) => {
                    return <span>{tag}</span>
                })}</CountriesList>
            </CountriesContainer>}
        </AdDetailsBody>
    </DetailsContainer>
}

const Results = () => {
    const containerRef = useRef(null);
    const useRequestDataHook = useRequestData()
    const useSaveAdsHook = useSaveAds()

    const { youtubeState, youtubeDispatch } = useYoutube()
    const { searchState, searchDispatch } = useSearch()
    const { authState, authDispatch } = useAuth()

    const onDataRequest = () => {
        if (!useRequestDataHook.isDataRequested) {
            useRequestDataHook.requestDataMutate({ id: youtubeState.queryId })
        }
    }
    const onSave = (ad) => {
         useSaveAdsHook.SaveAdMutate({
            projectId: authState.defaultProjectInfo.projectId,
            adId: `${ad.id}`,
            platform: "youtube"
        })
    }
    if (authState.isRestrictedPopup) {
        return <ResultsContainer restricted={true} detailsModal={youtubeState.detailsModal}>
            <PlatformRestrictedPopup err={searchState.searchError} />
        </ResultsContainer>
    }

    if (searchState.limitExceeded) {
        return <ResultsContainer restricted={true} detailsModal={youtubeState.detailsModal}>
            <LimitExceededPopup onClose={()=>{searchDispatch({type:"UPDATE_LIMIT_EXCEEDED", value: false})}} closeEnabled={true} err={searchState.searchError} />
        </ResultsContainer>
    }
    const onDetailsClose = () => {
        youtubeDispatch({ type: 'SET_AD_DETAILS', value: {} })
        youtubeDispatch({ type: 'UPDATE_DETAILS_MODAL', value: false })
    }
    return <ResultsContainer resultLoading={youtubeState.loadingResults} noResults={youtubeState.noResults} detailsModal={youtubeState.detailsModal}>
        {youtubeState.loadingResults ? <div className='loader'>
            <SearchLoader />
        </div> : youtubeState.noResults ? <NoResults>
            <img src={NoResultsLogo} />
            <h2>No Results Found</h2>
            <p>We're sorry, but we don't currently have the data matching your search parameters. Our database is constantly updated, so please try again later or try modifying your search.</p>
            <RequestDataBtn
                // dataRequested={authState.isDataRequested}
                isDisabled={useRequestDataHook.isRequestDataLoading || authState.isDataRequested}
                onClick={onDataRequest}
            >
                {authState.isDataRequested ? <p>Your request for data crawl has been accepted<img src={TickImg} /></p> : <p>{useRequestDataHook.isRequestDataLoading ? "Requesting..." : "Request data crawl for this query"}</p>}
            </RequestDataBtn>
        </NoResults> :
            <>
                {authState.saveAdsPopupOpen && <SaveAdsPopup />}
                <ResultsWraper>
                    <CardsList ref={containerRef} detailsModal={youtubeState.detailsModal}> {
                        youtubeState.ads && youtubeState.ads.length > 0 && youtubeState.ads.map((ad, idx) => {
                            return <CardElement containerRef={containerRef} ad={ad} idx={idx} />
                        })
                    } </CardsList>
                    {
                        youtubeState.detailsModal &&
                        <AdDetails
                            // navigateTo={navigateTo}
                            platform="youtube"
                            basicDetail={youtubeState.adDetails}
                            onDetailsClose={onDetailsClose}
                            highlightWords={youtubeState.highlightWords}
                            onSave={onSave}
                        />
                    }
                    {
                        // youtubeState.detailsModal && <DetailsElement/>
                    }
                </ResultsWraper>
            </>

        }
    </ResultsContainer>




    // <>
    //     <ResultsContainer>
    //         <CardWrapper>
    //             <Card>
    //                 <Header>
    //                     <img src={youtubeImgAd} />
    //                     <YoutubeAdsImg src={youtubeLogo} />
    //                 </Header>
    //             </Card>
    //         </CardWrapper>
    //     </ResultsContainer>
    // </>








}

export default Results



