import styled from "styled-components";

export const TableWrapper = styled.div`
width: 966px;
height: 696px;
border-radius: 16px;
background: #FFF;
margin-top: 16px;
max-width: 100%;

`
export const TableContainer = styled.div`
padding: 24px;
`
export const RelatedKeyword = styled.div`
color: #7D7D7D;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
display: flex;
column-gap: 7px;
img{
    cursor: pointer;
}
`
export const TableWrap  = styled.div`
max-width: 966px;
width: 100%;
height: 617px;
border-radius: 6px;
background: #FFF;
margin-top: 24px;
`
export const TableHeader  = styled.div`
max-width: 918px;
height: 44px;
background: #EAEAEA;
display: flex;
justify-content: space-between;
align-items: center;
padding: 0 34px;
color: rgba(28, 28, 28, 0.50);
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
`
export const KeyHeader  = styled.div`
width: 32%;
`
export const StatusHeader  = styled.div`
justify-content: center;
width: 22%;
display: flex;
column-gap: 6px;
`
export const PlatformHeader  = styled.div`
justify-content: center;
width: 22%;
display: flex;
column-gap: 6px;
`
export const CountryHeader  = styled.div`
justify-content: center;
display: flex;
column-gap: 6px;
width: 22%;
`

export const TableBody  = styled.div`
height: 87%;
    overflow-y: auto;
     ::-webkit-scrollbar-track
    {
        box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset;
        background-color: rgb(245 245 245 / 28%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 5px;
        background-color: rgb(245 245 245 / 92%);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb
    {
        background-color: rgb(0 0 0 / 7%);
        border-radius: 10px;
    }

`
export const Row  = styled.div`
/* width: 100%; */
display: flex;
align-items: center;
border-bottom: 1px solid #E3E8EE;
height:69px;
padding: 0px 34px;
`
export const HoverWrapper  = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 100%;
height:45px;
/* padding: 0 10px; */
&:hover{
    background: #FFF7E8;
    cursor: pointer;
}
`
export const KeyData  = styled.div`
width: 32%;
color: #1679DB;
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
display: flex;
column-gap: 8px;
align-items: center;
`
export const StatusData  = styled.div`
text-align: center;
width: 22%;
display: flex;
justify-content: center;
color: rgba(28, 28, 28, 0.70);
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
p{
width: fit-content;
border-radius: 4px;
background: #E1FAEA;
padding: 4px 12px;
color: #318048;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
&.inactive{
    color: #B00020;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
border-radius: 4px;
background: #F9EBED;
padding: 4px 12px;

}
}
`
export const PlatformData  = styled.div`
text-align: center;
width: 22%;
color: rgba(28, 28, 28, 0.60);
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
`

export const CountryData  = styled.div`
text-align: center;
width: 22%;
color: rgba(28, 28, 28, 0.60);
font-family: 'Inter';
font-size: 14px;
font-weight: 500;
`
export const ShowMoreBtnWrap = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 40px;
`
export const ShowMoreBtn = styled.div`
color: #1679DB;
font-family: 'Inter';
font-size: 14px;
font-weight: 400;
display: flex;
align-items: center;
column-gap: 4px;
cursor: pointer;
`