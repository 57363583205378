import styled from "styled-components";

export const SaleBarWrap = styled.div`
  height: 76px;
  background: rgba(255, 217, 98, 1);
  display: flex;
  padding: 0 18px;
`;
export const SaleInfoWrap = styled.div`
  width: 96.5%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  p {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: center;
    color: rgba(17, 25, 38, 1);
  }
  span {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.01em;
    margin-left: 6px;
  }
`;
export const TimerWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 77, 77, 1);
  gap: 2px;

  span {
    margin-left: 4px;
  }
`;
export const TimeCount = styled.div`
  background: rgba(252, 229, 155, 1);
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.01em;
`;
export const ClaimBtn = styled.div`
  background: rgba(255, 113, 30, 1);
  height: 40px;
  padding: 10px;
  gap: 10px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.01em;
  cursor: pointer;
  min-width: 107px;
  pointer-events: ${props => props.isActive ? "unset": "none"};
`;
export const ExitImgWrap = styled.div`
  width: 3.5%;
  display: flex;
  align-items: flex-start;
  justify-content: end;
  img {
    margin-top: 13px;
    cursor: pointer;
    height: 24px;
  }
`;
