import React, { useRef, useEffect, useState } from "react";
import {
  Container,
  Header,
  AdTypeContainer,
  AdTypeList,
  AdType,
  ActiveType,
  InactiveType,
  AdAccountContainer,
  AccountsDropDownContainer,
  SelectedAccount,
  AccountsDropDown,
  AccountOption,
  CampaignNameContainer,
  CampaignNameInput,
  ContinueButton,
  Footer,
  GridContainer,
  OptionsContainer,
  Option,
  OuterRadio,
  InnerRadio,
} from "./index.styled";
import LeftArrow from "../../../../../../../media/Dashboard/backarrow.svg";
import GoogleAdsIcon from "../../../../../../../media/Dashboard/GoogleAds.svg";
import DownArrow from "../../../../../../../media/Dashboard/drop-down-arrow.svg";
import { useAdIntegration } from "../../../context/AdManagementContext";
import { useOutsideAlerter } from "../../../../../../../utils/outsiderAlert";
import { useAdIntegrationInfo } from "../../../hooks/useAdManagementInfo";
import { useNavigate } from "react-router-dom";

const AdCampaignInput = () => {
  const { newAdManagementState, newAdManagementDispatch } = useAdIntegration();

  const adManagementHook = useAdIntegrationInfo();
  const [campaignDropdown, setCampaignDropdown] = useState(false)
  const navigate = useNavigate();
  let wrapperRef = useRef(null);

  const optionsForCampaignName = ["Selecting from campaign list", "Entering new campaign name"]
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0)

  const setDropDown = (bool) => {
    newAdManagementDispatch({
      type: "SET_ADACCOUNT_DROPDOWN_OPEN",
      value: bool,
    });
  };

  useOutsideAlerter(wrapperRef, setDropDown);
  useEffect(() => {
    adManagementHook.setGetConnectedList();
  }, []);

  useEffect(() => {
    const adAccount = newAdManagementState.selectedAdAccount
    if(adAccount){
      adManagementHook.getPushedCampaignListMutate({customerId: adAccount})
    }
  },[newAdManagementState.selectedAdAccount])

  useEffect(() => {
    if(selectedOptionIndex === 0){
      newAdManagementDispatch({
        type: "SET_CUSTOM_CAMPAIGN_NAME",
        value: null,
      });
    }
    else{
      newAdManagementDispatch({
        type: "SET_CAMPAIGN_NAME",
        value: null,
      });
    }
  }, [selectedOptionIndex])

  const renderAdType = () => {
    const adTypes = [
      {
        name: "Text ad",
        icon: GoogleAdsIcon,
      },
      {
        name: "Display ad",
        icon: null,
      },
      {
        name: "Shopiing ad",
        icon: null,
      },
    ];
    newAdManagementState.connectedAdAccount &&
      newAdManagementState.connectedAdAccount.length < 0 &&
      navigate(`/admanagement-new/ad-campaigns`);

    const onAdTypeClick = (adType) => {
      // adManagementHook.setGetConnectedList()
      newAdManagementDispatch({
        type: "SET_SELECTED_ADTYPE",
        value: adType.name,
      });
      newAdManagementDispatch({
        type: "SET_SELECTAD_TYPE_TOGGLE",
        value: true,
      });
    };

    return (
      <AdTypeContainer>
        <Header>
          <img src={LeftArrow} onClick={() => navigate(-1)} />
          <h2>Select ad type</h2>
        </Header>
        <AdTypeList>
          {adTypes.map((adType) => {
            return (
              <AdType
                isActive={
                  adType.icon &&
                  newAdManagementState.selectedAdType == adType.name
                }
                onClick={() => adType.icon && onAdTypeClick(adType)}
              >
                {adType.icon ? (
                  <ActiveType
                    isActive={
                      newAdManagementState.selectedAdType == adType.name
                    }
                  >
                    <img src={adType.icon} />
                    <p>{adType.name}</p>
                  </ActiveType>
                ) : (
                  <InactiveType>
                    <p>{adType.name}</p>
                    <span>Available soon</span>
                  </InactiveType>
                )}
              </AdType>
            );
          })}
        </AdTypeList>
      </AdTypeContainer>
    );
  };

  const adAccounts = () => {
    let accountsList = newAdManagementState.connectedAdAccount;

    const toggleDropDown = () => {
      newAdManagementDispatch({
        type: "SET_ADACCOUNT_DROPDOWN_OPEN",
        value: !newAdManagementState.adAccountDropDownOpen,
      });
    };

    return (
      <AdAccountContainer>
        <Header>
          <h2>Select ad account</h2>
        </Header>
        <AccountsDropDownContainer ref={wrapperRef}>
          <SelectedAccount
            rotateImg={newAdManagementState.adAccountDropDownOpen}
            onClick={toggleDropDown}
          >
            {newAdManagementState.selectedAdAccount
              ? newAdManagementState.selectedAdAccount
              : "Select Ad Account"}
            <img src={DownArrow} />
          </SelectedAccount>
          {newAdManagementState.adAccountDropDownOpen && (
            <AccountsDropDown>
              {accountsList.map((adAccount) => {
                return (
                  <AccountOption
                    onClick={() => {
                      newAdManagementDispatch({
                        type: "SET_SELECTED_ACCOUNT",
                        value: adAccount.customerId,
                      });
                      newAdManagementDispatch({
                        type: "SET_ADACCOUNT_DROPDOWN_OPEN",
                        value: false,
                      });
                    }}
                  >
                    {adAccount.customerId}
                  </AccountOption>
                );
              })}
            </AccountsDropDown>
          )}
        </AccountsDropDownContainer>
      </AdAccountContainer>
    );
  };

  const renderCampaignNameInput = () => {
    const isContinueEnabled =
      newAdManagementState.selectedAdType &&
      newAdManagementState.selectedAdAccount &&
      (newAdManagementState.campaignName || newAdManagementState.customCampaignName);
    const handleSearch = (event) => {
      if (event.key === "Enter") {
        newAdManagementDispatch({
          type: "SET_CURRENT_CAMPAIGN_STEP",
          value: 2,
        });
        newAdManagementDispatch({ type: "SET_ERROR_MESSAGE", value: null });
      }
    };
    return (
      <CampaignNameContainer>
        <Header>
          <h2>Campaign name</h2>
        </Header>
        <GridContainer>
          <OptionsContainer>
            {optionsForCampaignName.map((option, idx) => {
              return(
                <Option onClick={(e) => {
                  e.stopPropagation()
                  setSelectedOptionIndex(idx)
                }} >
                  <OuterRadio>
                    <InnerRadio selected={selectedOptionIndex === idx} ></InnerRadio>
                  </OuterRadio>
                  {option}
                </Option>
              )
            })}
          </OptionsContainer>
          {
            selectedOptionIndex === 0 &&
            <SelectedAccount style={{position: "relative"}} onClick={() => setCampaignDropdown(true)} >
              {newAdManagementState.campaignName ?? "Select campaign"}
              <img src={DownArrow} />
              {campaignDropdown && (
                <AccountsDropDown style={{left: "0"}} >
                  {newAdManagementState.campaignList.length === 0
                   ?  <AccountOption>No campaigns found</AccountOption>  
                   : newAdManagementState.campaignList.map((campaign) => {
                    return (
                        <AccountOption
                          onClick={(e) => {
                            newAdManagementDispatch({
                              type: "SET_CAMPAIGN_NAME",
                              value: campaign.campaignName,
                            });
                            setCampaignDropdown(false)
                            e.stopPropagation()
                          }}
                        >
                          {campaign.campaignName}
                        </AccountOption>
                      );
                    })
                  }
                </AccountsDropDown>
              )}
            </SelectedAccount>
          }
          
          {
            selectedOptionIndex === 1 &&
            <CampaignNameInput
              onChange={(e) =>
                newAdManagementDispatch({
                  type: "SET_CUSTOM_CAMPAIGN_NAME",
                  value: e.target.value,
                })
              }
              placeholder="Enter your custom campaign name"
              onKeyDown={handleSearch}
              value={newAdManagementState.customCampaignName}
            />
          }
        </GridContainer>
        <Footer>
          <ContinueButton
            isActive={isContinueEnabled}
            onClick={() => {
              newAdManagementDispatch({
                type: "SET_CURRENT_CAMPAIGN_STEP",
                value: 2,
              });
              newAdManagementDispatch({
                type: "SET_ERROR_MESSAGE",
                value: null,
              });
            }}
          >
            Continue
          </ContinueButton>
        </Footer>
      </CampaignNameContainer>
    );
  };

  return (
    <Container>
      {renderAdType()}
      {newAdManagementState.isSelectAdTypeToggle && adAccounts()}
      {newAdManagementState.isSelectAdTypeToggle && renderCampaignNameInput()}
    </Container>
  );
};

export default AdCampaignInput;
