import React, { useRef, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import NoResultsLogo from "../../../../../../../media/Dashboard/noresults.svg";
import { useRequestData } from "../../../../../../hooks/useRequestData";

// Styles
import {
  CountriesList,
  DateRange,
  AdDetailsBody,
  CloseDetails,
  DetailsHeader,
  DetailsContainer,
  CardBody,
  Card,
  CardsList,
  ResultsContainer,
  ResultsWraper,
  VideoContainer,
  ImageContainer,
  PageName,
  ChannelDetails,
  DetailsChannelDetails,
  LogoContainer,
  ChannelName,
  Description,
  InnerDescription,
  SecondaryDetails,
  DetailsVideoContainer,
  DetailsImageContainer,
  PrimaryText,
  Headline,
  DestinationUrl,
  ShortUrl,
  InnerContent,
  InnerTitle,
  CallToActionButton,
  CTA,
  MediaType,
  NetworkTypes,
  NoResults,
  RequestDataBtn,
  CopyImg,
  SaveAdsButton,
  HoverCard,
  AdDetailsButton,
  HoverCardWrapper,
  CardContainer,
} from "./index.styled";
import SearchLoader from "../../../../../../HOC/SearchLoader";

import GlobeIcon from "../../../../../../../media/Dashboard/globe.svg";
import { useFacebook } from "../../context/facebook";
import TickImg from "../../../../../../../media/Dashboard/tick-grey.svg";

import RightUpperBlackArrow from "../../../../../../../media/Dashboard/RightUpperBlackArrow.svg";

import CloseBtn from "../../../../../../../media/Dashboard/detailsPaneClose.svg";

import AltImage from "../../../../../../../media/Dashboard/altimage.png";
import { appClient } from "../../../../../../../utils/axios-utils";
import LimitExceededPopup from "../../../../../../components/limitExceededPopup";
import CopyBtn from "../../../../../../../media/Dashboard/copy.png";
import PlatformRestrictedPopup from "../../../../../../components/platformRestrictionPopup";
import { useAuth } from "../../../../../../../context/auth";
import { useSearch } from "../../../context/search";
import SaveAdsPopup from "../../../../../../components/SaveAdsPopup";
import AdDetails from "../../../../../../components/AdDetailsModal";
import { useSaveAds } from "../../../../../../hooks/useSaveAds";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const adDetails = async (id) => {
  const config = {
    url: `/detail/facebook/${id}`,
    method: "GET",
  };

  return appClient(config);
};

const requestDataCrawl = async (data) => {
  const config = {
    url: `/track/search/update-search-query`,
    method: "POST",
    data,
  };

  return appClient(config);
};

const convertTimestamp = (timestamp) => {
  if (!timestamp) {
    return "-";
  }
  // Create a new Date object based on the timestamp
  var date = new Date(Number(timestamp));

  // Get the month name
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var month = monthNames[date.getMonth()];

  // Get the year
  var year = date.getFullYear();

  // Assemble the formatted date string
  var formattedDate = date.getDate() + " " + month + " " + year;

  return formattedDate;
};

const CardElement = ({
  ad,
  idx,
  basicAdDetails,
  setBasicAdDetails,
  containerRef,
}) => {
  const { facebookState, facebookDispatch } = useFacebook();
  const { authState, authDispatch } = useAuth();
  const useSaveAdsHook = useSaveAds();

  const scrollToElement = (index) => {
    const element = containerRef.current.children[index];
    const scrollPosition = element.offsetTop - 200;
    containerRef.current.scrollTo({ top: scrollPosition, behavior: "smooth" });
  };

  const { mutate: adsDetailsMutate, isLoading: isDetailsLoading } = useMutation(
    adDetails,
    {
      onSuccess: (data) => {
        facebookDispatch({ type: "SET_AD_DETAILS", value: data });
        if (
          facebookState.adDetails.id == data.id ||
          !facebookState.adDetails.id
        ) {
          facebookDispatch({
            type: "UPDATE_DETAILS_MODAL",
            value: !facebookState.detailsModal,
          });
        }
      },
      onError: (e) => {
        console.log(e);
      },
    }
  );

  const toggleDetailsModal = (ad, idx) => {
    if (facebookState.adDetails.id !== ad.id && facebookState.detailsModal) {
      facebookDispatch({ type: "SET_AD_DETAILS", value: ad });
    } else {
      facebookDispatch({ type: "SET_AD_DETAILS", value: ad });
      facebookDispatch({
        type: "UPDATE_DETAILS_MODAL",
        value: !facebookState.detailsModal,
      });
    }
    setTimeout(() => {
      scrollToElement(idx);
    }, 100);
  };

  const onSave = (ad) => {
    useSaveAdsHook.SaveAdMutate({
      projectId: authState.defaultProjectInfo.projectId,
      adId: `${ad.id}`,
      platform: "facebook",
    });
    // authDispatch({ type: 'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'meta' })
    // authDispatch({ type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id })
    // authDispatch({ type: 'UPDATE_SAVE_AD_POPUP', value: true })
  };

  const onRemoveSavedAd = (ad) => {
    useSaveAdsHook.RemoveSavedAdMutate({
      projectId: authState.defaultProjectInfo.projectId,
      adId: `${ad.id}`,
      platform: "facebook",
    });
  };

  return (
    <Card key={ad.id}>
      <CardContainer>
        <HoverCard
          className="hover-element"
          onClick={() => toggleDetailsModal(ad, idx)}
        >
          <HoverCardWrapper>
            <AdDetailsButton onClick={() => toggleDetailsModal(ad, idx)}>
              <img src={RightUpperBlackArrow} />
              <span>Ad Details</span>
            </AdDetailsButton>
            <SaveAdsButton
              isSaved={authState.savedAdsIds.includes(ad.id)}
              onClick={(e) => {
                e.stopPropagation();
                if (authState.savedAdsIds.includes(ad.id)) {
                  onRemoveSavedAd(ad);
                } else {
                  onSave(ad);
                }
              }}
            >
              {authState.savedAdsIds.includes(ad.id) ? "Saved" : "Save"}
            </SaveAdsButton>
          </HoverCardWrapper>
        </HoverCard>
        <CardBody
          detailsOpen={facebookState.adDetails.id == ad.id}
          isVisible={ad.video}
        >
          <ChannelDetails>
            <LogoContainer>
              <img
                src={
                  ad.logo
                    ? `https://aws-adspyder-logos.s3.ap-south-1.amazonaws.com/${ad.id}`
                    : GlobeIcon
                }
              />
            </LogoContainer>
            <ChannelName>
              {ad.title}
              <span>Sponsored</span>
            </ChannelName>
          </ChannelDetails>
          <Description>
            {`${convertTimestamp(ad.lastSeen)} __ ${ad.content}`}
          </Description>
          {/* <FloatingButtonsContainer>
                    <Favorite isFav={idx == 1} onClick={()=>{
                        authDispatch({type:'UPDATE_PROJECT_SELECTED_PLATFORM', value: 'facebook'})
                        authDispatch({type: 'SET_SELECTED_FAVOURITE_AD_ID', value: ad.id})
                        authDispatch({type:'UPDATE_SAVE_AD_POPUP', value: true})}}>
                        <img src={authState.favAdsList["facebook"]?.includes(ad.id) ? FavouriteIconActive : FavorietIcon}/>
                    </Favorite> */}
          {/* |
                    <DropdownIcon>
                        <span/>
                    </DropdownIcon> */}
          {/* </FloatingButtonsContainer> */}
          {/* {ad.image && (
            <ImageContainer>
              <img
                src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`}
              />
            </ImageContainer>
          )} */}
          {ad.image &&
            (ad.imageIds ? (
              <Carousel
                autoPlay
                infiniteLoop
                interval={5000}
                transitionTime={500}
              >
                {ad.imageIds.map((imid) => {
                  return (
                    <ImageContainer key={imid}>
                      <img
                        src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${imid}`}
                      />
                    </ImageContainer>
                  );
                })}
              </Carousel>
            ) : (
              <ImageContainer>
                <img
                  src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${ad.id}`}
                />
              </ImageContainer>
            ))}

          {/* {ad.video && <VideoContainer>
                    <video src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${ad.id}`} preload="auto" controls loop style={{ width: "100%", height: "100%" }} />
                </VideoContainer>} */}

          {ad.video &&
            (ad.videoIds ? (
              <Carousel
                autoPlay
                infiniteLoop
                interval={5000}
                transitionTime={500}
              >
                {ad.videoIds.map((vid) => {
                  return (
                    <VideoContainer key={vid}>
                      <video
                        src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${vid}`}
                        preload="auto"
                        controls
                        loop
                        style={{ width: "100%", height: "100%" }}
                      />
                    </VideoContainer>
                  );
                })}
              </Carousel>
            ) : (
              <VideoContainer>
                <video
                  src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${ad.id}`}
                  preload="auto"
                  controls
                  loop
                  style={{ width: "100%", height: "100%" }}
                />
              </VideoContainer>
            ))}

          {!ad.video && !ad.image && (
            <ImageContainer>
              <img src={AltImage} />
            </ImageContainer>
          )}
          {(ad.ctaContent || ad.ctaButton) && (
            <SecondaryDetails>
              <InnerContent>
                {/* <InnerUrl>
                        {ad.ctaUrl}
                    </InnerUrl> */}
                {/* <InnerDesc>
                        {ad.ctaTitle}
                    </InnerDesc> */}
                <InnerTitle>{ad.ctaContent}</InnerTitle>
              </InnerContent>
              <CallToActionButton>
                {ad.ctaButton && ad.ctaButton !== "[object Object]" && (
                  <CTA>
                    {typeof ad.ctaButton == "string"
                      ? ad.ctaButton
                      : ad.ctaButton[0]}
                  </CTA>
                )}
              </CallToActionButton>
            </SecondaryDetails>
          )}
        </CardBody>
      </CardContainer>
      {/* <CardFooter> */}
      {/* <FooterLeft>
                <AdDate>
                {ad.firstSeen && ad.lastSeen && <img src={DateIcon}/>} */}
      {/* {convertTimestamp(ad.firstSeen)} -  */}
      {/* {convertTimestamp(ad.lastSeen)}
                </AdDate>
                <AdCountries>
                    {ad.country.length > 0 && <img src={CountryIcon} />}{ ad.country.length == 1 ? ad.country[0] : ad.country.length > 1 ? ad.country[0] + " + " + (ad.country.length - 1) : ""}
                </AdCountries>
            </FooterLeft>
            <FooterRight> */}
      {/* <DetailsButton onClick={()=>toggleDetailsModal(ad,idx)}>
                {(facebookState.adDetails.id == ad.id) && facebookState.detailsModal ? "Close details" : "More details"}
                </DetailsButton> */}
      {/* </FooterRight> */}
      {/* </CardFooter> */}
    </Card>
  );
};

const DetailsElement = () => {
  const { facebookState, facebookDispatch } = useFacebook();
  const [showTick, setShowTick] = useState(false);
  const [copiedItem, setCopiedItem] = useState("");

  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");

  const onCopyClick = (value) => {
    navigator.clipboard.writeText(value);
    setCopiedItem(value);
    setShowTick(true);
    setTimeout(() => {
      setShowTick(false);
    }, 1500);
  };

  useEffect(() => {
    if (facebookState.adDetails.image) {
      const img = new Image();
      img.onload = function () {
        // setTimeout(() => {
        setHeight(img.height);
        setWidth(img.width);
        // }, 1000);
      };
      img.src = `https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${facebookState.adDetails.id}`;
    }
    if (facebookState.adDetails.video) {
      var video = document.createElement("video");
      video.src = `https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${facebookState.adDetails.id}`;

      video.addEventListener("loadedmetadata", function () {
        // console.log(video)
        setHeight(video.videoHeight);
        setWidth(video.videoWidth);
      });
    }
  }, []);
  return (
    <DetailsContainer>
      <DetailsHeader>
        <h3>Ad details</h3>{" "}
        <CloseDetails
          onClick={() => {
            facebookDispatch({ type: "UPDATE_DETAILS_MODAL", value: false });
            facebookDispatch({ type: "SET_AD_DETAILS", value: {} });
          }}
        >
          <img src={CloseBtn} />
        </CloseDetails>
      </DetailsHeader>
      <AdDetailsBody>
        <DetailsChannelDetails>
          <LogoContainer>
            <img
              src={`https://aws-adspyder-logos.s3.ap-south-1.amazonaws.com/${facebookState.adDetails.id}`}
            />
          </LogoContainer>
          <ChannelName>
            {facebookState.adDetails.title}
            <span>Promoted</span>
          </ChannelName>
        </DetailsChannelDetails>
        {facebookState.adDetails.image && (
          <DetailsImageContainer>
            <img
              onLoad={(e) => {
                setHeight(e.target.naturalHeight);
                setWidth(e.target.naturalWidth);
              }}
              src={`https://aws-adspyder-images.s3.ap-south-1.amazonaws.com/${facebookState.adDetails.id}`}
            />
            <span>
              {height} X {width}
            </span>
          </DetailsImageContainer>
        )}
        {facebookState.adDetails.video && (
          <DetailsVideoContainer>
            <video
              src={`https://aws-adspyder-videos.s3.ap-south-1.amazonaws.com/${facebookState.adDetails.id}`}
              preload="auto"
              controls
              loop
              style={{ width: "100%", height: "100%" }}
            />
          </DetailsVideoContainer>
        )}
        <PageName>
          <label>Page name:</label>
          {facebookState.adDetails.title}
        </PageName>
        <PrimaryText>
          <label>Primary text:</label>
          {facebookState.adDetails.content}{" "}
          {facebookState.adDetails.content && (
            <CopyImg
              onClick={() => onCopyClick(facebookState.adDetails.content)}
              src={
                showTick && facebookState.adDetails.content == copiedItem
                  ? TickImg
                  : CopyBtn
              }
            />
          )}
        </PrimaryText>
        <Headline>
          <label>Headline:</label>
          {facebookState.adDetails.innerdescription
            ? facebookState.adDetails.innerdescription
            : "-"}{" "}
          {facebookState.adDetails.innerdescription && (
            <CopyImg
              onClick={() =>
                onCopyClick(facebookState.adDetails.innerdescription)
              }
              src={
                showTick &&
                facebookState.adDetails.innerdescription == copiedItem
                  ? TickImg
                  : CopyBtn
              }
            />
          )}
        </Headline>
        <InnerDescription>
          <label>Description:</label>
          {facebookState.adDetails.innertitle
            ? facebookState.adDetails.innertitle
            : "-"}{" "}
          {facebookState.adDetails.innertitle && (
            <CopyImg
              onClick={() => onCopyClick(facebookState.adDetails.innertitle)}
              src={
                showTick && facebookState.adDetails.innertitle == copiedItem
                  ? TickImg
                  : CopyBtn
              }
            />
          )}
        </InnerDescription>
        <DestinationUrl>
          <label>Destination URL:</label>
          <a href={facebookState.adDetails.url}>
            {facebookState.adDetails.url}
          </a>
        </DestinationUrl>
        <ShortUrl>
          <label>Display Link:</label>
          {facebookState.adDetails.innertitle ? (
            <a href={facebookState.adDetails.innertitle}>
              {facebookState.adDetails.innertitle}
            </a>
          ) : (
            "-"
          )}
        </ShortUrl>
        <MediaType>
          <label>Media Type:</label>
          {facebookState.adDetails.image
            ? "Image"
            : facebookState.adDetails.video
            ? "Video"
            : "-"}
        </MediaType>
        <NetworkTypes>
          {/* <label>Netword Types:</label> <TagsList>{facebookState.adDetails.platform.map((platformType,idx)=>{
                return <span>{platformType}</span>
            })}</TagsList> */}
        </NetworkTypes>
        <DateRange>
          <label>Date:</label>
          {convertTimestamp(facebookState.adDetails.firstSeen)} -{" "}
          {convertTimestamp(facebookState.adDetails.lastSeen)}
        </DateRange>
        <label>Country:</label>
        <CountriesList>
          {facebookState.adDetails.country.map((country, idx) => {
            return <span>{country}</span>;
          })}
        </CountriesList>
        {/* {facebookState.adDetails.country && <CountriesContainer>
            <label>Country:</label><CountriesList><span>{facebookState.adDetails.country}</span></CountriesList>
        </CountriesContainer>} */}
      </AdDetailsBody>
    </DetailsContainer>
  );
};

const Results = () => {
  const { facebookState, facebookDispatch } = useFacebook();
  const [basicAdDetails, setBasicAdDetails] = useState({});
  const useRequestDataHook = useRequestData();
  const useSaveAdsHook = useSaveAds();

  const { authState, authDispatch } = useAuth();
  const { searchState, searchDispatch } = useSearch();

  const containerRef = useRef(null);

  const onDataRequest = () => {
    if (!useRequestDataHook.isDataRequested) {
      // useRequestDataHook.requestDataMutate({id: facebookState.queryId})
      useRequestDataHook.requestRealtimeDataForFaceBookMutate({
        id: facebookState.queryId,
      });
    }
  };

  const onDetailsClose = () => {
    setBasicAdDetails({});
    facebookDispatch({ type: "SET_AD_DETAILS", value: {} });
    facebookDispatch({ type: "UPDATE_DETAILS_MODAL", value: false });
  };

  function findIndexById(arr, searchId) {
    for (let i = 0; i < facebookState.ads.length; i++) {
      if (arr[i].id === searchId) {
        return i; // Return the index when the ID is found
      }
    }
    return -1; // Return -1 if ID is not found in the array
  }

  const navigateTo = (adId, toAd) => {
    console.log("navigateTo", adId, toAd);
    let adIndex = findIndexById(adId);
    console.log(adIndex);
    if (adIndex != -1) {
      if (toAd == "next") {
        console.log("inside next");
        facebookDispatch({ type: "UPDATE_DETAILS_MODAL", value: true });
        setBasicAdDetails(facebookState.ads[adIndex + 1]);
        facebookDispatch({
          type: "SET_AD_AI_CLASSIFICATION_LOADING",
          value: true,
        });
      } else if (toAd == "back") {
        console.log("inside back");
        facebookDispatch({ type: "UPDATE_DETAILS_MODAL", value: true });
        setBasicAdDetails(facebookState.ads[adIndex - 1]);
        facebookDispatch({
          type: "SET_AD_AI_CLASSIFICATION_LOADING",
          value: true,
        });
      }
    }
  };

  const onSave = (ad) => {
    useSaveAdsHook.SaveAdMutate({
      projectId: authState.defaultProjectInfo.projectId,
      adId: `${ad.id}`,
      platform: "facebook",
    });
  };

  if (authState.isRestrictedPopup) {
    return (
      <ResultsContainer
        restricted={true}
        detailsModal={facebookState.detailsModal}
      >
        <PlatformRestrictedPopup err={searchState.searchError} />
      </ResultsContainer>
    );
  }

  if (searchState.limitExceeded) {
    return (
      <ResultsContainer
        restricted={true}
        detailsModal={facebookState.detailsModal}
      >
        <LimitExceededPopup onClose={()=>{searchDispatch({type:"UPDATE_LIMIT_EXCEEDED", value: false})}} closeEnabled={true} err={searchState.searchError} />
      </ResultsContainer>
    );
  }

  return (
    <ResultsContainer
      resultLoading={facebookState.loadingResults}
      noResults={facebookState.noResults}
      detailsModal={facebookState.detailsModal}
    >
      {facebookState.loadingResults ? (
        <div className="loader">
          <SearchLoader />
        </div>
      ) : facebookState.noResults ? (
        <NoResults>
          <img src={NoResultsLogo} />
          <h2>No Results Found</h2>
          <p>
            We're sorry, but we don't currently have the data matching your
            search parameters. Our database is constantly updated, so please try
            again later or try modifying your search.
          </p>
          <RequestDataBtn
            // dataRequested={authState.isDataRequested}
            isDisabled={
              useRequestDataHook.isRequestRealtimeDataForFacebookLoading ||
              authState.isDataRequested
            }
            onClick={onDataRequest}
          >
            {authState.isDataRequested ? (
              <p>
                Your request for data crawl has been accepted
                <img src={TickImg} />
              </p>
            ) : (
              <p>
                {useRequestDataHook.isRequestRealtimeDataForFacebookLoading
                  ? "Requesting..."
                  : "Request data crawl for this query"}
              </p>
            )}
          </RequestDataBtn>
        </NoResults>
      ) : (
        <ResultsWraper>
          {authState.saveAdsPopupOpen && <SaveAdsPopup noMarginTop={true} />}
          <CardsList
            ref={containerRef}
            detailsModal={facebookState.detailsModal}
          >
            {" "}
            {facebookState.ads &&
              facebookState.ads.length > 0 &&
              facebookState.ads.map((ad, idx) => {
                return (
                  <CardElement
                    containerRef={containerRef}
                    basicAdDetails={basicAdDetails}
                    setBasicAdDetails={setBasicAdDetails}
                    ad={ad}
                    idx={idx}
                  />
                );
              })}{" "}
          </CardsList>
          {/* {
                    facebookState.detailsModal && <DetailsElement />
                    } */}
          {facebookState.detailsModal && (
            <AdDetails
              navigateTo={navigateTo}
              platform="meta"
              basicDetail={facebookState.adDetails}
              onDetailsClose={onDetailsClose}
              highlightWords={facebookState.highlightWords}
              onSave={onSave}
            />
          )}
        </ResultsWraper>
      )}
    </ResultsContainer>
  );
};

export default Results;
