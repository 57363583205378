import styled from "styled-components";

export const InnerContentWrap = styled.div`
  padding: 0px 35px 35px 35px;
  overflow-y: auto;
  height: calc(100% - 77px);
  margin-top: 25px;

  ::-webkit-scrollbar {
    background-color: unset;
  }

  /* max-width: 819px; */
`;
export const InnerContentContainer = styled.div`
  display: flex;
  row-gap: 12px;
  flex-direction: column;
  width: 100%;
  max-width: 819px;
  margin-bottom: 100px;
`;
export const InnerContentDiv = styled.div``;
export const TargetPersonaWrap = styled.div`
  border-radius: 12px;
  border: 1px solid #dadfe4;
  display: flex;
  width: 100%;
  padding: 24px 24px 10px 24px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 12px;
  background: #fff;
`;
export const TargetPersona = styled.div`
  color: var(--black-100, #1c1c1c);
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  padding-bottom: ${(prop) => (prop.props ? "20px" : "")};
  img {
    height: 16px;
    transform: ${(prop) => (prop.props ? "" : "rotate(180deg)")};
    cursor: pointer;
  }
  p {
    display: flex;
    column-gap: 8px;
    align-items: center;
    span {
      color: rgba(28, 28, 28, 0.4);
      font-size: 14px;
      font-weight: 400;
    }
  }
`;
export const TargetDescrip = styled.div`
  color: #525252;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
`;
export const DestinationUrlWrap = styled.div`
  border-radius: 12px;
  border: 1px solid #e8e8e8;
  background: #fff;
  width: 100%;
  padding: 24px 25px 24px 28px;
  display: flex;
  flex-direction: column;
`;
export const DestinationUrl = styled.div`
  border-radius: 6px;
  border: 1px solid #dadfe4;
  background: #fff;
  width: 100%;
  height: 44px;
  display: flex;
  column-gap: 12px;
  padding: 12px 0 12px 8px;
  align-items: center;
  img {
    height: 16px;
  }

`;
export const HeadLineWrap = styled.div`
  border-radius: 12px;
  border: 1px solid #e8e8e8;
  background: #fff;
  padding: 24px 25px 24px 28px;
  width: 100%;
`;
export const HeadLine = styled.div`
  color: rgba(28, 28, 28, 0.5);
  font-size: 14px;
  font-weight: 400;
`;

export const InputWrap = styled.div`
  margin-top: 12px;
  display: flex;
  column-gap: 8px;
  margin-bottom: 24px;
  align-items: center;
  img {
    padding: 10px;
    cursor: pointer;
  }
`;
export const InputContainer = styled.div`
  border-radius: 6px;
  border: 1px solid #dadfe4;
  background: #fff;
  width: 722px;
  height: 44px;
  padding: 0px 16px;
  &#decsripTextArea {
    height: auto;
    padding: 0px 16px 6px 16px;
  }
  &#decsripTextArea form {
    align-items: end;
  }
  form {
    display: flex;
    column-gap: 20px;
    height: 100%;
    align-items: center;
    input {
      height: 100%;
      width: 100%;
      outline: none;
      border: none;
      color: var(--black-100, #1c1c1c);
      font-size: 14px;
      font-weight: 400;
    }
    p {
      color: rgba(28, 28, 28, 0.4);
      font-size: 12px;
      font-weight: 400;
    }
    textarea {
      height: 91px;
      width: 100%;
      outline: none;
      border: none;
      color: var(--black-100, #1c1c1c);
      font-size: 14px;
      font-weight: 400;
      padding: 12px 0 6px 0;
      resize: none;
    }
  }
`;
export const DescriptionWrap = styled.div`
  border-radius: 12px;
  border: 1px solid #e8e8e8;
  background: #fff;
  width: 100%;
  padding: 24px 25px 24px 28px;
`;
export const KeywordWrap = styled.div`
  border-radius: 6px;
  border: 1px solid #dadfe4;
  background: #fff;
  width: 100%;
  max-width: 756px;
  height: 213px;
  padding: 20px 16px 0 23px;
  p {
    color: rgba(28, 28, 28, 0.4);
    font-size: 12px;
    font-weight: 400;
    display: flex;
    justify-content: end;
  }
  input {
    width: 100%;
    height: 25px;
    border: none;
    outline: none;
  }
`;
export const KeywordContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 20px;
  height: 65%;
  overflow-y: auto;
  flex-wrap: wrap;
`;
export const KeywordDiv = styled.div`
  border-radius: 5.814px;
  background: ${(prop) => (prop.colorProp % 2 == 0 ? "#FBE7E9" : "#EDE7FB")};
  display: flex;
  height: 29.07px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: #222;
  font-size: 14px;
  font-weight: 500;
  img {
    height: 14px;
    cursor: pointer;
  }
`;
export const AddHeadLine = styled.div`
  color: #1679db;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%; /* 24px */
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.isDisabled ? "none" : "auto")};
`;
