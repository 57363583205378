import React, { useState, useEffect, useRef } from 'react'
import { Container, Down, Row1, Row, Drop, ToolTipContainer, Wrapper, ToolTipImageContainer, ToolTip } from './index.styled'
import { useNavigate } from 'react-router-dom'

// import { useSearch } from "../../context/search"
import { useAuth } from "../../../../../../context/auth"
import DropDownArrow from '../../../../../../media/Dashboard/drop-down-arrow.svg'

import GoogleLogo from '../../../../../../media/Dashboard/Google16.svg'
import BingLogo from '../../../../../../media/Dashboard/Bing16.svg'
import YoutubeLogo from '../../../../../../media/Dashboard/YouTube16.svg'
import MetaLogo from '../../../../../../media/Dashboard/meta16.png'
import LinkedinLogo from '../../../../../../media/Dashboard/LinkedIn16.svg'
import RedditLogo from '../../../../../../media/Dashboard/Reddit16.svg'
import GooglePlaLogo from '../../../../../../media/Dashboard/Googlepla16.svg'
import BingPlaLogo from '../../../../../../media/Dashboard/Bingpla16.svg'
import TwitterLogo from '../../../../../../media/Dashboard/twitter.png'
import AmazonLogo from '../../../../../../media/Dashboard/amazon16.svg'
import DisplayLogo from '../../../../../../media/Dashboard/Display16.svg'
import TikTokLogo from '../../../../../../media/Dashboard/tiktokImg.png'

import ToolTipIcon from '../../../../../../media/Dashboard/info_button_tool_tip.svg'

import ToolTipLogo from "../../../../../../media/Dashboard/tooltip.svg"

import { Separator } from './index.styled'
import Tooltip from 'rc-tooltip';


function useOutsideAlerter(ref, setShow) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setShow(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
}

const PlatformList = [
    {
        logo: GoogleLogo,
        platform: "google",
    },{
        logo: BingLogo,
        platform: "bing",
    },{
        
    },{
        logo: YoutubeLogo,
        platform: "youtube",
    },{
        
    },{
        logo: MetaLogo,
        platform: "meta",
    },{
        logo: LinkedinLogo,
        platform: "linkedin",
    },{
        logo: RedditLogo,
        platform: "reddit"
    },{
        logo: TwitterLogo,
        platform: "twitter"
    },{
        logo: GooglePlaLogo,
        platform: "googlepla"
    },{
        logo: BingPlaLogo,
        platform: "bingpla"
    },{
        logo: AmazonLogo,
        platform: "amazon"
    },{
        
    },{
        logo: DisplayLogo,
        platform: "display",
    },
    // {
    //     logo: TikTokLogo,
    //     platform: "tiktok",
    // },
]

let platformsWithoutUrlSearch = ["youtube", "linkedin", "reddit", "amazon","twitter"]
let platformsWithoutKeywordSearch = ["display"]

const PlatformDropDown = () => {
    // const { searchState, searchDispatch} = useSearch()
    const { authState, authDispatch } = useAuth()
    const wrapperRef = useRef(null)
    const [show, setShow] = useState(false)
    const navigate = useNavigate()

    useOutsideAlerter(wrapperRef, setShow)

    const getCurrentPlatformImg = (platform) => {
        let currentPlatform = PlatformList.filter((p,idx)=>{
            return p.platform == platform
        })

        return currentPlatform[0].logo
    }

    useEffect(() => {

    }, [])

    // console.log(authState)
    
    return <Wrapper>
        {/* <ToolTipContainer>
            choose platform <Tooltip placement="right" trigger={['hover']} overlay={<span>Select the ad platform of your choice.</span>}>
            <img src={ToolTipIcon}/>
        </Tooltip>
        </ToolTipContainer> */}
        <Container error={authState.searchPlatformError} ref={wrapperRef}>
        
        <Drop error={authState.searchPlatformError} onClick={() => { setShow(prev => !prev) }}>
            {authState.searchPlatform ?             
                <span><img className="platform-logo" src={getCurrentPlatformImg(authState.searchPlatform)}/> {authState.searchPlatform}</span>
            : <p>Platform</p>}
            <img className='dropdown-arrow' src={DropDownArrow} />
            <ToolTipImageContainer
                error={authState.searchPlatformError}
                className="tooltip-container">
                <img src={ToolTipLogo}/>
                <ToolTip>
                    Please select a platform
                </ToolTip>
            </ToolTipImageContainer>
        </Drop>

        <Down show={show}>
            <Row1>
                <p>Platform</p>
            </Row1>

            {PlatformList.map((platform, index) => {
                if(platform.logo){
                    if(authState.searchMode == "url" && !platformsWithoutUrlSearch.includes(platform.platform)){
                        return <Row key={index}
                        disabled={/* !authState.features.allowedPlatforms.includes(platform.platform) || */ platform.disabled}
                        onClick={() => {
                            // console.log(platform)
                            authDispatch({type: 'SET_SEARCH_PLATFORM_ERROR', value: false})
                            authDispatch({type:'SET_SEARCH_PLATFORM', value: platform.platform})
                            setShow(false)
                            if(authState.searchDisabled){
                                authDispatch({type: 'SET_SEARCH_DISABLED', value: false})
                            }
                            // navigate(`/adspy/${platform.platform}`)
                        }}>
                            <p><img src={platform.logo}/>{platform.platform}</p>
                        </Row>
                    }else if(authState.searchMode == "keyword" && !platformsWithoutKeywordSearch.includes(platform.platform)){
                        return <Row key={index}
                        disabled={/* !authState.features.allowedPlatforms.includes(platform.platform) || */ platform.disabled}
                        onClick={() => {
                            // console.log(platform)
                            authDispatch({type: 'SET_SEARCH_PLATFORM_ERROR', value: false})
                            authDispatch({type:'SET_SEARCH_PLATFORM', value: platform.platform})
                            setShow(false)
                            if(authState.searchDisabled){
                                authDispatch({type: 'SET_SEARCH_DISABLED', value: false})
                            }
                            // navigate(`/adspy/${platform.platform}`)
                        }}>
                            <p><img src={platform.logo}/>{platform.platform}</p>
                        </Row>
                    }
                    
                    }else{
                        return <Separator/>
                    }
                })}
            </Down>
        </Container>
    </Wrapper>
   
    }

export default PlatformDropDown