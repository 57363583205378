import React, { useEffect, useState, useRef }  from "react";
import { FilterDropDown, SelectedFilter, FilterListDropDown, FilterListContainer, FilterListHeader, ClearAll, FilterList, Divder, ExcludeTermContainer, CountryFilterSection, CountryFilterSectionHead, CountryFilterSectionBody, TimeFilterSection, TimeFilterSectionHead, TimeFilterSectionBody, SearchInFilterSection, SearchInFilterSectionHead, SearchInFilterSectionBody, FooterContainer, MoreFiltersBtn, ApplyFiltersBtn } from "./index.styled"
import FilterIconWhite from "../../../../../../../../media/Dashboard/FilterIconWhite.svg"
import DropDownIcon from "../../../../../../../../media/Dashboard/drop-down-arrow.svg"
import CountryFilter from "../CountryFilter";
import TimeFilter from "../TimeFilter";
import SearchInFilter from "../SearchInFilter"
import { useAuth } from "../../../../../../../../context/auth";
import { useSearchParams } from "react-router-dom";
import { useTikTok } from "../../../context/tiktok";

function useOutsideAlerter(ref, setShow) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setShow(false)
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
}

const MajorFilters = () => {
    const { tikTokState, tikTokDispatch } = useTikTok()
    const [searchParams, setSearchParams] = useSearchParams()

    const { authState, authDispatch } = useAuth()

    const [show, setShow] = useState(false)
    const wrapperRef = useRef(null)

    const filterList = [
        {   id: 1,
            name: "Country"
        },
        {
            id: 2,
            name: "Time Period"
        },
        {
            id: 3,
            name: "Search in"
        }
    ]

    const OnFilterClick = (type) => {
        if(tikTokState.activeFilter == type){
            tikTokDispatch({type: "SET_ACTIVE_FILTER", value: ""})
        }else{
            tikTokDispatch({type: "SET_ACTIVE_FILTER", value: type})
        }
    }

    const paramsDataFormatter = (date) => {
        if(!date){
            return ""
        }
        
        const dateString = date;
    
        const newDate = new Date(dateString);
    
        const formattedDate = newDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
        });
    
        var parts = formattedDate.split("/");
        var rearrangedDate = parts[2] + "-" + parts[0] + "-" + parts[1];

        return rearrangedDate
    }

    const OnApplyFilter = () => {
        const { phraseSearchIn, broadSearchIn} = tikTokState
        let fields

        if(tikTokState.keywordSearchType == "phrase"){
            fields = phraseSearchIn.filter((obj) => {
                return obj.checked && obj.value
            }).map((obj) => obj.value)
        }else{
            fields = broadSearchIn.filter((obj) => {
                return obj.checked && obj.value
            }).map((obj) => obj.value)
        }
        
        if(fields.length == 0){
            return tikTokDispatch({type: 'SET_SEARCH_IN_ERROR', value: true})
        }

        let paramSearchIn = fields.join(",")

        // searchParams.set("phraseInput", 1)
        // searchParams.set("searchMode", 1)
        // searchParams.set("searchMode", 1)
        // searchParams.set("keywordSearchType", 1)
        // searchParams.set("urlSearchType", 1)
        // searchParams.set("domainUrlInput", 1)
        // searchParams.set("keywords", 1)
        searchParams.set("page", 1)
        searchParams.set("excludedTerm", tikTokState.excludedTerm)
        searchParams.set("searchIn", paramSearchIn)
        searchParams.set("selectedCountries", tikTokState.selectedCountries.join(","))
        searchParams.set("startDate", paramsDataFormatter(tikTokState.startDate))
        searchParams.set("endDate", paramsDataFormatter(tikTokState.endDate))
        searchParams.set("minimumShouldMatch", tikTokState.broadMinShouldMatch.value)
        searchParams.set("sortType", tikTokState.selectedSortType.type)

        setSearchParams(searchParams)

        setShow(false)
    }

    const onClear = () => {
        tikTokDispatch({type: "CLEAR_FILTERS"})
    }

    useOutsideAlerter(wrapperRef, setShow)

    return <FilterDropDown ref={wrapperRef}>
        <SelectedFilter onClick={()=>setShow(prev => !prev)}>
            Filter <img src={FilterIconWhite}/>
        </SelectedFilter>
        {show && <FilterListDropDown>
            <FilterListContainer>
                <FilterListHeader>
                    <h3>Filter by</h3>
                    <ClearAll onClick={onClear}>
                        clear all
                    </ClearAll>
                </FilterListHeader>
                <FilterList>
                    {authState.searchMode == "keyword" && <ExcludeTermContainer>
                        <input 
                        autoComplete="off"
                        value={tikTokState.excludedTerm}
                        onChange={(e) => { 
                            // console.log(e.target.value)
                            tikTokDispatch({ type: "UPDATE_EXCLUDED_TERM", value: e.target.value}) 
                        }} placeholder="Exclude this term"/>
                    </ExcludeTermContainer>}
                    <CountryFilterSection>
                        <CountryFilterSectionHead 
                            isActive={tikTokState.activeFilter == "Country"}
                            onClick={()=>OnFilterClick("Country")}
                        >
                            <label>Country</label><img src={DropDownIcon}/>
                        </CountryFilterSectionHead>
                        {tikTokState.activeFilter !== "Country" && <Divder/>}
                        {tikTokState.activeFilter == "Country" && <CountryFilterSectionBody>
                            <CountryFilter/>
                        </CountryFilterSectionBody>}
                    </CountryFilterSection>
                    <TimeFilterSection>
                        <TimeFilterSectionHead 
                            isActive={tikTokState.activeFilter == "Time"}
                            onClick={()=>OnFilterClick("Time")}
                        >
                            <label>Time</label><img src={DropDownIcon}/>
                        </TimeFilterSectionHead>
                        {tikTokState.activeFilter !== "Time" && <Divder/>}
                        {tikTokState.activeFilter == "Time" && <TimeFilterSectionBody>
                            <TimeFilter/>
                        </TimeFilterSectionBody>}
                    </TimeFilterSection>
                    {authState.searchMode == "keyword" && <SearchInFilterSection>
                        <SearchInFilterSectionHead 
                            isActive={tikTokState.activeFilter == "Search in"}
                            onClick={()=>OnFilterClick("Search in")}
                        >
                            <label>Search in</label><img src={DropDownIcon}/>
                        </SearchInFilterSectionHead>
                        {tikTokState.activeFilter !== "Search in" && <Divder/>}
                        {tikTokState.activeFilter == "Search in" && <SearchInFilterSectionBody>
                            <SearchInFilter/>
                        </SearchInFilterSectionBody>}
                    </SearchInFilterSection>}
                </FilterList>
                <FooterContainer>
                    {tikTokState.isAdvancedFilterAvailable && <MoreFiltersBtn onClick={()=> {
                        tikTokDispatch({type: 'SET_ADVANCED_FILTERS', value: true})
                        setShow(false)
                    }}>
                        More filters
                    </MoreFiltersBtn>}
                    <ApplyFiltersBtn onClick={OnApplyFilter}>
                        Apply Filter
                    </ApplyFiltersBtn>
                </FooterContainer>
            </FilterListContainer>
        </FilterListDropDown>}
    </FilterDropDown>
}

export default MajorFilters